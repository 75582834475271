import { useState } from "react";

export const useCurrentPage = (initialPageSize = 10, initialPageIndex = 0) => {
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [currentPage, setCurrentPage] = useState(initialPageIndex);

    const onPageChange = (newPageSize, newPageIndex) => {
        setPageSize(newPageSize);
        setCurrentPage(newPageIndex);
    };
    const saveCurrentPage = (page) => {
        localStorage.setItem("currentPage", page);
    };

    const restoreCurrentPage = () => {
        const currentPage = localStorage.getItem("currentPage");
        if (currentPage) {
            return parseInt(currentPage, 10);
        }
        return 0;
    };

    return {
        pageSize,
        currentPage,
        onPageChange,
        saveCurrentPage,
        restoreCurrentPage,
    };
};