import React, { createContext, useState } from 'react';

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
    const [profileData, setProfileData] = useState();

    return (
        <ProfileContext.Provider value={{
            profileData,
            setProfileData,
        }}>
            {children}
        </ProfileContext.Provider>
    );
};

export const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
    const [imageData, setImageData] = useState();

    return (
        <ImageContext.Provider value={{
            imageData,
            setImageData
        }}>
            {children}
        </ImageContext.Provider>
    );
};

export const BaseCurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
    const [currencyData, setCurrencyData] = useState();

    return (
        <BaseCurrencyContext.Provider value={{
            currencyData,
            setCurrencyData,
        }}>
            {children}
        </BaseCurrencyContext.Provider>
    );
};

export const BaseRegisterContext = createContext();

export const RegisterProvider = ({ children }) => {
    const [singPassData, setSingPassData] = useState();

    return (
        <BaseRegisterContext.Provider value={{
            singPassData,
            setSingPassData,
        }}>
            {children}
        </BaseRegisterContext.Provider>
    );
};