export const formatDateToUserTimeZone = (utcDateString) => {
    if (utcDateString === null || utcDateString === undefined) {
        return;
    }

    if (utcDateString.includes("T") && !utcDateString.includes("Z")) {
        // Assume the date is in UTC if it's missing 'T' or 'Z'
        utcDateString += "Z";
    }

    let date = new Date(utcDateString);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
    };

    try {
        const formattedDate = date.toLocaleString("en-US", {
            timeZone: userTimeZone,
            ...options,
        });

        return formattedDate
            .replace(/(\d{2})\/(\d{2})\/(\d{4}),\s+/, "$3-$1-$2 ")
            .trim();
    } catch (error) {
        console.error("Error formatting date:", error);
    }
};
