import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "../Common/withRouter";
import classname from "classnames";

// i18n
import { toggleLeftmenu } from "../../store/actions";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ leftMenu, t, toggleLeftmenu }) => {
    const [dropdownStates, setDropdownStates] = useState({
        transaction: false,
    });

    const navRef = useRef(null);

    const toggleDropdown = (dropdown) => {
        setDropdownStates({
            ...dropdownStates,
            [dropdown]: !dropdownStates[dropdown],
        });
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest(".dropdown-menu") && !event.target.closest(".nav-link")) {
                setDropdownStates({
                    transaction: false,
                });
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleOptionClick = () => {
        toggleLeftmenu(false);
        setDropdownStates({
            transaction: false,
        });
    };

    return (
        <React.Fragment>
            <div className="topnav" ref={navRef}>
                <div className="container-fluid">
                    <nav
                        className="navbar navbar-light navbar-expand-lg topnav-menu"
                        id="navigation"
                    >
                        <Collapse isOpen={leftMenu} className="navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link
                                        to="/dashboard"
                                        className="nav-link arrow-none"
                                        onClick={handleOptionClick}
                                    >
                                        <i className="bx bx-home-circle me-2"></i>
                                        {t("Dashboard")}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link
                                        to="/CustomerBeneficiary"
                                        className="nav-link arrow-none"
                                        onClick={handleOptionClick}
                                    >
                                        <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: "8px" }} />
                                        {t("Beneficiary")}
                                    </Link>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link
                                        to="#"
                                        onClick={() => toggleDropdown("transaction")}
                                        className="nav-link arrow-none"
                                    >
                                        <i className="bx bx-window me-2"></i>
                                        {t("Transaction")}
                                        <div className={classname("dropdown-menu", { show: dropdownStates.transaction })}>
                                            <Link
                                                to="/addtransaction"
                                                className="dropdown-item"
                                                onClick={handleOptionClick}
                                            >
                                                {t("Add Transaction")}
                                            </Link>
                                            <Link
                                                to="/managetransaction"
                                                className="dropdown-item"
                                                onClick={handleOptionClick}
                                            >
                                                {t("Manage Transaction")}
                                            </Link>
                                            <Link
                                                to="/transactionhistory"
                                                className="dropdown-item"
                                                onClick={handleOptionClick}
                                            >
                                                {t("Transaction History")}
                                            </Link>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    );
};

Navbar.propTypes = {
    leftMenu: PropTypes.bool,
    t: PropTypes.func.isRequired,
    toggleLeftmenu: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(
    connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar))
);
