import { Tooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
    Col,
    Row,
    Form,
    Card,
    Label,
    Input,
    Button,
} from "reactstrap";
import Swal from "sweetalert2";
import ReactSelect from "react-select";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import AVEBeneficiary from "../Beneficiary/AVEBeneficiary";
import { SelectStyle } from "../../common/data/SelectStyle";
import React, { useEffect, useState, useContext } from "react";
import { BaseCurrencyContext } from "../../common/data/context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { faMinus, faEquals, faX, faPlus, faDivide, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";

const DashboardTransfer = () => {

    const navigate = useNavigate();

    const [currency, setCurrency] = useState([]);
    const [currencyIcons, setCurrencyIcons] = useState({});
    const { currencyData } = useContext(BaseCurrencyContext);

    const [searchTerm, setSearchTerm] = useState("");
    const [beneficiary, setBeneficiary] = useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

    const [globalTransferData, setGlobalTransferData] = useState(null);
    const [documentData, setDocumentData] = useState([]);

    const fromCurrencyTransfer = currencyData?.value;
    const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0");
    const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);

    const toCurrencyTransfer = selectedBeneficiary?.currencyName;
    const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0");
    const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState(0);

    const [buyRate, setBuyRate] = useState(0);
    const [mainBranch, setMainBranch] = useState(null);
    const [rateHistoryId, setRateHistoryId] = useState(null);

    const [errors, setErrors] = useState({});
    const [modal1, setModal1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [enableTransfer, setEnableTransfer] = useState(true);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleHelpClick = () => {
        window.open('https://sitapi.crescentexchange.com/help/customer/DashboardTransfer.pdf');
    };

    const transferData = globalTransferData?.find((x) => {
        const targetCurrency = toCurrencyTransfer

        return x.currencyCode === targetCurrency;
    });

    const transferRate = transferData ? parseFloat(transferData.rate) : 0;

    const calculateFee = (fee, fromCurrency, toCurrency, buyRate) => {

        if (fromCurrency === "SGD") {
            return fee;
        } else if (toCurrency === "SGD") {
            return fee / buyRate;
        } else {
            return fee / buyRate;
        }

    };

    let agentFee = transferData ? parseFloat(transferData.agentCharges) : 0;
    let wireFee = transferData ? parseFloat(transferData.wireTransferFee) : 0;
    let commissionFee = transferData ? parseFloat(transferData.commissionCharges) : 0;

    const convertedWireFee = calculateFee(wireFee, fromCurrencyTransfer, toCurrencyTransfer, buyRate);
    const convertedCommissionFee = calculateFee(commissionFee, fromCurrencyTransfer, toCurrencyTransfer, buyRate);
    const convertedAgentFee = calculateFee(agentFee, fromCurrencyTransfer, toCurrencyTransfer, buyRate);

    console.log({
        buyRate,
        transferData, transferRate,
        fromCurrencyTransfer, toCurrencyTransfer,
        wireFee, commissionFee, agentFee,
        convertedWireFee, convertedCommissionFee, convertedAgentFee,
    });

    const resetTransaction = () => {
        setIsLoading(false);

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
            beneficiary: ""
        }));

        setFromCurrencyDisplay("0");
        setToCurrencyDisplay("0");

        //Transfer
        setSelectedBeneficiary(null);
        setFromCurrencyValueTransfer(0);
        setToCurrencyValueTransfer(0);

    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch currencies
                const currencyResponse = await getApiData("api/Currency/GetAllCurrencies");
                if (currencyResponse.success === true) {
                    const icons = {};
                    currencyResponse.data.forEach(currency => {
                        const flag = `data:image/png;base64,${currency.currencyFlag}`;
                        const normalizedCurrencyName = currency.currencyName.split('(')[0].trim();
                        icons[currency.currencyCode.trim()] = flag;
                        icons[normalizedCurrencyName] = flag;
                    });
                    setCurrencyIcons(icons);

                    const mappedResponse = currencyResponse.data.map((item, index) => ({
                        index: index + 1,

                        currencyId: item.currencyId,
                        currencyName: item.currencyName,
                        currencyCode: item.currencyCode,
                    }));

                    setCurrency(mappedResponse);
                } else {
                    setCurrency([]);
                }

                // Fetch transfer rates
                const rateResponse = await getApiData("api/RateMaster/GetGlobalTransferRate");
                if (rateResponse.success === true) {
                    const mappedTransferRates = rateResponse.data.map(rate => ({
                        rateHistoryId: rate.rateHistoryId,
                        currencyCode: rate.currencyCode,
                        currencyName: rate.currencyName,
                        rate: rate.value !== "" ? parseFloat(rate.value) : 0,
                        worldRate: rate.worldRate !== "" ? parseFloat(rate.worldRate) : 0,
                        transferRate: rate.transferRate !== "" ? parseFloat(rate.transferRate) : 0,
                        wireTransferFee: rate.wireTransferFee !== "" ? parseFloat(rate.wireTransferFee) : 0,
                        agentCharges: rate.agentCharges !== "" ? parseFloat(rate.agentCharges) : 0,
                        commissionCharges: rate.commissionCharges !== "" ? parseFloat(rate.commissionCharges) : 0,
                        totalFeesAdmin: rate.totalFeesAdmin !== "" ? parseFloat(rate.totalFeesAdmin) : 0,
                        buyRateMainBranch: rate.buyRateMainBranch !== "" ? parseFloat(rate.buyRateMainBranch) : 0,
                        buyRateHistoryIdMainBranch: rate.buyRateHistoryIdMainBranch ? parseFloat(rate.buyRateHistoryIdMainBranch) : 0,
                    }));
                    console.log(mappedTransferRates);
                    setGlobalTransferData(mappedTransferRates);

                } else {
                    setGlobalTransferData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (selectedBeneficiary === null) {
            setErrors({
                beneficiary: "Please select a Beneficiary to transfer Money."
            });
        } else {
            setErrors({
                beneficiary: ""
            });
        }

        getMainBranch();
        fetchData();
    }, []);

    const fetchContextBuyRate = async () => {
        try {
            const resp = await getApiData(`api/RateMaster/GetTransactionRate?fromCurr=${currencyData?.value}`);
            if (resp.success === true) {
                setRateHistoryId(resp.data.rateHistoryId);
                setBuyRate(parseFloat(resp.data.buyRate));
            } else {
                setBuyRate(null);
                setRateHistoryId(null);
            }
        } catch (error) {
            console.error('Error occuried', error);
        }
    }

    const fetchBeneficiaries = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData("api/Customerbeneficiary/GetAllBeneficiaryByCustomer");
            const filteredResponse = response.data.filter(item => item.status === 2 && item.active === 1);
            const mappedBeneficiaries = filteredResponse.map(item => ({
                label: item.beneficiaryName,
                value: item.custBenId,
                currencyName: item.currencyName.split('(')[0].trim(),
                currencyCode: item.currencyName.split('(')[1]?.replace(')', '') || "",
            }));
            setBeneficiary(mappedBeneficiaries.filter(x => x.currencyCode !== fromCurrencyTransfer));
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching beneficiaries", error);
        }
    };

    useEffect(() => {
        fetchContextBuyRate();
        if (fromCurrencyTransfer !== undefined) {
            fetchBeneficiaries();
        }

        if (fromCurrencyTransfer === toCurrencyTransfer) {
            resetTransaction();
        }

        if (toCurrencyTransfer === "SGD" && !buyRate) {
            setEnableTransfer(false);
            Swal.fire({
                text: "Transfer not available for this Currency!",
                icon: "info",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                confirmButtonColor: "#556ee6",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    resetTransaction();
                }
            });
        }

        if (toCurrencyTransfer !== undefined && toCurrencyTransfer !== "SGD" && !transferRate) {
            setEnableTransfer(false);
            Swal.fire({
                text: "Transfer not available for this Beneficiary!",
                icon: "info",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                confirmButtonColor: "#556ee6",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    resetTransaction();
                }
            });
        }

    }, [fromCurrencyTransfer, toCurrencyTransfer, buyRate, transferRate,]);

    const getMainBranch = async () => {
        const response = await getApiData("api/BranchMaster/GetMainBranch");
        if (response.success === true) {
            const mapMainBranch = response.data.map(branch => ({
                branchId: branch.branchId,
                branchName: branch.branchName,
            }));
            setMainBranch(mapMainBranch[0]);
        } else {
            setMainBranch(null);
        }

    };

    const setExchangeRate = () => {

        if (fromCurrencyTransfer === "SGD") {
            return transferRate.toFixed(10);
        } else if (toCurrencyTransfer === "SGD") {
            return buyRate.toFixed(10);
        } else {
            return (buyRate / transferRate).toFixed(10);
        }

    };

    const handlePrecision = (value) => {
        return Math.round((value + Number.EPSILON) * 1000000000) / 1000000000;
    };

    const handleSubmitTransfer = async (e) => {
        e.preventDefault();

        if (!selectedBeneficiary) {
            toast.error("Please select a Beneficiary.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        }

        if (fromCurrencyValueTransfer <= 0) {
            setErrors({
                fromCurrency: "Value cannot be empty.",
                toCurrency: "Value cannot be empty.",
            });
            return;
        }

        const fromRateHistory = globalTransferData?.find(
            (x) => x.currencyCode === fromCurrencyTransfer
        );

        const toRateHistory = globalTransferData?.find(
            (x) => x.currencyCode === toCurrencyTransfer
        );

        const TransferData = {

            toCustBenId: selectedBeneficiary.value,

            fromCurrType: fromCurrencyTransfer,
            fromCurrValue: handlePrecision(fromCurrencyValueTransfer),

            toCurrType: toCurrencyTransfer,
            toCurrValue: handlePrecision(toCurrencyValueTransfer),

            exchangeRate: setExchangeRate(),

            fromBranchId: mainBranch?.branchId,
            transactionType: 3,

            fromRateHistoryId: fromRateHistory?.rateHistoryId,
            toRateHistoryId: toRateHistory?.rateHistoryId,
            BuyRateHistoryIdMainBranch: rateHistoryId,
        };

        try {
            //console.log(TransferData);
            //return;
            setIsLoading(true);
            const response = await postApiData(
                "api/Transaction/InsertTransaction",
                TransferData
            );
            if (response.success === true) {
                toast.success("Transaction request has been completed", {
                    position: "top-right",
                    autoClose: 3000,
                });
                resetTransaction();
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/profile");
                    } else {
                        resetTransaction();
                    }
                });
            }
        } catch (error) {
            resetTransaction();
            toast.error("Transaction failed, Please try again.", {
                position: "top-right",
                autoClose: 1500,
            });
        }
    };

    const toggleAddModal = () => setModal1(!modal1);

    const openADDBeneficiary = () => {
        toggleAddModal();
        setSearchTerm("");
    }

    const filteredBeneficiaries = beneficiary.filter(b =>
        b.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!filteredBeneficiaries.length && typedBeneficiaryName) {
        filteredBeneficiaries.push({
            label: `Create New Beneficiary "${typedBeneficiaryName}"`,
            value: "create-new",
        });
    }

    const getCurrencyFlag = (currencyName) => {
        return currencyIcons[currencyName] || "";
    };


    const convertFromTransferCurrency = (e) => {
        const inputValue = e.target.value;

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        let convertedValue = 0;
        const amount = parseFloat(inputValue) || 0;
        const totalFee = parseFloat((convertedWireFee || 0) + (convertedCommissionFee || 0) + (convertedAgentFee || 0));

        setFromCurrencyValueTransfer(amount);

        if (amount <= 10 || amount < totalFee) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `Amount must be greater than 10, total fees (${totalFee.toFixed(2)}), and cannot be negative.`,
                toCurrency: ""
            }));
            setToCurrencyDisplay("0");
            setToCurrencyValueTransfer(0);
            return;
        }

        if (fromCurrencyTransfer === "SGD") {
            if (transferRate > 0) {
                convertedValue = (amount - totalFee) / transferRate;
            } else {
                setErrors({
                    fromCurrency: "Transfer rate is invalid.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                setToCurrencyDisplay("0");
                return;
            }
        } else if (toCurrencyTransfer === "SGD") {
            if (buyRate > 0) {
                convertedValue = (amount - totalFee) * buyRate;
                if (convertedValue < 1) {
                    setErrors({
                        fromCurrency: "",
                        toCurrency: "Converted SGD cannot be less than 1",
                    });
                    setToCurrencyValueTransfer(0);
                    setToCurrencyDisplay("0");
                    return;
                }
            } else {
                setErrors({
                    fromCurrency: "Invalid buy rate for conversion.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                setToCurrencyDisplay("0");
                return;
            }
        } else {
            if (buyRate <= 0 || transferRate <= 0) {
                setErrors({
                    fromCurrency: "Invalid buy or transfer rates.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                setToCurrencyDisplay("0");
                return;
            }

            const subAmount = amount - totalFee;
            const convertedSGD = subAmount * buyRate;
            convertedValue = convertedSGD / transferRate;
        }

        if (convertedValue.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Amount cannot exceed 10 digits."
            }));
            setToCurrencyValueTransfer(0);
            setToCurrencyDisplay("0");
            return;
        }

        setErrors({
            fromCurrency: "",
            toCurrency: "",
        });

        setToCurrencyValueTransfer(convertedValue);
        setToCurrencyDisplay(convertedValue.toFixed(2));

    };

    const convertToTransferCurrency = (e) => {
        const inputValue = e.target.value;

        setToCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        let convertedValue = 0;
        const amount = parseFloat(inputValue) || 0;
        const totalFee = parseFloat((convertedWireFee || 0) + (convertedCommissionFee || 0) + (convertedAgentFee || 0));

        setToCurrencyValueTransfer(amount);

        if (amount <= 0 || amount < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `Amount must be greater than 10 and cannot be negative.`,
            }));
            setFromCurrencyValueTransfer(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (fromCurrencyTransfer === "SGD") {
            if (transferRate > 0) {
                convertedValue = (amount * transferRate) + totalFee;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Invalid transfer rate.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0");
                return;
            }
        } else if (toCurrencyTransfer === "SGD") {
            if (buyRate > 0) {
                convertedValue = (amount / buyRate) + totalFee;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Invalid buy rate for conversion.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0");
                return;
            }
        } else {
            if (buyRate <= 0 || transferRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Invalid buy or transfer rates.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0");
                return;
            }

            const convertedSGD = amount * transferRate;
            convertedValue = (convertedSGD / buyRate) + totalFee;
        }

        if (convertedValue.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Amount cannot exceed 10 digits."
            }));
            setFromCurrencyValueTransfer(0);
            setFromCurrencyDisplay("0");
            return;
        }

        setErrors({
            fromCurrency: "",
            toCurrency: "",
        });

        setFromCurrencyValueTransfer(convertedValue);
        setFromCurrencyDisplay(convertedValue.toFixed(2));
    };


    const getDocument = async (custBenId) => {
        const docResponse = await getApiData(`api/Customerbeneficiary/GetBeneficiaryDocByCustBenId?CustBenId=${custBenId}`);
        const document = await docResponse.data;

        setDocumentData(document)
    }

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />

            <AVEBeneficiary
                editMode={3}
                isOpen={modal1}
                currencies={currency}
                toggle={toggleAddModal}
                getDocument={getDocument}
                documentData={documentData}
                createBenFor={toCurrencyTransfer}
                getAvaBeneficiary={fetchBeneficiaries}
            />
            {
                isLoading ? (
                    <Loader />
                ) : (
                    <Card className="shadow-sm p-4">
                        <div className="ms-3 d-flex justify-content-end  ">
                            <FontAwesomeIcon
                                icon={faQuestionCircle}
                                onClick={handleHelpClick}
                                color="#556ee6"
                                id="helpIcon"

                                style={{ fontSize: '1.2em', cursor: "pointer" }}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                target="helpIcon"
                                toggle={() => setTooltipOpen(!tooltipOpen)}
                            >
                                Help
                            </Tooltip>
                        </div>
                        {isLoading
                            ? <Loader />
                            : (

                                <Form onSubmit={handleSubmitTransfer}>

                                    <div className="mb-3">
                                        <Label>Select Beneficiary</Label>
                                        <RequiredAsterisk />
                                        <Row className="align-items-center">
                                            <Col xs={10} md={10}>
                                                <ReactSelect
                                                    styles={SelectStyle}
                                                    value={selectedBeneficiary}
                                                    onInputChange={(inputValue) => {
                                                        setSearchTerm(inputValue);
                                                        setTypedBeneficiaryName(inputValue);
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        if (selectedOption?.value === "create-new") {
                                                            openADDBeneficiary();
                                                        } else {
                                                            setErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                fromCurrency: "",
                                                                toCurrency: "",
                                                                beneficiary: ""
                                                            }));
                                                            resetTransaction();
                                                            setSelectedBeneficiary(selectedOption);
                                                        }
                                                    }}

                                                    options={beneficiary.map((option) => ({
                                                        value: option.value,
                                                        label: option.label,
                                                        currencyName: option.currencyCode,
                                                        icon: getCurrencyFlag(option.currencyCode),
                                                    }))}
                                                    formatOptionLabel={({ label, icon, currencyName }) => (
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <span>{label}</span>
                                                            <img
                                                                src={icon}
                                                                alt="flag"
                                                                style={{ width: 20, height: 20, marginLeft: 8 }}
                                                            />
                                                            <span style={{ marginLeft: 5, fontSize: 12, }}>
                                                                ( {currencyName})
                                                            </span>

                                                        </div>
                                                    )}
                                                    isClearable={() => {
                                                        resetTransaction()
                                                    }}
                                                />
                                            </Col>

                                            <Col xs={2} md={2} className="d-flex justify-content-start">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={openADDBeneficiary}
                                                >

                                                    <span className="d-block d-md-none">
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </span>
                                                    <span className="d-none d-md-inline">
                                                        <FontAwesomeIcon icon={faPlus} /> Add Beneficiary
                                                    </span>
                                                </button>
                                            </Col>

                                        </Row>
                                        {errors.beneficiary && <div className="text-danger mt-1">{errors.beneficiary}</div>}
                                    </div>

                                    <Row>
                                        <Col md={6}>
                                            <Label>You Give</Label>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    background: "#F8F8F8",
                                                    padding: "2px",
                                                }}
                                            >
                                                <img
                                                    src={currencyIcons[fromCurrencyTransfer]}
                                                    alt={`${fromCurrencyTransfer} flag`}
                                                />
                                                <Input
                                                    value={fromCurrencyTransfer}
                                                    disabled
                                                    style={{ border: "none" }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <Label>{fromCurrencyTransfer} Amount </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                disabled={!selectedBeneficiary}
                                                value={fromCurrencyDisplay}
                                                onChange={convertFromTransferCurrency}
                                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                            />
                                            {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                                        </Col>
                                    </Row>

                                    {selectedBeneficiary !== null
                                        && enableTransfer &&
                                        <>

                                            {convertedWireFee > 0 &&
                                                <Row className="mb-3 text-end mt-2">
                                                    <Col lg={6} className="text-start">
                                                        <span>Wire Transfer Fee</span>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <span>
                                                            <FontAwesomeIcon icon={faMinus} />
                                                            {" "}
                                                            {convertedWireFee.toFixed(3)}
                                                            {" "}
                                                            {fromCurrencyTransfer}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            }

                                            {convertedCommissionFee > 0 &&
                                                <Row className="mb-3 text-end">
                                                    <Col lg={6} className="text-start">
                                                        <span>Commission Fee</span>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <span>
                                                            <FontAwesomeIcon icon={faMinus} />
                                                            {" "}
                                                            {convertedCommissionFee.toFixed(3)}
                                                            {" "}
                                                            {fromCurrencyTransfer}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            }

                                            {convertedAgentFee > 0 &&
                                                <Row className="mb-3 text-end">
                                                    <Col lg={6} className="text-start">
                                                        <span>Agent Fee</span>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <span>
                                                            <FontAwesomeIcon icon={faMinus} />
                                                            {" "}
                                                            {convertedAgentFee.toFixed(3)}
                                                            {" "}
                                                            {fromCurrencyTransfer}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            }


                                            {(convertedWireFee > 0 || convertedCommissionFee > 0 || convertedAgentFee > 0) &&
                                                <>
                                                    <hr />
                                                    <Row className="mb-3 text-end">
                                                        <Col lg={6} className="text-start">
                                                            <span>Total {fromCurrencyTransfer}</span>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <span>
                                                                <FontAwesomeIcon icon={faEquals} />
                                                                {" "}
                                                                {console.log({ fromCurrencyValueTransfer, convertedWireFee, convertedCommissionFee, convertedAgentFee })}
                                                                {fromCurrencyValueTransfer > 0
                                                                    ? (fromCurrencyValueTransfer - (convertedWireFee + convertedCommissionFee + convertedAgentFee)).toFixed(3)
                                                                    : 0
                                                                }
                                                                {" "}
                                                                {fromCurrencyTransfer}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }

                                            <Row className="mb-3 mt-3 text-end">
                                                <Col lg={6} className="text-start">
                                                    <span>Transfer Rate</span>
                                                </Col>
                                                <Col lg={6}>
                                                    <span>
                                                        {toCurrencyTransfer === "SGD"
                                                            ? <FontAwesomeIcon icon={faX} />
                                                            : <FontAwesomeIcon icon={faDivide} />
                                                        }
                                                        {" "}
                                                        {toCurrencyTransfer === "SGD" && fromCurrencyTransfer === "SGD"
                                                            ? 0
                                                            : toCurrencyTransfer === "SGD"
                                                                ? buyRate.toFixed(4)
                                                                : fromCurrencyTransfer === "SGD"
                                                                    ? transferRate.toFixed(4)
                                                                    : toCurrencyTransfer !== "SGD" && fromCurrencyTransfer !== "SGD"
                                                                        ? (buyRate / transferRate).toFixed(4)
                                                                        : 0
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>

                                        </>
                                    }

                                    <Row>
                                        <Col md={6}>
                                            <Label>Beneficiary Receive</Label>
                                            {selectedBeneficiary
                                                ?
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        fontSize: "16px",
                                                    }}>
                                                    <img
                                                        src={currencyIcons[toCurrencyTransfer]}
                                                        alt="Currency flag"
                                                        style={{ width: "20px", marginRight: "5px" }}
                                                    />
                                                    <span>{toCurrencyTransfer}</span>
                                                </div>
                                                :
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    value="Please select Beneficiary"
                                                />
                                            }
                                        </Col>

                                        <Col md={6}>
                                            <Label>{toCurrencyTransfer} Amount</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Amount"
                                                value={toCurrencyDisplay}
                                                onChange={convertToTransferCurrency}
                                                onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
                                                disabled={!selectedBeneficiary}
                                            />
                                            {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                                        </Col>
                                    </Row>

                                    <div className="d-flex justify-content-between mt-4">
                                        <Button
                                            color="danger"
                                            onClick={resetTransaction}
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={
                                                isLoading ||
                                                fromCurrencyTransfer === toCurrencyTransfer ||
                                                Object.values(errors).some((error) => error !== "")
                                            }
                                        >
                                            Submit
                                        </Button>
                                    </div>

                                </Form>
                            )}
                    </Card>
                )}
        </React.Fragment>
    );
};

export default DashboardTransfer;


