import React from  'react';
import { Modal, Button, Table } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { FaDownload } from "react-icons/fa";
import { Card } from 'react-bootstrap';
import logo from "../../../images/logo-dark.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faEquals, faX, faDivide } from '@fortawesome/free-solid-svg-icons';

const ReceiptModal = ({ show, handleClose, receiptData, buyRate, chargesData }) => {
  
    const generatePDF = async () => {
        const modalContent = document.getElementById('modal-print-content');
        if (!modalContent) return;

        try {
           
            const pdfWidth = 210; 
            const pdfHeight = 297;
            const scaleFactor = 2; 

            const canvas = await html2canvas(modalContent, {
                scale: scaleFactor,
                useCORS: true,
                width: modalContent.scrollWidth,
                height: modalContent.scrollHeight,
            });

            const imgData = canvas.toDataURL('image/png');
            const imgWidth = pdfWidth - 20; 
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            const doc = new jsPDF('p', 'mm', 'a4');

            if (imgHeight > pdfHeight - 20) {
               
                const scaledHeight = pdfHeight - 20; 
                const scaledWidth = (canvas.width * scaledHeight) / canvas.height;
                doc.addImage(imgData, 'PNG', 10, 10, scaledWidth, scaledHeight);
            } else {
                
                doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
            }

           
            doc.save('receipt.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };


    const handlePrint = () => {
        const modalContent = document.getElementById('modal-print-content');
        if (!modalContent) return;

        const printWindow = window.open('', '_blank', 'width=800,height=600');
        const modalStyles = Array.from(document.styleSheets)
            .map((styleSheet) => {
                try {
                    return Array.from(styleSheet.cssRules)
                        .map((rule) => rule.cssText)
                        .join('\n');
                } catch {
                    return '';
                }
            })
            .join('\n');

        printWindow.document.write(`
        <html>
            <head>
                <title>Transaction Receipt</title>
                <style>
                    ${modalStyles}
                    body {
                        font-family: Arial, sans-serif;
                        padding: 20px;
                    }
                    .table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .table th, .table td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                    .text-center {
                        text-align: center;
                    }
                </style>
            </head>
            <body>
                ${modalContent.outerHTML}
            </body>
        </html>
    `);

        printWindow.document.close();
        printWindow.focus();

        printWindow.onload = () => {
            printWindow.print();
            printWindow.close();
        };
    };

    const agentFee = parseFloat(chargesData?.agentCharges);
    const wireFee = parseFloat(chargesData?.wireTransferFee);
    const commissionFee = parseFloat(chargesData?.commissionCharges);
    const exchangeBuyRate = parseFloat(buyRate);
    const fromCurrencyValue = parseFloat(receiptData?.fromCurrValue);
    const toCurrencyValue = parseFloat(receiptData?.toCurrValue);

    return (
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body id="modal-print-content">
                <div className="text-center mb-4">
                    <img src={logo} alt="Logo" className="img-fluid" style={{ maxHeight: '60px', maxWidth: '200px' }} />
                </div>
                <h3 className="text-center mb-3">Transaction Receipt</h3>
                <div className="flex-column flex-md-row justify-content-around align-items-center p-3  ">
                 
                        <p><strong>Transaction ID:</strong> {receiptData.transactionId}</p>
                        <p><strong>Transaction Date:</strong> {receiptData.date}</p>
                   
                        <p><strong>Branch Name:</strong> {receiptData.branch}</p>
                        <p><strong>Customer Name:</strong> {receiptData.name}</p>
                    
                </div>

                <h4 className="text-center mt-4 mb-4">Currency Exchange</h4>
                <Card className="text-center" style={{ padding: '20px', borderRadius: '8px' }}>
                    <Table bordered responsive>
                        <tbody>
                            <tr>
                                <td><strong>{receiptData.fromCurrType} Currency:</strong></td>
                                <td>{receiptData.fromCurrValue}</td>
                            </tr>
                            {receiptData.transactionType === 1 && agentFee > 0 && (
                                <tr>
                                    <td><strong>Converted SGD:</strong></td>
                                    <td>{(fromCurrencyValue * receiptData.exchangeRate).toFixed(2)} SGD</td>
                                </tr>
                            )}
                            {agentFee > 0 && (
                                <tr>
                                    <td><strong>Agent Charges:</strong></td>
                                    <td>
                                        <FontAwesomeIcon icon={faMinus} className="me-1" />
                                        {receiptData.transactionType === 1
                                            ? agentFee + " SGD"
                                            : receiptData.transactionType === 2
                                                ? agentFee + " SGD"
                                                : receiptData.transactionType === 3
                                                    ? receiptData?.fromCurrType === "SGD"
                                                        ? agentFee + " SGD"
                                                        : receiptData?.toCurrType === "SGD"
                                                            ? (agentFee / receiptData.exchangeRate).toFixed(3) + ` ${receiptData?.fromCurrType}`
                                                            : receiptData?.fromCurrType !== "SGD" && receiptData?.toCurrType !== "SGD"
                                                                ? (agentFee / exchangeBuyRate).toFixed(3) + ` ${receiptData?.fromCurrType}`
                                                                : 0
                                                    : 0}
                                    </td>
                                </tr>
                            )}
                            {commissionFee > 0 && (
                                <tr>
                                    <td><strong>Commission Charges:</strong></td>
                                    <td>
                                        <FontAwesomeIcon icon={faMinus} className="me-1" />
                                        {receiptData.transactionType === 1
                                            ? commissionFee + " SGD"
                                            : receiptData.transactionType === 2
                                                ? commissionFee + " SGD"
                                                : receiptData.transactionType === 3
                                                    ? receiptData?.fromCurrType === "SGD"
                                                        ? commissionFee + " SGD"
                                                        : receiptData?.toCurrType === "SGD"
                                                            ? (commissionFee / receiptData.exchangeRate).toFixed(3) + ` ${receiptData?.fromCurrType}`
                                                            : receiptData?.fromCurrType !== "SGD" && receiptData?.toCurrType !== "SGD"
                                                                ? (commissionFee / exchangeBuyRate).toFixed(3) + ` ${receiptData?.fromCurrType}`
                                                                : 0
                                                    : 0}
                                    </td>
                                </tr>
                            )}
                            {wireFee > 0 && (
                                <tr>
                                    <td><strong>Wire Transfer Fee:</strong></td>
                                    <td>
                                        <FontAwesomeIcon icon={faMinus} className="me-1" />
                                        {receiptData.transactionType === 1
                                            ? wireFee + " SGD"
                                            : receiptData.transactionType === 2
                                                ? wireFee + " SGD"
                                                : receiptData.transactionType === 3
                                                    ? receiptData?.fromCurrType === "SGD"
                                                        ? wireFee + " SGD"
                                                        : receiptData?.toCurrType === "SGD"
                                                            ? (wireFee / receiptData.exchangeRate).toFixed(3) + ` ${receiptData?.fromCurrType}`
                                                            : receiptData?.fromCurrType !== "SGD" && receiptData?.toCurrType !== "SGD"
                                                                ? (wireFee / exchangeBuyRate).toFixed(3) + ` ${receiptData?.fromCurrType}`
                                                                : 0
                                                    : 0}
                                    </td>
                                </tr>
                            )}
                            {(agentFee > 0 || wireFee > 0 || commissionFee > 0) && receiptData.transactionType !== 1 && (
                                <tr>
                                    <td><strong>Total from currency:</strong></td>
                                    <td style={{ color: "#556ee6", fontWeight: "bold" }}>
                                        <FontAwesomeIcon icon={faEquals} className="me-1" style={{ color: "#556ee6" }} />
                                        {receiptData.transactionType === 2
                                            ? (fromCurrencyValue - agentFee) + " SGD"
                                            : receiptData.transactionType === 3
                                                ? receiptData?.fromCurrType === "SGD"
                                                    ? (fromCurrencyValue - (wireFee + commissionFee + agentFee)) + " SGD"
                                                    : receiptData?.toCurrType === "SGD"
                                                        ? (fromCurrencyValue - ((wireFee / receiptData.exchangeRate) + (commissionFee / receiptData.exchangeRate) + (agentFee / receiptData.exchangeRate))).toFixed(2) + ` ${receiptData?.fromCurrType}`
                                                        : receiptData?.fromCurrType !== "SGD" && receiptData?.toCurrType !== "SGD"
                                                            ? (fromCurrencyValue - ((wireFee / exchangeBuyRate) + (commissionFee / exchangeBuyRate) + (agentFee / exchangeBuyRate))).toFixed(3) + ` ${receiptData?.fromCurrType}`
                                                            : 0
                                                : 0}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td><strong>Exchange Rate:</strong></td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={
                                            receiptData.transactionType === 1
                                                ? faX
                                                : receiptData.transactionType === 2
                                                    ? faDivide
                                                    : receiptData.transactionType === 3
                                                        ? receiptData?.fromCurrType === "SGD"
                                                            ? faDivide
                                                            : receiptData?.toCurrType === "SGD"
                                                                ? faX
                                                                : receiptData?.fromCurrType !== "SGD" && receiptData?.toCurrType !== "SGD"
                                                                    ? faDivide
                                                                    : null
                                                        : null
                                        }
                                        className="me-1"
                                    />
                                    {receiptData.exchangeRate}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>{receiptData.toCurrType} Currency:</strong></td>
                                <td>
                                    <FontAwesomeIcon icon={faEquals} className="me-1" />
                                    {toCurrencyValue} {receiptData?.toCurrType}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card>

                <hr />
                <p className="text-center mt-5" style={{ fontSize: '16px', color: '#556ee6' }}>Thank you for using Crescent Exchange</p>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={generatePDF}><FaDownload /> PDF</Button>
                <Button variant="primary" onClick={handlePrint}>Print</Button>
            </Modal.Footer>
        </Modal>

    );
};

export default ReceiptModal;
