import React, { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faBellSlash,
    faExchangeAlt,
    faClock,
    faCalendarDay,
    faCalendarAlt,
    faTasks,
    faInfoCircle,
    faSyncAlt,
    faCheckCircle,
    faQuestionCircle,
    faEllipsisV
} from "@fortawesome/free-solid-svg-icons";
import { getApiData, putApiData } from "./../../../helpers/axiosHelper";
import { Tooltip } from "reactstrap";
import { HubConnectionBuilder, HttpTransportType, LogLevel } from "@microsoft/signalr";
import config from "../../../../config";
import ViewTransaction from "../../../pages/Transaction/ViewTransaction";
import { formatDateToUserTimeZone } from "../../../common/data/FormateDateTime";
export const NotificationType = { Action: 1, Info: 2 };

const isMobile = window.innerWidth <= 768;



const handleHelpClick = () => {
    window.open('https://sitapi.crescentexchange.com/help/customer/Notifications.pdf');
};
const Notifications = () => {
    const [notification, setNotification] = useState({
        bellMenu: false,
        activeTab: 1,
        totalCount: 0,
        actionCount: 0,
        infoCount: 0,
        actionNotifications: { today: [], yesterday: [], older: [] },
        infoNotifications: { today: [], yesterday: [], older: [] },
    });

    const [loading, setLoading] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [connection, setConnection] = useState(null);
    const hubUrl = `${config.apiUrl}chatHub`;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [modal1, setModal1] = useState(false);
    const toggleViewModal = () => setModal1(!modal1);
    const [commentData, setCommentData] = useState([]);
    const [transactionId, setTransactionID] = useState("");
    const [transactionData, setTransactionData] = useState([]);
    const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
    const [branchID, setBranchID] = useState("");
    const [branchData, setBranchData] = useState();
    const [optime, setOptime] = useState([]);
    const [transactionType, setTransactionType] = useState(null);
    const [chargesData, setChargesData] = useState(null);
    const [buyRate, setBuyRate] = useState(0);
    const [transferRate, setTransferRate] = useState(0);
    const [documentData, setDocumentData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const handleRefresh = async () => {
        try {
            setLoading(true);
            await getNotifications();
        } finally {
            setLoading(false);
        }
    };

    const toggleBellMenu = () =>
        setNotification((prev) => ({ ...prev, bellMenu: !prev.bellMenu }));

    const toggleTab = (tab) =>
        setNotification((prev) => ({ ...prev, activeTab: tab }));

    const categorizeNotifications = (notifications) => {
        const today = new Date();
        const startOfToday = new Date(today.setHours(0, 0, 0, 0));
        const startOfYesterday = new Date(today.setDate(today.getDate() - 1));

        // Sort notifications first to ensure newest are at the top
        const sorted = [...notifications].sort((a, b) => new Date(b.date) - new Date(a.date));

        return sorted.reduce(
            (acc, notification) => {
                const date = new Date(notification.date);
                if (date >= startOfToday) acc.today.push(notification);
                else if (date >= startOfYesterday) acc.yesterday.push(notification);
                else acc.older.push(notification);
                return acc;
            },
            { today: [], yesterday: [], older: [] }
        );
    };

    const getNotificationTitle = (id) => ({
        1: "Transaction",
        2: "Profile",
    }[id] || `Title ${id}`);

    const getNotifications = async () => {
        try {
            const resp = await getApiData("api/CustomerNotification/GetNotification");
            console.log("resp000", resp);
            const notifications = resp?.map((x) => ({
                title: getNotificationTitle(x.title),
                desc: x.description,
                value: x.notificationID,
                date: formatDateToUserTimeZone(x.createdAt),
                type: x.type,
                referenceID: x.referenceID,
                BranchID: x.branchID
            }));

            const actionNotifications = notifications.filter(
                (x) => x.type === NotificationType.Action
            );
            const infoNotifications = notifications.filter(
                (x) => x.type === NotificationType.Info
            );

            setNotification((prev) => ({
                ...prev,
                totalCount: notifications.length,
                actionCount: actionNotifications.length,
                infoCount: infoNotifications.length,
                actionNotifications: categorizeNotifications(actionNotifications),
                infoNotifications: categorizeNotifications(infoNotifications),
            }));
        } catch (error) {
            console.error('Error occurred', error);
        }
    };

    //useEffect(() => {
    //    getNotifications();
    //}, []);
    //useEffect(() => {
    //    const connectSignalR = async () => {
    //        try {
    //            const custUser = sessionStorage.getItem("custUser");
    //            const authObj = JSON.parse(custUser);
    //            if (!authObj?.token) {
    //                console.error("No token found in sessionStorage.");
    //                return;
    //            }
    //            const connection = new HubConnectionBuilder()
    //                .withUrl("https://localhost:7298/chatHub", {
    //                    accessTokenFactory: () => authObj.token,
    //                    skipNegotiation: false,
    //                    transport: HttpTransportType.WebSockets | HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling
    //                })
    //                .configureLogging(LogLevel.Debug)
    //                .withAutomaticReconnect([0, 2000, 10000, 30000])
    //                .build();

    //            connection.onclose(error => {
    //                console.log('Connection closed:', error);
    //            });

    //            connection.onreconnecting(error => {
    //                console.log('Reconnecting:', error);
    //            });

    //            connection.onreconnected(connectionId => {
    //                console.log('Reconnected:', connectionId);
    //            });

    //            connection.on("ReceiveNotification", (notificationData) => {
    //                console.log("New Notification Received: ", notificationData);
    //                setNotification((prev) => ({
    //                    ...prev,
    //                    totalCount: prev.totalCount + 1,
    //                }));
    //                getNotifications(); // Refresh notifications when new one is received
    //            });

    //            await connection.start();
    //            console.log("Connected to SignalR successfully!");
    //            setConnection(connection);

    //            // Get initial notifications after connection is established
    //            getNotifications();
    //        } catch (error) {
    //            console.error("Error connecting to SignalR:", error);
    //        }
    //    };

    //    connectSignalR();

    //    return () => {
    //        if (connection) {
    //            connection.stop();
    //        }
    //    };
    //}, []);

    useEffect(() => {
        const connectSignalR = async () => {
            try {
                const custUser = sessionStorage.getItem("custUser");
                const authObj = JSON.parse(custUser);
                if (!authObj?.token) {
                    console.error("No token found in sessionStorage.");
                    return;
                }

                const newConnection = new HubConnectionBuilder()
                    .withUrl(hubUrl, {
                        accessTokenFactory: () => authObj.token,
                        skipNegotiation: false,
                        transport: HttpTransportType.WebSockets | HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling
                    })
                    .configureLogging(LogLevel.Debug)
                    .withAutomaticReconnect([0, 2000, 10000, 30000])
                    .build();

                newConnection.onclose((error) => {
                    console.log('Connection closed:', error);
                });

                newConnection.onreconnecting((error) => {
                    console.log('Reconnecting:', error);
                });

                newConnection.onreconnected((connectionId) => {
                    console.log("Reconnected:", connectionId);
                    getNotifications(); // Refresh notifications on reconnect
                });

                // Handle new notifications
                newConnection.on("ReceiveNotification", (notificationData) => {
                    if (notificationData && notificationData.notificationID) {
                        // Play sound only for new notifications
                        const audio = new Audio("/assets/NotificationSound.wav");
                        audio.play().catch((err) => console.error("Error playing notification sound:", err));

                        console.log("notificationData999999", notificationData);
                        const newNotification = {
                            title: getNotificationTitle(notificationData.title),
                            desc: notificationData.description,
                            value: notificationData.notificationID,
                            date: formatDateToUserTimeZone(notificationData.createdAt),
                            type: notificationData.type,
                            referenceID: notificationData.referenceID,
                            BranchID: notificationData.branchID,
                        };
                        // Update state with new notification
                        setNotification(prev => {
                            const isAction = notificationData.type === NotificationType.Action;
                            const isInfo = notificationData.type === NotificationType.Info;

                            return {
                                ...prev,
                                totalCount: prev.totalCount + 1,
                                actionCount: isAction ? prev.actionCount + 1 : prev.actionCount,
                                infoCount: isInfo ? prev.infoCount + 1 : prev.infoCount,
                                actionNotifications: isAction
                                    ? {
                                        today: [newNotification, ...prev.actionNotifications.today],
                                        yesterday: prev.actionNotifications.yesterday,
                                        older: prev.actionNotifications.older
                                    }
                                    : prev.actionNotifications,
                                infoNotifications: isInfo
                                    ? {
                                        today: [newNotification, ...prev.infoNotifications.today],
                                        yesterday: prev.infoNotifications.yesterday,
                                        older: prev.infoNotifications.older
                                    }
                                    : prev.infoNotifications
                            };
                        });
                    }
                });

                // Handle notifications marked as read
                newConnection.on("NotificationMarkedAsRead", (notificationId) => {
                    console.log("Notification marked as read:", notificationId);
                    // Simply refresh notifications to get the latest state
                    getNotifications();
                });

                await newConnection.start();
                console.log("Connected to SignalR successfully!");
                setConnection(newConnection);

                await getNotifications(); // Initial fetch
            } catch (error) {
                console.error("Error connecting to SignalR:", error);
            }
        };

        connectSignalR();

        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, []);


    const formatTime = (date) =>
        new Date(date).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });

    //const handleNotificationClick = async (notificationID, type) => {
    //    try {
    //        await putApiData(
    //            `api/CustomerNotification/ReadNotification?NotificationId=${notificationID}`
    //        );

    //        setNotification((prev) => {
    //            const updatedActionNotifications = removeNotification(
    //                prev.actionNotifications,
    //                notificationID
    //            );
    //            const updatedInfoNotifications = removeNotification(
    //                prev.infoNotifications,
    //                notificationID
    //            );

    //            return {
    //                ...prev,
    //                actionNotifications: updatedActionNotifications,
    //                infoNotifications: updatedInfoNotifications,
    //                actionCount:
    //                    type === NotificationType.Action
    //                        ? prev.actionCount - 1
    //                        : prev.actionCount,
    //                infoCount:
    //                    type === NotificationType.Info
    //                        ? prev.infoCount - 1
    //                        : prev.infoCount,
    //            };
    //        });
    //    } catch (error) {
    //        console.log("Error marking notification as read:", error);
    //    }
    //};

    const handleNotificationClick = async (notificationID, type) => {
        try {
            await putApiData(
                `api/CustomerNotification/ReadNotification?NotificationId=${notificationID}`
            );

            setNotification(prev => {
                const updatedState = { ...prev };
                const notificationType = type === NotificationType.Action
                    ? 'actionNotifications'
                    : 'infoNotifications';

                // Remove notification from all categories
                ['today', 'yesterday', 'older'].forEach(category => {
                    updatedState[notificationType][category] =
                        updatedState[notificationType][category].filter(item => item.value !== notificationID);
                });

                // Update counts
                updatedState.totalCount = Math.max(0, updatedState.totalCount - 1);
                if (type === NotificationType.Action) {
                    updatedState.actionCount = Math.max(0, updatedState.actionCount - 1);
                } else {
                    updatedState.infoCount = Math.max(0, updatedState.infoCount - 1);
                }

                return updatedState;
            });
        } catch (error) {
            console.error("Error marking notification as read:", error);
        }
    };

    const removeNotification = (notifications, notificationID) => {
        return Object.fromEntries(
            Object.entries(notifications).map(([key, items]) => [
                key,
                items.filter((item) => item.value !== notificationID),
            ])
        );
    };
    const renderEmpty = () => (
        <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
            <FontAwesomeIcon icon={faBellSlash} shake size="2xl" className="mt-4" color="#FF5252" />
            <p>No unread notifications</p>
        </div>
    );

    const renderNotificationItems = (items, showDate = false) =>
        items.map((x) => (
            <DropdownItem
                key={x.value}
                className="d-flex"
                onClick={() => {
                    if (x.type === NotificationType.Action) {
                        console.log("referenceID", x.referenceID);
                        console.log("items", x);
                        // Passing referenceID and branchID to ViewTransactionDetails
                        ViewTransactionDetails({
                            transactionId: x.referenceID,
                            fromBranchId: x.BranchID,  // Assuming x.branchID is available
                        });
                        setTransactionID(x.referenceID);
                        toggleViewModal();
                    } else if (x.type === NotificationType.Info) {
                        return;
                    }
                }}
            >
                <div style={{ flex: 1, marginRight: '10px' }}>
                    <strong>{x.title}</strong>
                    <p
                        className="mb-0 text-truncate"
                        style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                        }}
                    >
                        {x.desc}
                    </p>
                </div>
                <small className="text-muted text-end">
                    {showDate ? formatDate(x.date) + ' ' : ''}
                    {formatTime(x.date)}
                </small>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="sm"
                    color="#28a745"
                    className="ms-2"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleNotificationClick(x.value, x.type);
                    }}
                    title="Mark as read"
                />
            </DropdownItem>
        ));


    const formatDate = (date) =>
        new Date(date).toLocaleDateString([], {
            month: 'short',
            day: 'numeric',
        });

    const renderTabContent = (notifications) =>
        Object.entries(notifications)
            .filter(([_, items]) => items.length > 0)
            .map(([category, items]) => (
                <div key={category} className="mt-3">
                    <h5 className="d-flex align-items-center">
                        <FontAwesomeIcon
                            icon={getCategoryIcon(category)}
                            size="sm"
                            color={getIconColor(category)}
                            className="me-2"
                        />
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                    </h5>
                    {renderNotificationItems(items, category === 'older')}
                </div>
            ));


    const getCategoryIcon = (category) => ({
        today: faCalendarDay,
        yesterday: faClock,
        older: faCalendarAlt,
    }[category]);

    const getIconColor = (category) => ({
        today: "#556ee6",
        yesterday: "#50a5f1",
        older: "#9E9E9E",
    }[category]);

    const getCategoryColor = (date) => {
        const today = new Date().setHours(0, 0, 0, 0);
        const yesterday = new Date(today).setDate(new Date().getDate() - 1);
        const notificationDate = new Date(date);

        return notificationDate >= today
            ? "#34c38f"
            : notificationDate >= yesterday
                ? "#FFC107"
                : "#9E9E9E";
    };

    const handleMarkAllAsRead = async () => {
        try {
            const allUnreadNotifications = [
                ...notification.actionNotifications.today,
                ...notification.actionNotifications.yesterday,
                ...notification.actionNotifications.older,
                ...notification.infoNotifications.today,
                ...notification.infoNotifications.yesterday,
                ...notification.infoNotifications.older,
            ];

            // Process all notifications using the existing handleNotificationClick method
            const promises = allUnreadNotifications.map(notif =>
                handleNotificationClick(notif.value, notif.type)
            );

            // Wait for all notifications to be processed
            await Promise.all(promises);

        } catch (error) {
            console.error("Error marking all notifications as read:", error);
            // Refresh notifications to ensure correct state
            getNotifications();
        }
    };

    const ViewTransactionDetails = (cellProps) => {
        const { transactionId, fromBranchId } = cellProps;
        setTransactionID(transactionId);
        setBranchID(fromBranchId);

        const getTransactionDetails = async () => {
            try {
                setIsLoading(true);
                // Fetch transaction details
                const transactionResponse = await getApiData(
                    `api/Transaction/GetTransaction?TransactionID=${transactionId}`
                );

                // Fetch branch details
                const branchResponse = await getApiData(
                    `api/BranchMaster/GetBranchWithOperation?branchId=${fromBranchId}`
                );

                // Map operational times to 12-hour format
                const operationalTimes = branchResponse.data.operationalTime.map((item) => ({
                    day: Days[item.day],
                    startTime: convertTo12HourFormat(item.startTime),
                    endTime: convertTo12HourFormat(item.endTime),
                }));

                setBranchData(branchResponse.data.branch);
                setOptime(operationalTimes);
                setSendTransactiondetails(transactionResponse);
                setCommentData(transactionResponse.comments);
                setTransactionType(transactionResponse.transactionType);
                setDocumentData(transactionResponse.transactionDocs)
                setDocumentData(transactionResponse.transactionDocs)

                // Fetch transaction charges
                const { transactionType, fromRateHistoryId, toRateHistoryId } = transactionResponse;

                await fetchTransactionCharges(transactionType, fromRateHistoryId, toRateHistoryId);
                setIsLoading(false);
            } catch (error) {
                console.error("Error occurred:", error);
            }
        };

        getTransactionDetails();
        toggleViewModal();
    };

    const fetchTransactionCharges = async (transactionType, fromRateHistoryId, toRateHistoryId, fromCurrType, toCurrType) => {
        try {
            let rateHistoryId = transactionType === 1 ? fromRateHistoryId : toRateHistoryId;

            let buyRate = 0;
            let transferRate = 0;


            if (transactionType === 3 && (fromCurrType !== "SGD" || toCurrType !== "SGD")) {
                const buyRateResponse = await getApiData(
                    `api/RateMaster/GetRateHistoryIdRate?rateHistoryId=${fromRateHistoryId}`
                );
                if (buyRateResponse?.success) {
                    buyRate = buyRateResponse.data?.rate || 0;
                }

                const transferRateResponse = await getApiData(
                    `api/RateMaster/GetRateHistoryIdRate?rateHistoryId=${toRateHistoryId}`
                );
                if (transferRateResponse?.success) {
                    transferRate = transferRateResponse.data?.rate || 0;
                }
            }

            if (rateHistoryId) {
                const chargesResponse = await getApiData(
                    `api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`
                );

                if (chargesResponse?.success) {
                    setChargesData(chargesResponse.data);
                    setBuyRate(buyRate);
                    setTransferRate(transferRate);
                } else {
                    console.error("Failed to fetch charges:", chargesResponse.message);
                }
            } else {
                console.warn("Rate history ID is null. Skipping getTransactionCharges API call.");
            }
        } catch (error) {
            console.error("Error fetching transaction charges:", error);
        }
    };


    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(":");
        const meridiem = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${meridiem}`;
    };

    const Days = {
        0: "All Days",
        1: "Sunday",
        2: "Monday",
        3: "Tuesday",
        4: "Wednesday",
        5: "Thursday",
        6: "Friday",
        7: "Saturday",
    };

    const { bellMenu, activeTab, actionNotifications, infoNotifications } = notification;

    return (
        <>
            <Dropdown isOpen={bellMenu} toggle={toggleBellMenu} className="ms-3">
                <DropdownToggle tag="button" className="btn header-item position-relative">
                    <FontAwesomeIcon icon={faBell} size="lg" />
                    {notification.totalCount > 0 && (
                        <span className="badge bg-danger rounded-circle position-absolute top-0 start-100 translate-middle mt-4 ms-n2">
                            {notification.totalCount}
                        </span>
                    )}
                </DropdownToggle>

                <DropdownMenu
                    className="dropdown-menu-end responsive-dropdown p-3"
                    style={{
                        maxHeight: '500px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        padding: '10px',
                        minWidth: isMobile ? "30vw" : "500px",
                    }}
                >

                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Notifications</h5>
                        <div className="d-flex justify-content-between align-items-center">
                            <FontAwesomeIcon
                                icon={faSyncAlt}
                                style={{
                                    cursor: "pointer",
                                    transition: "transform 0.5s linear",
                                    transform: loading ? "rotate(360deg)" : "rotate(0deg)",
                                }}
                                onClick={handleRefresh}
                            />
                            <FontAwesomeIcon
                                icon={faQuestionCircle}
                                onClick={handleHelpClick}
                                color="#556ee6"
                                id="helpIcon"
                                style={{ fontSize: '1.2em', cursor: "pointer", marginLeft: '2px' }}
                            />
                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                target="helpIcon"
                                toggle={() => setTooltipOpen(!tooltipOpen)}
                            >
                                Help
                            </Tooltip>

                            {/* Add Dropdown for Mark All as Read */}
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="ms-2">
                                <DropdownToggle tag="button" className="btn">
                                    <FontAwesomeIcon icon={faEllipsisV} size="lg" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={handleMarkAllAsRead}>
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            size="sm"
                                            className="me-2"
                                        />
                                        Mark All as Read
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>


                    <Nav tabs className="mb-3">
                        {[{ name: `Actions (${notification.actionCount})`, icon: faTasks },
                        { name: `Info (${notification.infoCount})`, icon: faInfoCircle },
                        ].map((tab, idx) => (
                            <NavItem key={idx}>
                                <NavLink
                                    className={`d-flex align-items-center ${activeTab === idx + 1 ? "active" : ""}`}
                                    style={{
                                        backgroundColor: activeTab === idx + 1 ? "#eff2f7" : "transparent",
                                    }}
                                    onClick={() => toggleTab(idx + 1)}
                                >
                                    <FontAwesomeIcon
                                        icon={tab.icon}
                                        size="lg"
                                        color={idx === 0 ? "#556ee6" : "#f46a6a"}
                                        className="me-2"
                                    />
                                    {tab.name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>

                    <TabContent activeTab={activeTab}  >
                        <TabPane tabId={1}  >
                            {Object.values(actionNotifications).some((arr) => arr.length)
                                ? renderTabContent(actionNotifications)
                                : renderEmpty()}
                        </TabPane>
                        <TabPane tabId={2}>
                            {Object.values(infoNotifications).some((arr) => arr.length)
                                ? renderTabContent(infoNotifications)
                                : renderEmpty()}
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
            {modal1 && (
                <ViewTransaction
                    isOpen={modal1}
                    toggle={toggleViewModal}
                    transactionId={transactionId}
                    optime={optime}
                    branchID={branchID}
                    showAction={true}
                    transactionType={transactionType}
                    chargesData={chargesData}
                    buyRate={buyRate}
                    transferRate={transferRate}
                    //editMode={editMode}
                    branchData={branchData}
                    commentData={commentData}
                    status={sendTransactionDetails?.status} 
                    sendTransactionDetails={sendTransactionDetails}
                    documentData={documentData}
                    loading={isLoading}
                //reload={reload}
                />
            )}
        </>
    );
};

export default Notifications;
