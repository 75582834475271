import axios from "axios";
import { setupCache, buildWebStorage } from "axios-cache-interceptor"; 
import config from "../../config";
import { forceLogout } from "../common/data/commonFunction";
function initializeHeader() {


    var custUser = sessionStorage.getItem("custUser");
    var authObj = JSON.parse(custUser);
    // Set up default config for axios
    axios.defaults.baseURL = config.apiUrl;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Authorization"] = "Bearer " + authObj?.token;

}

let cachedAxios = null;

function initializeCache() {
    const custUser = sessionStorage.getItem("custUser");
    const authObj = JSON.parse(custUser);

    // Set up the cachedAxios instance
    cachedAxios = setupCache(axios.create({
        baseURL: config.apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authObj?.token}`,
        },
    }), {
        storage: buildWebStorage(sessionStorage), // Use sessionStorage here
        ttl: 3 * 60 * 1000, // Cache TTL: 3 minutes
        exclude: {
            methods: ["post", "delete"], // Exclude POST and DELETE requests
        },
        debug: true, // Enable debug logs
    });
}


// Create a function to make a GET request
export const getApiData = async (endpoint, params = {}, useCache = false) => {
    try {
        initializeHeader();
        initializeCache();
        const axiosInstance = useCache ? cachedAxios : axios;
        const response = await axiosInstance.get(endpoint, {
            params,
        });
        return response.data;
    } catch (error) {
        forceLogout(error);
        console.error("Error in getApiData:", error);
        // throw error;
    }
};

// Create a function to make a POST request
export const postApiData = async (endpoint, data = {}) => {
    try {
        initializeHeader()
        const response = await axios.post(axios.defaults.baseURL + endpoint, data);
        return response.data;
    } catch (error) {
        // Handle error
        forceLogout(error)
        console.error("Error in postApiData:", error);
        // throw error;
    }
};

// Create a function to make a PUT request
export const putApiData = async (endpoint, data = {}) => {
    try {
        initializeHeader()
        const response = await axios.put(axios.defaults.baseURL + endpoint, data);
        return response.data;
    } catch (error) {
        // Handle error
        forceLogout(error)
        console.error("Error in putApiData:", error);
        // throw error;
    }
};

// Create a function to make a DELETE request
export const deleteApiData = async (endpoint, data = {}) => {
    try {
        initializeHeader()
        const response = await axios.delete(
            axios.defaults.baseURL + endpoint,
            data
        );
        return response.data;
    } catch (error) {
        // Handle error
        forceLogout(error)
        console.error("Error in deleteApiData:", error);
        // throw error;
    }
};

export default axios;
