import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    Input,
    Label,
    Form,
    FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { postApiData } from "../../helpers/axiosHelper";
import logoImg from "../../../images/crescent_logo.png";
import profileImg from "../../../images/profile-img.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";

const EmailVerification = () => {
    document.title = "Crescent Exchange | Verification";
    const { uid } = useParams();
    const navigate = useNavigate();

    const [timer, setTimer] = useState(120);
    const [isLoading, setIsLoading] = useState(false);
    const [isSendAgain, setIsSendAgain] = useState(false);

    // Handle Timer countdown
    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timer]);

    // Resend OTP logic
    const handleSendAgain = async () => {
        if (timer > 0) {
            toast.error(`Please wait ${Math.ceil(timer / 60)} minutes`, {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }
        setIsSendAgain(true);
        const response = await postApiData(`api/Email/SendOtpAgain?uid=${uid}`);
        if (response.success) {
            toast.success("OTP resent successfully.", {
                position: "top-right",
                autoClose: 3000,
            });
            setTimer(120);
        } else {
            toast.error(response.message, {
                position: "top-right",
                autoClose: 3000,
            });
        }
        setIsSendAgain(false);
    };

    // Form validation using Formik and Yup
    const validation = useFormik({
        initialValues: { emailcode: "" },
        validationSchema: Yup.object({
            emailcode: Yup.string()
                .min(6, "OTP must be 6 digits.")
                .max(6, "OTP must be 6 digits.")
                .matches(/^[0-9]+$/, "Enter only numbers.")
                .required("Please enter the verification code."),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            const response = await postApiData("api/Email/VerifyOtp", {
                enteredOtp: values.emailcode,
                uid: uid,
            });
            if (response.success) {
                toast.success("OTP verification successful.", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        navigate("/login", { replace: true });
                        setIsLoading(false);
                    },
                });
            } else {
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
                resetForm();
                setIsLoading(false);
            }
        },
    });

    // Timer display logic
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = ("0" + (seconds % 60)).slice(-2);
        return `${minutes}:${remainingSeconds}`;
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="home-btn d-block" style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 1000 }}>
                <Link
                    to="/home"
                    className="text-dark"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    <i className="bx bx-home h2" style={{ marginRight: '5px' }} />

                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Email Verification</h5>
                                                <p>Verify your email by OTP</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profileImg} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="avatar-md profile-user-wid mb-2">
                                        <Link to="/">
                                            <span className="avatar-title rounded-circle bg-light w-75 h-75 border border-dark border-2">
                                                <img src={logoImg} alt="" className="rounded-circle w-75" />
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="p-0">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">Enter Code</Label>
                                                <RequiredAsterisk />
                                                <Input
                                                    maxLength={6}
                                                    id="emailcode"
                                                    name="emailcode"
                                                    placeholder="Enter OTP"
                                                    type="input"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.emailcode}
                                                    disabled={isLoading}
                                                    invalid={!!(validation.touched.emailcode && validation.errors.emailcode)}
                                                />
                                                {validation.touched.emailcode && validation.errors.emailcode && (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.emailcode}
                                                    </FormFeedback>
                                                )}
                                            </div>
                                            {/* Timer Text */}
                                            <div className="text-end text-muted mb-3">
                                                {timer > 0 ? (
                                                    <span>Resend OTP in: {formatTime(timer)}</span>
                                                ) : (
                                                    <span>OTP expired, you can resend now</span>
                                                )}
                                            </div>
                                            <div className="mt-4 text-end">
                                                <button
                                                    onClick={handleSendAgain}
                                                    className="btn btn-primary me-2"
                                                    type="button"
                                                    disabled={isLoading || isSendAgain || timer > 0}
                                                >
                                                    {isSendAgain ? "Sending..." : "Resend OTP"}
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? "Verifying..." : "Verify"}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmailVerification;
