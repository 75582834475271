import moment from "moment";
import Swal from "sweetalert2";
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Loader from "../../components/Common/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Label, Input } from 'reactstrap';
import { SelectStyle } from "../../common/data/SelectStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { faMinus, faEquals, faX } from '@fortawesome/free-solid-svg-icons';

const SellForm = ({ branchID, sellingCurrency, rateType, rate, sellCharge, rateHisID, closeModal, }) => {

    // console.log({ branchID, isAllBranch, sellingCurrency, rateType, rate, sellCharge, rateHisID, });

    const navigate = useNavigate();

    const fromCurrencySelling = sellingCurrency;
    const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0");
    const [fromCurrencyValueSelling, setFromCurrencyValueSelling] = useState("");

    const toCurrencySelling = "SGD";
    const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0")
    const [toCurrencyValueSelling, setToCurrencyValueSelling] = useState("");

    const [branchOptions, setBranchOptions] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);

    const [currencies, setCurrencies] = useState([]);

    const [optime, setOptime] = useState({});

    const currentDate = new Date().toLocaleString();
    const [startDate, setStartDate] = useState(null);

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const fetchCurrencies = async () => {
        try {
            const response = await getApiData('api/Currency/GetAllCurrencies');
            if (response.success === true) {
                setCurrencies(response.data);
            } else {
                setCurrencies([]);
            }
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const validateDate = (selectedDate) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const selectedDateObj = new Date(selectedDate);

        if (!selectedDate) {
            setErrors({ date: 'Date is required' });
        } else if (selectedDateObj < currentDate) {
            setErrors({ date: 'You cannot select a past date' });
        } else {
            setErrors({});
        }
    };

    const handlePrecision = (value) => {
        return Math.round((value + Number.EPSILON) * 1000000000) / 1000000000;
    };

    const handleSubmitSelling = async (e) => {
        e.preventDefault();

        if (branchID === 0 && selectedBranch === null) {
            setErrors({
                branch: "Please select a branch.",
            });
            return;
        }

        if (!fromCurrencyValueSelling && !toCurrencyValueSelling) {
            setErrors({
                fromCurrency: "Enter the amount you wish to Sell.",
                toCurrency: "Enter the amount you wish to Sell.",
            });
            return;
        }

        const formattedDate = startDate ? new Date(startDate).toISOString() : "";

        const SellingData = {

            fromCurrType: fromCurrencySelling,
            fromCurrValue: handlePrecision(fromCurrencyValueSelling),
            toCurrType: toCurrencySelling,
            toCurrValue: handlePrecision(toCurrencyValueSelling),

            exchangeRate: rate,
            transactionType: 1,

            fromRateHistoryId: rateHisID,
            fromBranchId: selectedBranch?.value,
            pickup: formattedDate,

        };

        try {
            //console.log(SellingData);
            //return;
            setIsLoading(true);
            const response = await postApiData('api/Transaction/InsertTransaction', SellingData);
            if (response.success === true) {

                toast.success("Transaction completed successfully.", {
                    position: "top-right",
                    autoClose: 2000
                });
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile ",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/profile");
                    } else {
                        closeModal();
                    }
                });
            }
            closeModal();
            resetTransaction();
            setIsLoading(false);
        } catch (error) {
            console.error("Error during transaction", error);
            resetTransaction();
        }
    };

    const getBranchByCurrCode = async () => {
        try {
            setIsLoading(true);

            const apiEndpoint = branchID === 0
                ? `api/BranchMaster/GetBranchWithNoRS`
                : `api/BranchMaster/GetBranchById?branchId=${branchID}`;

            const response = await getApiData(apiEndpoint);
            setIsLoading(false);

            if (!response || !response.success && branchID !== 0) {
                console.error("API response error:", response?.message || "No response");
                setBranchOptions([]);
                return;
            }

            let filteredBranches = [];
            let branchOperationalTime = null;

            if (branchID === 0) {
                const branches = Array.isArray(response) ? response : [];
                filteredBranches = branches
                    .filter(branch => branch.active === 1)
                    .map(branch => ({
                        value: branch.branchId,
                        label: branch.branchName,
                        address: `${branch.street}, ${branch.city}, ${branch.postalCode}`,
                    }));
            } else {
                const branchData = response.data?.branch;
                const operationalTimes = response.data?.operationalTime;

                if (branchData) {
                    filteredBranches = [{
                        value: branchData.branchId,
                        label: branchData.branchName,
                        address: `${branchData.street}, ${branchData.city}, ${branchData.postalCode}`,
                    }];
                }

                if (operationalTimes && operationalTimes.length > 0) {
                    branchOperationalTime = {
                        startTime: convertTo12HourFormat(operationalTimes[0].startTime),
                        endTime: convertTo12HourFormat(operationalTimes[0].endTime),
                    };
                }
            }
            setBranchOptions(filteredBranches);

            if (filteredBranches.length === 1) {
                setSelectedBranch(filteredBranches[0]);
                setOptime(branchOperationalTime);
            }

            console.log("Filtered Branches:", filteredBranches);
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching branches:", error);
        }
    };

    const convertTo12HourFormat = (time) => {
        const [hour, minute] = time.split(":");
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute} ${ampm}`;
    };

    const resetTransaction = () => {
        setSelectedBranch(null);
        setFromCurrencyDisplay("0");
        setToCurrencyDisplay("0");
        setFromCurrencyValueSelling(0);
        setToCurrencyValueSelling(0);
    };

    const handleNumericInput = (e) => {
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Enter"
        ];

        const inputValue = e.target.value;


        if (allowedKeys.includes(e.key)) return;


        if (e.key === ".") {
            if (inputValue.includes(".")) {
                e.preventDefault();
                return;
            }
        } else if (!/^\d$/.test(e.key)) {
            e.preventDefault();
            return;
        }


        if (inputValue.length >= 10) {
            e.preventDefault();
        }
    };

    const convertFromCurrency = (e) => {
        const inputValue = e.target.value;

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueSelling("");
            setToCurrencyValueSelling("");
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: ""
            }));
            return;
        }

        if (inputValue.length > 20) return;

        const amount = parseFloat(inputValue) || 0;
        const exchangeRate = parseFloat(rate) > 0 ? parseFloat(rate) : 0;
        const charges = parseFloat(sellCharge) > 0 ? parseFloat(sellCharge) : 0;

        setFromCurrencyValueSelling(amount);

        if (amount <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `${fromCurrencySelling} must be greater than 10.`,
                toCurrency: ""
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `${fromCurrencySelling} must be greater than the selling fee.`,
                toCurrency: "",
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0");
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
        }

        const convertedToCurrency = (amount * exchangeRate) - charges;

        console.log({ amount, exchangeRate, charges, convertedToCurrency });

        if (convertedToCurrency <= 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Converted amount must be greater than 1."
            }));
            setToCurrencyDisplay("0");
            setToCurrencyValueSelling(0);
            return;
        }

        if (convertedToCurrency.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Amount cannot exceed 10 digits."
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: ""
        }));

        setToCurrencyValueSelling(
            convertedToCurrency > 0 ? convertedToCurrency : 0
        );
        setToCurrencyDisplay(convertedToCurrency.toFixed(2));
    };

    const convertToCurrency = (e) => {
        const inputValue = e.target.value;

        setToCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0");
            setToCurrencyValueSelling(0);
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: ""
            }));
            return;
        }

        const amount = parseFloat(inputValue) || 0;
        const exchangeRate = parseFloat(rate) > 0 ? parseFloat(rate) : 0;
        const charges = parseFloat(sellCharge) > 0 ? parseFloat(sellCharge) : 0;

        setToCurrencyValueSelling(amount);

        if (amount <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "SGD cannot be less than 10."
            }));
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "SGD must be greater than the buying fee."
            }));
            setFromCurrencyDisplay("0");
            setFromCurrencyValueSelling(0);
            return;
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: "",
            }));
        }

        const convertedFromCurrency = (amount + charges) / exchangeRate;
        console.log({ amount, exchangeRate, charges, convertedFromCurrency });


        if (convertedFromCurrency.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount cannot exceed 10 digits.",
                toCurrency: ""
            }));
            setFromCurrencyDisplay("0");
            setFromCurrencyValueSelling(0);
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: ""
        }));

        setFromCurrencyValueSelling(
            convertedFromCurrency > 0 ? convertedFromCurrency : 0
        );

        setFromCurrencyDisplay(convertedFromCurrency.toFixed(2));

    };

    const getCurrencyFlag = (currencyCode) => {
        const currency = currencies.find(c => c.currencyCode === currencyCode);
        return currency ? `data:image/png;base64,${currency.currencyFlag}` : '';
    };

    useEffect(() => {
        if (toCurrencySelling && rateType) getBranchByCurrCode(branchID, toCurrencySelling, rateType);
        fetchCurrencies();
    }, [branchID, toCurrencySelling, rateType]);

    return (
        <>

            <Form onSubmit={handleSubmitSelling}>
                <Row>
                    <Col md={6}>
                        <Label>You Give</Label>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #000', background: '#F8F8F8', padding: '2px' }}>
                            <img
                                src={getCurrencyFlag(fromCurrencySelling)}
                                alt={`${fromCurrencySelling} flag`}
                                style={{ width: '30px', height: '30px', marginRight: '5px' }}
                            />
                            <Input value={fromCurrencySelling} disabled style={{ border: 'none', fontWeight: '600', fontSize: '1rem' }} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <Label>You Get</Label>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #000', background: '#F8F8F8', padding: '2px' }}>
                            <img
                                src={getCurrencyFlag(toCurrencySelling)}
                                alt={`${toCurrencySelling} flag`}
                                style={{ width: '30px', height: '30px', marginRight: '5px' }}
                            />
                            <Input value={toCurrencySelling} disabled style={{ border: 'none', fontWeight: '600', fontSize: '1rem' }} />
                        </div>
                    </Col>
                </Row>

                {branchOptions.length === 0 ? (
                    <div className="mt-2 text-center">No stock available</div>
                ) : (
                    <>
                        <div className="mb-2">
                            <Label>Branch</Label>
                            {branchID === 0 ? (
                                <ReactSelect
                                    styles={SelectStyle}
                                    options={branchOptions}
                                    value={selectedBranch}
                                    onChange={(option) => {
                                        setSelectedBranch(option);
                                    }}
                                    placeholder="Select Branch"
                                />
                            ) : (
                                branchOptions.map((branch) => (
                                    <div
                                        key={branch.value}
                                        onClick={() => setSelectedBranch(branch)}
                                        style={{
                                            padding: '10px',
                                            border: selectedBranch?.value === branch.value ? '1px solid #000' : '1px solid #ced4da',
                                            cursor: 'pointer',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>
                                            <strong>{branch.label}</strong>
                                            <div style={{ fontSize: '0.9em', color: '#888' }}>{branch.address}</div>
                                        </div>
                                    </div>
                                ))
                            )}
                            {errors.branch && <div className="text-danger">{errors.branch}</div>}
                        </div>

                        <Row className="mb-2" >
                            <Col md={12} >
                                <Label>
                                    Pick Currency by{' '}
                                    {optime && optime.startTime && optime.endTime
                                        ? `${optime.startTime} - ${optime.endTime}`
                                        : <span style={{ fontSize: '0.8em', color: '#888' }}>No Branch Specific Time available</span>
                                    }
                                </Label>
                                <div style={{ border: '1px solid black', }}>


                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                            setStartDate(date)
                                            validateDate(date);
                                        }}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={30}
                                        dateFormat="yyyy/MM/dd - h:mm aa"
                                        timeCaption="Time"
                                        className="form-control"
                                        placeholderText={moment(currentDate).format("YYYY/MM/DD - HH:mm")}
                                    />
                                </div>


                                {errors.date && <div className="text-danger">{errors.date}</div>}
                            </Col>
                        </Row>

                        <div className="mb-2">
                            <Label>{fromCurrencySelling} Amount</Label>
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Amount"
                                onKeyDown={handleNumericInput}
                                value={fromCurrencyDisplay}
                                onChange={convertFromCurrency}
                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                            />
                            {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}

                        </div>


                        {parseFloat(sellCharge) !== 0 &&
                            sellCharge !== "" &&
                            <>

                                <Row className="mb-2 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Converted SGD</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>
                                            <FontAwesomeIcon icon={faEquals} className="me-1" />
                                            {fromCurrencyValueSelling
                                                ? (parseFloat(fromCurrencyValueSelling) * parseFloat(rate)).toFixed(2)
                                                : 0
                                            }
                                            {" "}
                                            SGD
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="mb-2 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Agent Charges</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>
                                            <FontAwesomeIcon icon={faMinus} className="me-1" />
                                            {sellCharge
                                                ? parseFloat(sellCharge).toFixed(2)
                                                : "0"
                                            }
                                            {" "}SGD

                                        </span>
                                    </Col>
                                </Row>

                                <Row className="mb-2 text-end">
                                    <Col lg={6} className="text-start">
                                        <span>Total SGD we calculate</span>
                                    </Col>
                                    <Col lg={6}>
                                        <span>
                                            <FontAwesomeIcon icon={faEquals} className="me-1" />
                                            {fromCurrencyValueSelling && rate && sellCharge
                                                ? (parseFloat(fromCurrencyValueSelling) * rate - parseFloat(sellCharge)).toFixed(2)
                                                : 0
                                            }
                                        </span>
                                    </Col>
                                </Row>

                                <hr />

                            </>

                        }

                        <Row className="mb-2 text-end">
                            <Col lg={6} className="text-start">
                                <span>Exchange Rate</span>
                            </Col>
                            <Col lg={6}>
                                <span>
                                    <FontAwesomeIcon icon={faX} className="me-1" />
                                    {parseFloat(rate).toFixed(4)} SGD
                                </span>
                            </Col>
                        </Row>

                        <div className="mb-2">
                            <Label>{toCurrencySelling} Amount</Label>
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Amount"
                                value={toCurrencyDisplay}
                                onKeyDown={handleNumericInput}
                                onChange={convertToCurrency}
                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                            />
                            {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                        </div>

                        <div className="text-end">
                            <button
                                type="submit"
                                disabled={
                                    isLoading ||
                                    Object.values(errors).some((error) => error !== "")
                                }
                                className="btn btn-primary"
                            >
                                {isLoading ? "Loading..." : "Submit"}
                            </button>
                        </div>

                    </>
                )}
                <ToastContainer limit={1} />
            </Form>

        </>
    );
};

export default SellForm;