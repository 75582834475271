import React, { useState } from "react";
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    Input,
    Label,
    Form,
    FormFeedback,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Redux
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// import images
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { postApiData } from "../../helpers/axiosHelper";

const ChangePassword = () => {

    document.title = "Crescent Exchange | ChangePassword";

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);


    const isChrome = (() => {
        const isChromium = !!window.chrome;
        const isGoogle = /Google Inc/.test(navigator.vendor);
        const isNotEdge = !/Edg/.test(navigator.userAgent);
        const isNotOpera = !/OPR/.test(navigator.userAgent);
        return isChromium && isGoogle && isNotEdge && isNotOpera;
    })();

    const validation = useFormik({

        enableReinitialize: true,

        initialValues: {
            oldPassword: "",
            newPassword: "",
            password_confirmation: ""
        },

        validationSchema: Yup.object({
            oldPassword: Yup.string()
                .min(8, 'Password must be 8 characters long')
                .required("Please Enter Your Current Password")
                .matches(/[^\w]/, 'Password requires a symbol')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter'),
            newPassword: Yup.string()
                .required("Please enter your new password")
                .min(8, 'Password must be 8 characters long')
                .matches(/[^\w]/, 'Password requires a symbol')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter')
                .notOneOf([Yup.ref('oldPassword')], 'New password must be different from the old password'),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                .required("Confirm password is required"),
        }),

        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true)
            const response = await postApiData(
                "api/ChangePassword/ChangeCustomerPassword",
                JSON.stringify(values)
            );
            if (response.success === true) {
                toast.success(
                    "Password Changed Successful.", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        sessionStorage.clear();
                        setIsLoading(false);
                        navigate(`/login`);
                    },
                });

            } else {
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
                resetForm();
            }
        },
    });

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container className="mt-5 pt-3" >
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">

                                <CardBody className="pt-4">

                                    <ToastContainer closeButton={false} limit={1} />
                                    <div className="p-2">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div id="changePage" style={{ display: "block" }} >
                                                <div className="mb-3">
                                                    <Label className="form-label">Current Password</Label>
                                                    <RequiredAsterisk />
                                                    <div className="input-group">
                                                        <Input
                                                            name="oldPassword"
                                                            type={isOldPasswordVisible ? "text" : "password"}
                                                            placeholder="Enter Current Password"
                                                            value={validation.values.oldPassword || ""}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.oldPassword &&
                                                                    validation.errors.oldPassword
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {isChrome && (
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                style={{ border: '1px solid #ced4da' }}
                                                                onClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}
                                                            >
                                                                {isOldPasswordVisible ? (
                                                                    <i className="bx bx-hide"></i>
                                                                ) : (
                                                                    <i className="bx bx-show"></i>
                                                                )}
                                                            </button>
                                                        )}
                                                        {validation.touched.oldPassword && validation.errors.oldPassword && (
                                                            <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="mb-3">
                                                    <Label className="form-label">New Password</Label>
                                                    <RequiredAsterisk />
                                                    <div className="input-group">
                                                        <Input
                                                            name="newPassword"
                                                            type={isNewPasswordVisible ? "text" : "password"}
                                                            placeholder="Enter New Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.newPassword || ""}
                                                            disabled={isLoading}
                                                            invalid={
                                                                validation.touched.newPassword &&
                                                                    validation.errors.newPassword
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {isChrome && (
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                style={{ border: '1px solid #ced4da' }}
                                                                onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
                                                            >
                                                                {isNewPasswordVisible ? (
                                                                    <i className="bx bx-hide"></i>
                                                                ) : (
                                                                    <i className="bx bx-show"></i>
                                                                )}
                                                            </button>
                                                        )}

                                                        {validation.touched.newPassword &&
                                                            validation.errors.newPassword ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.newPassword}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>


                                                <div className="mb-2">
                                                    <Label htmlFor="confirmPassword" className="form-label">
                                                        Confirm Password{" "}
                                                    </Label>
                                                    <span className="text-danger">*</span>
                                                    <div className="input-group">
                                                        <Input
                                                            name="password_confirmation"
                                                            type={isConfirmPasswordVisible ? "text" : "password"}
                                                            placeholder="Confirm Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password_confirmation || ""}
                                                            disabled={isLoading}
                                                            invalid={
                                                                validation.touched.password_confirmation &&
                                                                    validation.errors.password_confirmation
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {isChrome && (
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                style={{ border: '1px solid #ced4da' }}
                                                                onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                                            >
                                                                {isConfirmPasswordVisible ? (
                                                                    <i className="bx bx-hide"></i>
                                                                ) : (
                                                                    <i className="bx bx-show"></i>
                                                                )}
                                                            </button>
                                                        )}

                                                        {validation.touched.password_confirmation &&
                                                            validation.errors.password_confirmation ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{validation.errors.password_confirmation}</div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row-reverse mt-4">
                                                <button
                                                    className="btn btn-primary btn-block "
                                                    disabled={isLoading}
                                                    type="submit"
                                                >
                                                    {isLoading ? "Submitting..." : "Submit"}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ChangePassword);


