import React, { useState, useEffect, useContext, useMemo } from "react";
import {
    Row,
    Col,
    Card,
    Modal,
    Badge,
    Tooltip,
    CardBody,
    Dropdown,
    ModalBody,
    ModalHeader,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";
import BuyForm from "./Buy_Form";
import SellForm from "./Sell_Form";
import ReactSelect from "react-select";
import TransferForm from "./TransferForm";
import { ToastContainer } from "react-toastify";
import DashboardTransfer from "./DashboardTransfer";
import Loader from "../../components/Common/Loader";
import { getApiData } from "../../helpers/axiosHelper";
import { BaseCurrencyContext } from "../../common/data/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faGlobe, faDollarSign } from "@fortawesome/free-solid-svg-icons";

const HelpTooltip = ({ rateType }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const pdfMapping = {
        1: "https://sitapi.crescentexchange.com/help/customer/Sell Currency.pdf",
        2: "https://sitapi.crescentexchange.com/help/customer/BuyCurrency.pdf",
        3: "https://sitapi.crescentexchange.com/help/customer/Transfer Currency.pdf",
    };

    const handleHelpClick = () => {
        const pdfPath = pdfMapping[rateType];
        if (pdfPath) {
            window.open(pdfPath, "_blank", "noopener,noreferrer");
        }
    };

    return (
        <div style={{ position: "absolute", top: "15px", right: "40px", zIndex: 10 }}>
            <span id={`helpTooltip-${rateType}`} onClick={handleHelpClick}>
                <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color="#556ee6"
                    style={{ fontSize: '1.2em', cursor: "pointer" }}
                />
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target={`helpTooltip-${rateType}`}
                    toggle={toggleTooltip}
                >
                    {rateType === 1
                        ? "Help"
                        : rateType === 2
                            ? "Help"
                            : "Help"}
                </Tooltip>
            </span>
        </div>
    );
};

const CurrencyCard = ({ rate, onAddClick, currencyIcon, globalTransferData }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const [branchAnalysisData, setBranchAnalysisData] = useState([]);

    const rates = [
        { type: "Buy", value: rate.sellRate, rateType: 2 },
        { type: "Sell", value: rate.buyRate, rateType: 1 },
    ];

    const rateTypeMapping = {
        2: { color: 'success', text: 'Buy' },
        1: { color: 'danger', text: 'Sell' },
    };

    const transferData = globalTransferData?.find(item => item.currencyCode === rate.currencyCode);
    const transferRate = transferData ? parseFloat(transferData.rate) : null;
    const isTransferAvailable = transferRate !== null && transferRate > 0;

    useEffect(() => {
        const fetchBranchAnalysis = async () => {
            if (rate.globalIcon && rate.currencyCode !== "SGD") {
                try {
                    const response = await getApiData(`api/BranchMaster/GetBranchsAnalizes?currencyCode=${rate.currencyCode}`);
                    setBranchAnalysisData(response);
                } catch (error) {
                    console.error("Error fetching branch analysis data:", error);
                }
            }
        };

        fetchBranchAnalysis();
    }, [rate.globalIcon, rate.currencyCode]);

    const isBuyEnabled = branchAnalysisData.some(branch => !branch.activeRS && branch.isStock);
    const isSellEnabled = branchAnalysisData.some(branch => !branch.activeRS);

    return (
        <Col md={3}>
            <Card className="shadow-sm" style={{ borderRadius: '20px' }}>
                <CardBody style={{ padding: '20px' }}>
                    <h6 className="text-center">Branch - {rate.branchName}</h6>

                    <div className="text-center text-muted">
                        {rate.globalIcon && (
                            <FontAwesomeIcon
                                icon={faGlobe}
                                style={{ position: 'absolute', top: '10px', right: '10px', color: '#556ee6', fontSize: '1.2em' }}
                            />
                        )}
                        <img
                            src={currencyIcon}
                            alt="Currency"
                            style={{ width: '30px', marginRight: '5px' }}
                        />
                        <span style={{ fontWeight: 'bold' }}>{rate.currencyCode}</span>
                    </div>

                    <h6 className="text-center" style={{ fontWeight: '500', marginTop: '10px' }}>Value</h6>

                    <Row className="text-center">
                        {rates.map(({ value, rateType }) => {
                            const { color, text } = rateTypeMapping[rateType];
                            const formattedValue = value !== null && !isNaN(value)
                                ? (parseFloat(value) % 1 === 0
                                    ? parseInt(value, 10)
                                    : parseFloat(value).toFixed(4))
                                : "N/A";
                            return (
                                <Col key={rateType}>
                                    <>
                                        <Badge className={`font-size-11 badge-soft-${color}`}>{text}</Badge>
                                        <p className="mt-2">{formattedValue}</p>
                                    </>

                                </Col>

                            );
                        })}
                        <Col>
                            <Badge className="font-size-11 badge-soft-info">Transfer</Badge>
                            <p className="mt-2">{isTransferAvailable ? transferRate.toFixed(4) : "N/A"}</p>
                        </Col>

                    </Row>

                    <div className="d-flex justify-content-center">
                        <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown} className="d-flex justify-content-center mb-2">
                            <DropdownToggle color="primary">
                                <FontAwesomeIcon icon={faDollarSign} /> Book Now <span style={{ fontSize: '8px' }}>&#x25BC;</span>
                            </DropdownToggle>
                            <DropdownMenu style={{ textAlign: 'center' }}>
                                <DropdownItem
                                    onClick={() => onAddClick(rate, 2)}
                                    disabled={
                                        rate.globalIcon
                                            ? !isBuyEnabled
                                            : (!(parseFloat(rate.sellRate) > 0) || !rate.sellStock)
                                    }
                                >
                                    Buy
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => onAddClick(rate, 1)}
                                    disabled={rate.globalIcon ? !isSellEnabled : !(parseFloat(rate.buyRate) > 0)}
                                >
                                    Sell
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => onAddClick(rate, 3)}
                                    disabled={!isTransferAvailable}
                                >
                                    Transfer
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

const AddCurrency = () => {
    const { currencyData } = useContext(BaseCurrencyContext);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [rates, setRates] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRate, setSelectedRate] = useState(null);
    const [currencyIcons, setCurrencyIcons] = useState({});
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [ratesAvailable, setRatesAvailable] = useState(true);
    const [globalTransferData, setGlobalTransferData] = useState(null);
    const [isAllBranch, setIsAllBranch] = useState(null);

    const toggleModal = () => setModalOpen(!modalOpen);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const handleHelpClick = () => {
        window.open("https://sitapi.crescentexchange.com/help/customer/Dashboard.pdf");
    };

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const response = await getApiData("api/BranchMaster/GetBranches", {}, true);
                const branchOptions = response.data.map(branch => ({
                    value: branch.branchId,
                    label: branch.branchName,
                }));
                branchOptions.unshift({ value: "all", label: "All Branches" });
                setBranches(branchOptions);
                setSelectedBranch(branchOptions[0]);
                fetchAllBranchRS();
            } catch (error) {
                console.error("Error fetching branches", error);
            }
        };
        const fetchGlobalTransferRates = async () => {
            try {
                const response = await getApiData("api/RateMaster/GetGlobalTransferRate");

                const mappedTransferRates = response.data.map(rate => ({
                    rateHistoryId: rate.rateHistoryId,
                    currencyCode: rate.currencyCode,
                    currencyName: rate.currencyName,
                    rate: rate.value !== "" ? parseFloat(rate.value) : 0,
                    worldRate: rate.worldRate !== "" ? parseFloat(rate.worldRate) : 0,
                    transferRate: rate.transferRate !== "" ? parseFloat(rate.transferRate) : 0,
                    wireTransferFee: rate.wireTransferFee !== "" ? parseFloat(rate.wireTransferFee) : 0,
                    agentCharges: rate.agentCharges !== "" ? parseFloat(rate.agentCharges) : 0,
                    commissionCharges: rate.commissionCharges !== "" ? parseFloat(rate.commissionCharges) : 0,
                    totalFeesAdmin: rate.totalFeesAdmin !== "" ? parseFloat(rate.totalFeesAdmin) : 0,
                    buyRateMainBranch: rate.buyRateMainBranch !== "" ? parseFloat(rate.buyRateMainBranch) : 0,
                    buyRateHistoryIdMainBranch: rate.buyRateHistoryIdMainBranch ? parseFloat(rate.buyRateHistoryIdMainBranch) : 0,
                }));

                setGlobalTransferData(mappedTransferRates);
            } catch (error) {
                console.error("Error fetching global transfer rates", error);
            }
        };


        fetchBranches();
        fetchGlobalTransferRates();
    }, []);

    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                const response = await getApiData("api/Currency/GetAllCurrencies", {}, true);
                const icons = {};
                response.data.forEach(currency => {
                    icons[currency.currencyCode.trim()] = `data:image/png;base64,${currency.currencyFlag}`;
                });

                setCurrencyIcons(icons);
            } catch (error) {
                console.error("Error fetching currencies");
            }
        };
        fetchCurrencies();
    }, []);

    const fetchAllBranchRS = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData("api/RateMaster/GetAllActiveRatesByBranches", {}, true);
            if (response.data && response.data.length > 0) {
                processRatesResponseForAllBranches(response.data);
                setRatesAvailable(true);
                setIsAllBranch(1);

            } else {
                setRatesAvailable(false);
            }
        } catch (error) {
            console.error("Error fetching all branch rates", error);
            setIsLoading(false);
            setRatesAvailable(false);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchRSByBranch = async (branchId) => {
        try {
            setIsLoading(true);
            const response = await getApiData(`api/RateMaster/GetActiveRatesByBranch?BranchID=${branchId}`);
            if (response.data && response.data.length > 0) {
                processRatesResponseForSingleBranch(response.data);
                setRatesAvailable(true);
                setIsAllBranch(0);
            } else {
                setRatesAvailable(false);
            }
        } catch (error) {
            console.error("Error fetching branch rates", error);
            setIsLoading(false);
            setRatesAvailable(false);
        } finally {
            setIsLoading(false);
        }
    };

    // Process response from fetchAllBranchRS
    const processRatesResponseForAllBranches = (data) => {
        const groupedRates = data.reduce((acc, branch) => {
            const { branchId, branchName, rateHistories = [] } = branch;

            rateHistories.forEach((rate) => {
                const key = `${branchId}-${rate.currencyCode.trim()}`;
                acc[key] = acc[key] || {
                    currencyName: rate.currencyName || "",
                    currencyCode: rate.currencyCode.trim(),
                    branchId,
                    branchName: branchName === "All Branches" ? "Global" : branchName,
                    globalIcon: branchName === "All Branches",
                };
                acc[key][['buyRate', 'sellRate'][rate.rateType - 1]] = rate.value;
                acc[key][['buyStock', 'sellStock'][rate.rateType - 1]] = rate.stock;
                acc[key][['buyCharge', 'sellCharge'][rate.rateType - 1]] = rate.agentCharges;
                acc[key][['buyRateHistoryId', 'sellRateHistoryId'][rate.rateType - 1]] = rate.rateHistoryId;
            });
            return acc;
        }, {});

        const ratesList = Object.values(groupedRates);

        const uniqueCurrencies = Array.from(
            new Map(
                ratesList.filter(x => x.currencyCode !== "SGD").map(({ currencyCode, currencyName }) => [
                    currencyCode,
                    { value: currencyCode, label: `${currencyName} (${currencyCode})` },
                ])
            ).values()
        );

        setRates(ratesList);
        setCurrencyOptions(uniqueCurrencies);
    };

    // Process response from fetchRSByBranch
    const processRatesResponseForSingleBranch = (data) => {
        const groupedRates = data.reduce((acc, rate) => {
            const { branchId, branchName, currencyName, currencyCode } = rate;
            const key = `${branchId}-${currencyCode.trim()}`;
            acc[key] = acc[key] || {
                currencyName: currencyName || "",
                currencyCode: currencyCode.trim(),
                branchId,
                branchName: branchName === "All Branches" ? "Global" : branchName,
                globalIcon: branchName === "All Branches",
            };

            acc[key][['buyRate', 'sellRate'][rate.rateType - 1]] = rate.value;
            acc[key][['buyStock', 'sellStock'][rate.rateType - 1]] = rate.stock;
            acc[key][['buyCharge', 'sellCharge'][rate.rateType - 1]] = rate.agentCharges;
            acc[key][['buyRateHistoryId', 'sellRateHistoryId'][rate.rateType - 1]] = rate.rateHistoryId;
            return acc;
        }, {});

        const ratesList = Object.values(groupedRates);

        const uniqueCurrencies = Array.from(
            new Map(
                ratesList.filter(x => x.currencyCode !== "SGD").map(({ currencyCode, currencyName }) => [
                    currencyCode,
                    { value: currencyCode, label: `${currencyName} (${currencyCode})` },
                ])
            ).values()
        );

        setRates(ratesList);
        setCurrencyOptions(uniqueCurrencies);
    };

    const handleBranchChange = (selectedBranch) => {
        setSelectedBranch(selectedBranch);
        if (selectedBranch.value === "all") fetchAllBranchRS();
        else fetchRSByBranch(selectedBranch.value);
    };

    const handleCurrencyChange = (selectedCurrency) => setSelectedCurrency(selectedCurrency);

    const handleAddClick = (rate, rateType) => {
        setSelectedRate({ ...rate, rateType });
        setModalOpen(true);
    };

    const renderForm = () => {
        if (!selectedRate) return null;

        const { branchId, currencyCode, rateType } = selectedRate;

        switch (rateType) {
            case 2:
                return (
                    <BuyForm
                        branchID={branchId}
                        rateType={rateType}
                        closeModal={toggleModal}
                        isAllBranch={isAllBranch}
                        rate={parseFloat(selectedRate.sellRate)}
                        buyingCurrency={currencyCode}
                        stock={selectedRate.buyStock}
                        buyCharge={selectedRate.sellCharge}
                        rateHisID={selectedRate.sellRateHistoryId}
                    />
                );
            case 1:
                return (
                    <SellForm
                        branchID={branchId}
                        rateType={rateType}
                        closeModal={toggleModal}
                        isAllBranch={isAllBranch}
                        rate={selectedRate.buyRate}
                        sellingCurrency={currencyCode}
                        sellCharge={selectedRate.buyCharge}
                        rateHisID={selectedRate.buyRateHistoryId}
                    />
                );
            case 3:
                return (
                    <TransferForm
                        branchID={branchId}
                        closeModal={toggleModal}
                        transferCurrency={currencyCode}
                        baseCurrency={currencyData?.value}
                        globalTransferData={globalTransferData}
                    />
                );
            default:
                return null;
        }
    };

    const filteredRates = useMemo(() =>
        selectedCurrency
            ?
            rates.filter((rate) => rate.currencyCode === selectedCurrency.value)
            :
            rates
        , [selectedCurrency, rates]);


    return (
        <>
            <ToastContainer />

            {currencyData?.value === "SGD"
                ?
                <Row className="justify-content-end">
                    <Col md="auto">
                        <small className="text-end text-primary mb-1">All Currency conversion based on SGD</small>
                    </Col>
                </Row>
                : null}
            <Card className="shadow-sm" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CardBody style={{ width: '100%' }}>
                    {currencyData?.value === "SGD" &&
                        <div className="mb-2" style={{ display: 'flex', justifyContent: 'flex-end' }}  >
                            <span id="helpTooltip" onClick={handleHelpClick}>
                                <FontAwesomeIcon icon={faQuestionCircle} color="#556ee6" style={{ fontSize: '1.2em', cursor: 'pointer' }} />
                            </span>
                            <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                target="helpTooltip"
                                toggle={toggleTooltip}
                            >
                                Help
                            </Tooltip>
                        </div>
                    }
                    <Row className="justify-content-between">
                        <Col md={3}>
                            {currencyData?.value === "SGD" &&
                                <ReactSelect
                                    options={branches}
                                    isDisabled={isLoading}
                                    value={selectedBranch}
                                    onChange={handleBranchChange}
                                />
                            }
                        </Col>
                        <Col md={4}>
                            <h2 className="text-center text-primary">Book Transaction</h2>
                        </Col>
                        <Col md={3}>
                            {currencyData?.value === "SGD" &&
                                <ReactSelect
                                    isDisabled={isLoading}
                                    value={selectedCurrency}
                                    options={currencyOptions}
                                    onChange={handleCurrencyChange}
                                    placeholder="Choose Currency"
                                    isClearable
                                    formatOptionLabel={({ value, label }) => (
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={currencyIcons[value]}
                                                alt={value}
                                                style={{ width: 20, height: 20, marginRight: 8 }}
                                            />
                                            <span>{label}</span>
                                        </div>
                                    )}
                                />
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Row style={{ overflowY: "auto", overflowX: "hidden", padding: "10px" }}>
                {isLoading ? (
                    <Loader />
                ) : currencyData?.value === "SGD" ? (
                    !ratesAvailable ? (
                        <h5 className="text-center">No rate available to initiate transaction</h5>
                    ) : (
                        filteredRates
                            .filter((x) => x.currencyCode !== "SGD")
                            .map((rate, idx) => (
                                <CurrencyCard
                                    key={idx}
                                    rate={rate}
                                    onAddClick={handleAddClick}
                                    currencyIcon={currencyIcons[rate.currencyCode]}
                                    baseCurrency={currencyData?.value}
                                    globalTransferData={globalTransferData}
                                />
                            ))
                    )
                ) : (
                    <DashboardTransfer
                        branch={filteredRates[0]?.branchId}
                        transferCurrency={filteredRates[0]?.currencyCode}
                        rate={filteredRates[0]?.baseTransferRate}
                        closeModal={toggleModal}
                        baseCurrency={currencyData?.value}
                        worldRate={filteredRates[0]?.worldRate}
                        transferRate={filteredRates[0]?.transferRate}
                        wireTransferFee={filteredRates[0]?.wireTransferFee}
                        commissionCharges={filteredRates[0]?.commissionCharges}
                    />
                )}
            </Row>


            {modalOpen && (
                <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
                    <ModalHeader toggle={toggleModal}>
                        {selectedRate?.rateType === 2 ? "Buy Currency" : selectedRate?.rateType === 1 ? "Sell Currency" : "Transfer Currency"}
                        <HelpTooltip rateType={selectedRate?.rateType} />
                    </ModalHeader>
                    <ModalBody>
                        {renderForm()}
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default AddCurrency;
