import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Modal,
    Label,
    Table,
    Badge,
    Button,
    Collapse,
    ModalBody,
    Col, Row, FormFeedback, Input, Card, CardBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify";
import { postApiData, getApiData, putApiData } from "../../helpers/axiosHelper";
import { mapStatus } from "../../common/data/StatusLabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateToUserTimeZone } from "../../common/data/FormateDateTime";
import { faMinus, faEquals, faX, faDivide, faEye, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import Loader from "../../components/Common/Loader";
import * as Yup from "yup";
import { useFormik } from "formik";

const ViewTransaction = (props) => {

    const { isOpen, toggle, optime, branchData, commentData, sendTransactionDetails, showAction, chargesData, transactionType, buyRate, transferRate, status, transactionID, documentData, loading } = props;

    console.log({ isOpen, toggle, optime, branchData, commentData, sendTransactionDetails, showAction, chargesData, transactionType, status, transactionID, documentData, loading });

    const [col_1, setCol_1] = useState(false);
    const [col_2, setCol_2] = useState(false);
    const [col_3, setCol_3] = useState(false);
    const [col_4, setCol_4] = useState(false);
    const [col_5, setCol_5] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [commentText, setCommentText] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [isCommentOpen, setIsCommentOpen] = useState(false);

    // Support Document
    const [docData, setDocData] = useState([]);
    const [modal1, setModal1] = useState(false);
    const [docFormat, setDocFormat] = useState("");
    const [fileData, setFileData] = useState(null);
    const [docIsValid, setDocIsValid] = useState(true);
    const [viewDocName, setViewDocName] = useState("");
    const [viewDocument, setViewDocument] = useState("");
    const [isAddingDoc, setIsAddingDoc] = useState(false);

    // Assined Values
    const agentFee = parseFloat(chargesData?.agentCharges);
    const wireFee = parseFloat(chargesData?.wireTransferFee);
    const commissionFee = parseFloat(chargesData?.commissionCharges);
    const exchangeBuyRate = parseFloat(buyRate);
    const exchangeRate = parseFloat(sendTransactionDetails?.exchangeRate);
    const fromCurrencyValue = parseFloat(sendTransactionDetails?.fromCurrValue);
    const toCurrencyValue = parseFloat(sendTransactionDetails?.toCurrValue);


    useEffect(() => {
        if (sendTransactionDetails?.status === 3) {
            setCol_1(false);
            setCol_2(false);
            setCol_3(false);
            setCol_4(true);
        } else {
            setCol_1(true);
            setCol_2(false);
            setCol_3(false);
            setCol_4(false);
        }
    }, []);

    const UserTypes = {
        1: "System User",
        2: "Customer",
        3: "Agent",
    };

    const toggleViewModal = () => setModal1(!modal1);

    const openCommentPopup = (status) => {
        setSelectedStatus(status);
        setIsCommentOpen(true);
    };

    const closeCommentPopup = () => {
        setIsCommentOpen(false);
        setCommentText("");
    };

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const getFileType = (contentType) => {
        switch (contentType) {
            case "png":
                return "image/png";
            case "jpg":
                return "image/jpg";
            case "jpeg":
                return "image/jpeg";
            case "pdf":
                return "application/pdf";
            case "docx":
                return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            default:
                return "application/octet-stream";
        }
    };

    const viewDoc = (index) => {
        const data = docData[index].document;
        const name = docData[index].documentName;
        const docFormat = docData[index].contentType;

        setViewDocument(data);
        setViewDocName(name);

        const imageTypes = ["png", "jpg", "jpeg"];
        const isPDF = docFormat === "pdf";


        if (imageTypes.includes(docFormat) || isPDF) {
            handleDownloadFile(index);
        } else {
            handleDownloadFile(index);
        }
    };


    const handleDownloadFile = (index) => {
        const data = docData[index];
        const byteChars = atob(data.document);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);


        const blob = new Blob([byteArray], { type: getFileType(data.contentType) });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${data.documentName}.${data.contentType || "pdf"}`;
        link.click();
        URL.revokeObjectURL(url);
    };

    const deleteDoc = async (index) => {
        const docId = docData[index].docID;
        const response = await putApiData(
            `api/TransactionDocs/SoftDeleteDoc?DocID=${docId}&Active=false`
        );
        if (response.success === true) {
            toast.success("Document Deleted Successfully", {
                position: "top-right",
                autoClose: 1500,
            });
            getDocument(sendTransactionDetails?.transactionId);
        } else {
            toast.error("Error Deleting Document. Contact Admin", {
                position: "top-right",
                autoClose: 1500,
            });
        }
    };

    const getDocument = async (transID) => {
        const docResponse = await getApiData(
            `api/TransactionDocs/GetByTransactionId?TransactionId=${transID}`
        ).catch(() => { });

        const document = await docResponse.data.filter((x) => x.active === true);
        // const documents = (document && document.data) || [];
        setDocData(document);
    };

    function handleFileChange(e) {
        setDocIsValid(true);
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 5000 * 1024) {
            toast.error("File Size Should be less than 5MB", {
                position: "top-right",
                autoClose: 2000,
            });
            documentValidation.resetForm();
            tog_standard();
            setDocIsValid(false);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const type = reader.result.split(";")[0];
            const docType = type.split("/")[1]; // Get the document type (e.g., pdf, png)

            let base64String = "";
            const indexOfComma = reader.result.indexOf(",");
            if (indexOfComma !== -1) {
                base64String = reader.result.substring(indexOfComma + 1);
            }
            setDocFormat(docType); // Ensure the docFormat is set correctly (e.g., "pdf" or "png")
            setFileData(base64String);
        };

        reader.readAsDataURL(file);
    }

    const documentValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            documentName: "",
            document: null,
            contentType: null,
        },

        validationSchema: Yup.object({
            documentName: Yup.string().required("Please Enter the Document Name"),
            document: Yup.string().required("Please Upload the Document"),
        }),

        onSubmit: async (values, { resetForm }) => {
            setIsAddingDoc(true);

            const transID = sendTransactionDetails?.transactionId;

            if (docIsValid) {
                const combinedValues = {
                    ...values,
                    transactionID: transID,
                    document: fileData,
                    contentType: docFormat,
                };
                const response = await postApiData(
                    `api/TransactionDocs/AddDoc`,
                    combinedValues
                );
                if (response.success === true) {
                    toast.success("Document Added Successfully", {
                        position: "top-right",
                        autoClose: 1500,
                    });
                    resetForm();
                    tog_standard();
                    getDocument(transID);
                    setIsAddingDoc(false);
                } else {
                    toast.error("Error Uploading Doc. Contact Admin", {
                        position: "top-right",
                        autoClose: 1500,
                    });
                    setIsAddingDoc(false);
                }
            } else {
                toast.warning("File Size Should be less than 5MB", {
                    position: "top-right",
                    autoClose: 1500,
                });
                setIsAddingDoc(false);
            }
            resetForm();
            tog_standard();
        },
    });

    useEffect(() => {
        if (documentData !== null) {
            setDocData(documentData);
        }
    }, [documentData]);

    useEffect(() => {
        if (loading) {
            setIsLoading(true);
        }
        setIsLoading(false)
    }, [loading]);


    const handleCommentSubmit = async () => {
        const trimmedComment = commentText.trim();

        if (trimmedComment === "") {
            toast.error("Comment cannot be empty or just spaces.", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }

        const response = await postApiData("api/Transaction/AcknowledgeOrReject", {
            transactionId: sendTransactionDetails.transactionId,
            comment: trimmedComment,
            status: selectedStatus,
        });

        if (response.success === true) {
            toast.success("Comment Added successfully.", {
                position: "top-right",
                autoClose: 1500,
            });
            toggle();
            closeCommentPopup();
        } else {
            toast.error("Something went wrong, Cannot comment.", {
                position: "top-right",
                autoClose: 1500,
            });
            toggle();
            closeCommentPopup();
        }
    };

    const calculateDateDifference = () => {
        const approvedDate = new Date(sendTransactionDetails.approvedDate);
        const currentDate = new Date();

        const approvedDateOnly = approvedDate.toISOString().split("T")[0];
        const currentDateOnly = currentDate.toISOString().split("T")[0];

        const diffInDays = Math.floor((currentDate - approvedDate) / (1000 * 60 * 60 * 24));

        return approvedDateOnly === currentDateOnly ? 0 : diffInDays;
    };

    const beneficiary = sendTransactionDetails?.beneficiaryDetails?.[0];

    return (
        <>
            <ToastContainer limit={1} />
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                centered={true}
                autoClose={true}
            >

                <ModalHeader toggle={toggle}>
                    {sendTransactionDetails?.transactionType === 2
                        ? "(Buy)"
                        : sendTransactionDetails?.transactionType === 1
                            ? "(Sell)"
                            : sendTransactionDetails?.transactionType === 3
                                ? "(Transfer)"
                                : ""}{" "}
                    Transaction ID - <span style={{ color: '#556EE6' }}>{sendTransactionDetails?.transactionId}</span>
                </ModalHeader>

                {isLoading
                    ?
                    <Loader />
                    :
                    <ModalBody style={{ maxHeight: "650px" }}>
                        <div className="mb-2">
                            <Button
                                className="w-100 bg-white border-0 shadow-none text-dark fs-3 text-start d-flex justify-content-between align-items-center"
                                onClick={() => {
                                    setCol_1(!col_1);
                                    setCol_2(false);
                                    setCol_3(false);
                                    setCol_4(false);
                                    setCol_5(false);

                                }}
                            >
                                <span>Currency</span>
                                <i className="fa fa-chevron-down" />
                            </Button>

                            <Collapse isOpen={col_1}>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Table className="table-bordered table-nowrap border border-2 mt-2">
                                        <tbody>
                                            {sendTransactionDetails && (
                                                <>
                                                    <tr>
                                                        <th scope="row">From currency :</th>
                                                        <td>{fromCurrencyValue} {sendTransactionDetails?.fromCurrType}</td>
                                                    </tr>

                                                    {transactionType === 1 && agentFee > 0 && (
                                                        <tr>
                                                            <th scope="row">Converted SGD :</th>
                                                            <td>

                                                                {(fromCurrencyValue * exchangeRate).toFixed(2)} SGD
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {agentFee > 0 && (
                                                        <tr>
                                                            <th scope="row">Agent Charges :</th>
                                                            <td>
                                                                <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                                {transactionType === 1
                                                                    ? agentFee + " SGD"
                                                                    : transactionType === 2
                                                                        ? agentFee + " SGD"
                                                                        : transactionType === 3
                                                                            ? sendTransactionDetails?.fromCurrType === "SGD"
                                                                                ? agentFee + " SGD"
                                                                                : sendTransactionDetails?.toCurrType === "SGD"
                                                                                    ? (agentFee / exchangeRate).toFixed(3) + ` ${sendTransactionDetails?.fromCurrType}`
                                                                                    : sendTransactionDetails?.fromCurrType !== "SGD" && sendTransactionDetails?.toCurrType !== "SGD"
                                                                                        ? (agentFee / exchangeBuyRate).toFixed(3) + ` ${sendTransactionDetails?.fromCurrType}`
                                                                                        : 0
                                                                            : 0
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {commissionFee > 0 && (
                                                        <tr>
                                                            <th scope="row">Commission Charges :</th>
                                                            <td>
                                                                <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                                {transactionType === 1
                                                                    ? commissionFee + " SGD"
                                                                    : transactionType === 2
                                                                        ? commissionFee + " SGD"
                                                                        : transactionType === 3
                                                                            ? sendTransactionDetails?.fromCurrType === "SGD"
                                                                                ? commissionFee + " SGD"
                                                                                : sendTransactionDetails?.toCurrType === "SGD"
                                                                                    ? (commissionFee / exchangeRate).toFixed(3) + ` ${sendTransactionDetails?.fromCurrType}`
                                                                                    : sendTransactionDetails?.fromCurrType !== "SGD" && sendTransactionDetails?.toCurrType !== "SGD"
                                                                                        ? (commissionFee / exchangeBuyRate).toFixed(3) + ` ${sendTransactionDetails?.fromCurrType}`
                                                                                        : 0
                                                                            : 0
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {wireFee > 0 && (
                                                        <tr>
                                                            <th scope="row">Wire Transfer Fee :</th>
                                                            <td>
                                                                <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                                {transactionType === 1
                                                                    ? wireFee + " SGD"
                                                                    : transactionType === 2
                                                                        ? wireFee + " SGD"
                                                                        : transactionType === 3
                                                                            ? sendTransactionDetails?.fromCurrType === "SGD"
                                                                                ? wireFee + " SGD"
                                                                                : sendTransactionDetails?.toCurrType === "SGD"
                                                                                    ? (wireFee / exchangeRate).toFixed(3) + ` ${sendTransactionDetails?.fromCurrType}`
                                                                                    : sendTransactionDetails?.fromCurrType !== "SGD" && sendTransactionDetails?.toCurrType !== "SGD"
                                                                                        ? (wireFee / exchangeBuyRate).toFixed(3) + ` ${sendTransactionDetails?.fromCurrType}`
                                                                                        : 0
                                                                            : 0
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {(agentFee > 0 || wireFee > 0 || commissionFee > 0) && transactionType !== 1 && (
                                                        <tr>
                                                            <th scope="row">Total from currency :</th>
                                                            <td style={{ color: "#556ee6", fontWeight: "bold" }}>
                                                                <FontAwesomeIcon
                                                                    icon={faEquals}
                                                                    className="me-1"
                                                                    style={{ color: "#556ee6" }}
                                                                />
                                                                {transactionType === 2
                                                                    ? (fromCurrencyValue - agentFee) + " SGD"
                                                                    : transactionType === 3
                                                                        ? sendTransactionDetails?.fromCurrType === "SGD"
                                                                            ? (fromCurrencyValue - (wireFee + commissionFee + agentFee)) + " SGD"
                                                                            : sendTransactionDetails?.toCurrType === "SGD"
                                                                                ? (fromCurrencyValue - ((wireFee / exchangeRate) + (commissionFee / exchangeRate) + (agentFee / exchangeRate))).toFixed(2) + ` ${sendTransactionDetails?.fromCurrType}`
                                                                                : sendTransactionDetails?.fromCurrType !== "SGD" && sendTransactionDetails?.toCurrType !== "SGD"
                                                                                    ? (fromCurrencyValue - ((wireFee / exchangeBuyRate) + (commissionFee / exchangeBuyRate) + (agentFee / exchangeBuyRate))).toFixed(3) + ` ${sendTransactionDetails?.fromCurrType}`
                                                                                    : 0
                                                                        : 0
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}

                                                    <tr>
                                                        <th scope="row">
                                                            Exchange rate :
                                                        </th>
                                                        <td>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    transactionType === 1
                                                                        ? faX
                                                                        : transactionType === 2
                                                                            ? faDivide
                                                                            : transactionType === 3
                                                                                ? sendTransactionDetails?.fromCurrType === "SGD"
                                                                                    ? faDivide
                                                                                    : sendTransactionDetails?.toCurrType === "SGD"
                                                                                        ? faX
                                                                                        : sendTransactionDetails?.fromCurrType !== "SGD" && sendTransactionDetails?.toCurrType !== "SGD"
                                                                                            ? faDivide
                                                                                            : null

                                                                                : null
                                                                }
                                                                className="me-1"
                                                            />
                                                            {exchangeRate}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">To currency :</th>
                                                        <td>
                                                            {toCurrencyValue} {sendTransactionDetails?.toCurrType}
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </tbody>


                                    </Table>
                                </div>
                            </Collapse>

                        </div>

                        <div className="mb-2">
                            <Button
                                className="w-100 bg-white border-0 shadow-none  text-dark fs-3 text-start d-flex justify-content-between align-items-center"
                                onClick={() => {
                                    setCol_1(false);
                                    setCol_2(!col_2)
                                    setCol_3(false);
                                    setCol_4(false);
                                    setCol_5(false);


                                }}
                            >
                                Branch  <i className="fa fa-chevron-down " />
                            </Button>

                            <Collapse
                                isOpen={col_2}
                            >
                                <div style={{ maxHeight: '225px', overflowY: 'auto' }}>
                                    <Table
                                        className="table-bordered table-nowrap border border-2 mt-2"
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <tbody>
                                            {branchData && branchData !== null && (
                                                <tr>
                                                    <th>Branch :</th>
                                                    <td>{branchData.branchName}</td>
                                                </tr>
                                            )}
                                        </tbody>

                                        <tbody>
                                            {sendTransactionDetails && (
                                                <tr>
                                                    <th scope="row">Booked Date :</th>
                                                    <td>{formatDateToUserTimeZone(sendTransactionDetails.transactionDate)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Label>Working Hours:</Label>

                                    <Table
                                        className="table-bordered table-nowrap border border-2"
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>Day</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {optime && optime.length > 0 ? (
                                                optime.map((operationalTime, index) => (
                                                    <tr key={index}>
                                                        <td>{operationalTime.day}</td>
                                                        <td>{operationalTime.startTime}</td>
                                                        <td>{operationalTime.endTime}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="3" className="text-center">No operational time available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Label>Branch Address:</Label>

                                    <Table
                                        className="table-bordered table-nowrap border border-2"
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <tbody >
                                            {branchData && branchData !== null ? (
                                                <>
                                                    <tr>
                                                        <th>Street :</th>
                                                        <td>{branchData.street}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>City :</th>
                                                        <td>{branchData.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>State :</th>
                                                        <td>{branchData.state}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Country :</th>
                                                        <td>{branchData.country}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Postal Code :</th>
                                                        <td>{branchData.postalCode}</td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <tr>
                                                    <th className="text-center">No Address Added.</th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Collapse>

                        </div>

                        {transactionType === 3 && (
                            <>
                                <div className="mb-2">

                                    <Button
                                        className="w-100 bg-white border-0 shadow-none text-dark fs-3 text-start d-flex justify-content-between align-items-center mt-2"
                                        onClick={() => {
                                            setCol_1(false);
                                            setCol_2(false);
                                            setCol_3(!col_3);
                                            setCol_4(false);
                                            setCol_5(false);
                                        }}
                                    >
                                        <span>Beneficiary</span>
                                        <i className="fa fa-chevron-down" />
                                    </Button>

                                    <Collapse isOpen={col_3}>
                                        <Table
                                            className="table-bordered table-nowrap border border-2"
                                            style={{ maxWidth: '200px' }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Name:</th>
                                                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{beneficiary?.beneficiaryName || "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Account Number:</th>
                                                    <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{beneficiary?.accountNumber || "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: "8px", }}>Currency Name:</th>
                                                    <td style={{ padding: "8px" }}>{beneficiary?.currencyName || "N/A"}</td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Collapse>

                                </div>
                            </>
                        )}

                        <div className="mb-2">
                            <Button
                                className="w-100 bg-white border-0 shadow-none text-dark fs-3 text-start d-flex justify-content-between align-items-center"
                                onClick={() => {
                                    setCol_4(false);
                                    setCol_2(false);
                                    setCol_1(false);
                                    setCol_3(false);
                                    setCol_5(!col_5);
                                }}
                            >
                                Supporting Docs <i className="fa fa-chevron-down" />
                            </Button>

                            <Collapse isOpen={col_5}>
                                <div
                                    className="p-3 border rounded bg-light"
                                    style={{
                                        maxHeight: "225px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {/* Scrollable Table Container */}
                                    <div
                                        className="table-responsive mb-2"
                                        style={{ maxHeight: "200px", overflowY: "auto" }}
                                    >
                                        {docData?.length > 0 ? (
                                            <Table className="table table-hover table-bordered">
                                                <thead className="table-primary">
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Document Name</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {docData.map((arr, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{arr.documentName}</td>
                                                            <td>
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    className="btn-sm me-2"
                                                                    onClick={() => viewDoc(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </Button>
                                                                <Button
                                                                    type="button"
                                                                    color="danger"
                                                                    className="btn-sm"
                                                                    onClick={() => deleteDoc(index)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        ) : (
                                            <div
                                                className="text-muted text-center py-4"
                                                style={{ minHeight: "100px" }}
                                            >
                                                <h4>No documents available</h4>
                                            </div>
                                        )}
                                    </div>

                                    {/* Always Visible Add Document Button */}
                                    {showAction === true &&
                                        status < 4 &&
                                        docData.length < 5 && (
                                            <div className="text-start">
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="btn-primary"
                                                    onClick={tog_standard}
                                                >
                                                    Add Document
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            </Collapse>
                        </div>

                        {/* View Document Modal */}
                        <Modal
                            size="lg"
                            isOpen={modal1}
                            toggle={toggleViewModal}
                            className="modal-dialog-centered"
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="myModalLabel">
                                    {viewDocName}
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => setModal1(false)}
                                    className="btn-close"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody className="p-0">
                                            <img
                                                src={`data:image/png;base64,${viewDocument}`}
                                                alt="Document"
                                                className="w-100"
                                                style={{ maxHeight: "50vh" }}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <ModalFooter>
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-sm"
                                    onClick={() =>
                                        handleDownloadFile(
                                            docData?.findIndex(
                                                (doc) => doc.documentName === viewDocName
                                            )
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faDownload} /> Download
                                </Button>
                            </ModalFooter>
                        </Modal>

                        {/* Manage Documents Modal */}
                        <Modal
                            isOpen={modal_standard}
                            toggle={() => {
                                tog_standard();
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Manage Documents
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        documentValidation.resetForm();
                                        setmodal_standard(false);
                                        return false;
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Row className="mb-4">
                                                <Col sm={6}>
                                                    <div className="mt-3">
                                                        <Label for="documentName">Document Name</Label>
                                                        <div className="col-md-12">
                                                            <Input
                                                                type="text"
                                                                placeholder="Document Name"
                                                                id="documentName"
                                                                name="documentName"
                                                                onChange={documentValidation.handleChange}
                                                                onBlur={documentValidation.handleBlur}
                                                                value={documentValidation.values.documentName}
                                                                invalid={
                                                                    documentValidation.touched.documentName &&
                                                                        documentValidation.errors.documentName
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {documentValidation.touched.documentName &&
                                                                documentValidation.errors.documentName ? (
                                                                <FormFeedback type="invalid">
                                                                    {documentValidation.errors.documentName}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="mt-1">
                                                        <Label for="document">Select Document</Label>
                                                        <div className="col-md-12">
                                                            <Input
                                                                type="file"
                                                                id="document"
                                                                aria-label="Upload"
                                                                accept=".png, .jpg, .jpeg, .pdf"
                                                                aria-describedby="inputGroupFileAddon04"
                                                                onChange={(e) => {
                                                                    handleFileChange(e);
                                                                    documentValidation.handleChange(e);
                                                                }}
                                                                onBlur={documentValidation.handleBlur}
                                                                invalid={
                                                                    documentValidation.touched.document &&
                                                                        documentValidation.errors.document
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {documentValidation.touched.document &&
                                                                documentValidation.errors.document ? (
                                                                <FormFeedback type="invalid">
                                                                    {documentValidation.errors.document}
                                                                </FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="mt-0 mb-4 me-3 text-end">
                                <Button
                                    className="btn btn-primary"
                                    color="primary"
                                    disabled={isAddingDoc}
                                    onClick={documentValidation.handleSubmit}
                                >
                                    {isAddingDoc ? "Submiting Document..." : "Submit"}
                                </Button>
                            </div>
                        </Modal>

                        <div className="mb-2">
                            <Button
                                className="w-100 bg-white border-0 shadow-none text-dark fs-3 text-start d-flex justify-content-between align-items-center"
                                onClick={() => {
                                    setCol_1(false);
                                    setCol_2(false);
                                    setCol_3(false);
                                    setCol_4(!col_4);
                                }}
                            >
                                Comment <i className="fa fa-chevron-down" />
                            </Button>

                            <Collapse
                                isOpen={col_4}

                            >
                                <div>
                                    <div>
                                        <div className="mt-1" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            {commentData?.length > 0 ? (
                                                commentData.map((comment, index) => (
                                                    <div key={index}>
                                                        <hr />
                                                        <span>
                                                            <h6>
                                                                {UserTypes[comment.userType]}
                                                            </h6>
                                                            <p>
                                                                Status:{"  "}
                                                                <Badge
                                                                    className={
                                                                        "font-size-11 badge-soft-" + mapStatus(comment.transactionStatus).color
                                                                    }
                                                                >
                                                                    {mapStatus(comment.transactionStatus).label}
                                                                </Badge>
                                                            </p>
                                                        </span>
                                                        <span>
                                                            <h6 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                Comment:
                                                                <b className="ms-2">{comment.comments}</b>
                                                                <small className="text-muted me-2" style={{ marginLeft: 'auto' }}>
                                                                    {comment.createdOn.split("T")}
                                                                </small>
                                                            </h6>
                                                        </span>
                                                        <hr />
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                                    <h3>No comments to show</h3>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    {showAction === true && sendTransactionDetails?.status !== 13 && (
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn-primary text-start mt-2"
                                            onClick={() => openCommentPopup(sendTransactionDetails.status)} >
                                            Comment
                                        </Button>
                                    )}
                                </div>

                            </Collapse>

                        </div>

                    </ModalBody>
                }

                {showAction === true && (

                    <ModalFooter >

                        {sendTransactionDetails != null &&
                            sendTransactionDetails.status === 2

                            && (
                                <>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn-primary"
                                        onClick={() => openCommentPopup(8)}>
                                        Acknowledge
                                    </Button>
                                    <Button
                                        type="button"
                                        color="warning"
                                        className="btn-primary"
                                        onClick={() => openCommentPopup(3)}>
                                        Review
                                    </Button>
                                </>
                            )}

                        {sendTransactionDetails && sendTransactionDetails.status === 4 && (
                            <Button
                                type="button"
                                color="danger"
                                className="btn-primary"
                                disabled={calculateDateDifference() === 0 ? false : true}
                                onClick={() => openCommentPopup(9)}
                            >
                                Decline
                            </Button>
                        )}

                        {sendTransactionDetails != null && (
                            sendTransactionDetails.status === 1 ||
                            sendTransactionDetails.status === 2 ||
                            sendTransactionDetails.status === 3 ||
                            sendTransactionDetails.status === 8

                        ) && (
                                <Button
                                    type="button"
                                    color="danger"
                                    className="btn-primary"
                                    onClick={() => openCommentPopup(6)}>
                                    Decline
                                </Button>
                            )}

                        {sendTransactionDetails != null &&
                            sendTransactionDetails.status === 10
                            && (
                                <Button
                                    type="button"
                                    color="danger"
                                    className="btn-primary"
                                    onClick={() => openCommentPopup(9)}>
                                    Decline
                                </Button>

                            )}

                    </ModalFooter>

                )}

            </Modal>

            {/*Comment Modal*/}
            <Modal isOpen={isCommentOpen} toggle={closeCommentPopup}>
                <ModalHeader toggle={closeCommentPopup}>Add Comment</ModalHeader>
                <ModalBody>
                    <textarea
                        className="form-control"
                        rows="5"
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                        placeholder="Write your comment here..."
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={!commentText} onClick={handleCommentSubmit}>
                        Submit
                    </Button>
                    <Button color="secondary" onClick={closeCommentPopup}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    );
};

ViewTransaction.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    commentData: PropTypes.array,
    sendTransactionDetails: PropTypes.object,
}

export default ViewTransaction