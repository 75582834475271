import React, { useState, useEffect, useContext } from "react";
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import Notifications from "./Notifications";
import { withTranslation } from "react-i18next";
import withRouter from "../../Common/withRouter";
import { ToastContainer, toast } from "react-toastify";
import { getApiData } from "../../../helpers/axiosHelper";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseCurrencyContext, ProfileContext, ImageContext } from "../../../common/data/context";

const ProfileMenu = (props) => {

    const [menu, setMenu] = useState(false);
    const { profileData } = useContext(ProfileContext);
    const { imageData } = useContext(ImageContext);
    const [profileImage, setProfileImage] = useState(null);
    const [currencyIcons, setCurrencyIcons] = useState({});
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const { setCurrencyData } = useContext(BaseCurrencyContext);
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    useEffect(() => {
        const fetchProfileImage = async () => {
            try {
                const { data: image } = await getApiData("api/ProfileImage/GetProfileImage");
                setProfileImage(image?.isRecognised ? image : null);
            } catch (error) {
                console.error("Error fetching profile image.", error);
            }
        };
        fetchProfileImage();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const sgdOption = {
                    value: "SGD",
                    label: "SGD",
                    currencyCode: "SGD",
                };
                const sgdIcon = "path/to/sgd-icon.png";
                setCurrencyIcons({ SGD: sgdIcon });
                setCurrencyOptions([sgdOption]);
                setSelectedCurrency(sgdOption);
                setCurrencyData(sgdOption);


                const [currenciesResponse, branchResponse] = await Promise.all([
                    getApiData("api/Currency/GetAllCurrencies"),
                    getApiData("api/RateMaster/GetGlobalTransferRate"),
                ]);

                if (currenciesResponse.success && branchResponse.success) {

                    const icons = currenciesResponse.data.reduce((acc, { currencyCode, currencyFlag }) => {
                        acc[currencyCode.trim()] = `data:image/png;base64,${currencyFlag}`;
                        return acc;
                    }, {});

                    const options = branchResponse.data
                        .filter(({ currencyCode, buyRateMainBranch }) =>
                            currencyCode.trim() !== "SGD" && buyRateMainBranch !== null && buyRateMainBranch !== ""
                        )
                        .map(({ currencyCode, currencyName }) => ({
                            value: currencyCode.trim(),
                            label: currencyCode.trim(),
                            currencyCode: currencyCode.trim(),
                        }));


                    options.unshift(sgdOption);

                    setCurrencyIcons(icons);
                    setCurrencyOptions(options);
                }
            } catch (error) {
                console.error("Error fetching currencies:", error);
            }
        };

        fetchData();
    }, []);
    const handleCurrencySelect = async (option) => {
        if (option.value !== "SGD") {
            const result = await Swal.fire({
                text: `You are attempting to change Currency. All the conversions will now be based on ${option.value} currency, and you will only be able to perform the Transfer Operation. Do you wish to proceed?`,
                title: "Warning",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Ok",
                confirmButtonColor: "#556ee6",
            });

            if (!result.isConfirmed) return;
        }

        setSelectedCurrency({
            ...option,
            label: option.value,
        });
        setCurrencyData(option);
    };

    const toggleMenu = () => setMenu(!menu);

    useEffect(() => {
        if (imageData?.normalImage) {
            setProfileImage(imageData);
        }
    }, [imageData]);


    return (
        <>
            <ToastContainer />
            <div className="d-flex mt-3 gap-2 " style={{ marginRight: '30px' }}>

                <p className="d-none d-sm-flex align-items-center" style={{ color: '#556ee6' }}>
                    Your Country
                </p>
                <ReactSelect
                    value={selectedCurrency}
                    options={currencyOptions || []}
                    onChange={(option) => {
                        setSelectedCurrency(option);
                        handleCurrencySelect(option);
                    }}
                    components={{ IndicatorSeparator: null }}
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            width: "100%",
                            maxWidth: "400px",
                        }),
                        control: (provided) => ({
                            ...provided,
                            width: "100%",
                            display: "flex",
                        }),
                        menu: (provided) => ({
                            ...provided,
                            width: "100%",
                            minWidth: "300px",
                        }),
                        option: (provided) => ({
                            ...provided,
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            display: "flex",
                            alignItems: "center",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }),
                    }}
                    formatOptionLabel={({ value, label }) => (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "100%",
                        }}>
                            {currencyIcons[value] && (
                                <img
                                    src={currencyIcons[value]}
                                    alt={label}
                                    style={{ width: "20px", height: "20px", marginRight: "8px" }}
                                />
                            )}
                            <span>{label}</span>
                        </div>
                    )}
                />
            </div>

            {/* Profile Dropdown */}
            <Dropdown isOpen={menu} toggle={toggleMenu} className="d-flex align-items-center" >
                <DropdownToggle className="btn header-item d-flex align-items-center gap-1 " tag="button" >
                    {profileImage?.isRecognised ? (
                        <div className="rounded-circle border border-dark align-items-end text-end" style={{ width: 35, height: 35, overflow: "hidden" }}>
                            <img
                                src={`data:image/png;base64,${profileImage.normalImage}`}
                                alt="Profile"
                                className="w-100 h-100"
                                style={{ objectFit: "cover", borderRadius: "50%" }}
                            />
                        </div>
                    ) : (
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                    )}
                    <span className="d-none d-xl-inline-block">
                        {profileData?.firstName ? `${profileData.firstName} ${profileData.lastName}` : ""}
                    </span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem tag="a" href="/profile">
                        <i className="bx bx-user font-size-16 me-1" /> {props.t("Profile")}
                    </DropdownItem>
                    <DropdownItem tag="a" href="/changepassword">
                        <i className="bx bx-key font-size-16 me-1" /> {props.t("Change password")}
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 me-1 text-danger" /> {props.t("Logout")}
                    </Link>
                </DropdownMenu>
            </Dropdown>

            {/* Notification Bell Dropdown */}
            <Notifications />
        </>
    );
};

ProfileMenu.propTypes = {
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ProfileMenu));
