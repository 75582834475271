import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    Label,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    NavItem,
    TabPane,
    CardBody,
    TabContent,
    Table,
    FormFeedback
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import classnames from 'classnames';
import ReactSelect from 'react-select';
import { toast } from "react-toastify";
import Loader from "../../components/Common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { faArrowLeft, faDownload, faEye, faTrashAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { deleteApiData, getApiData, postApiData, putApiData } from "../../helpers/axiosHelper";
import { SelectStyle } from "../../common/data/SelectStyle";
import moment from "moment";
import DatePicker from "react-datepicker";

const AVEBeneficiary = (props) => {
    const {
        isOpen,
        toggle,
        createBenFor,
        editMode,
        currencies,
        documentData,
        getDocument,
        getAvaBeneficiary,
        fetchAllBeneficiary,
        sendBeneficiaryDetails,
    } = props;


    const [saving, setSaving] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [isDraft, setIsDraft] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeTab, setactiveTab] = useState(1);
    const [docFormat, setDocFormat] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [viewDocName, setViewDocName] = useState("");
    const [docIsValid, setDocIsValid] = useState(true);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [viewDocument, setViewDocument] = useState("");
    const [nationalData, setNationalData] = useState([]);
    const [isAddingDoc, setIsAddingDoc] = useState(false);
    const [completedTabs, setCompletedTabs] = useState([]);
    const [modal_standard, setmodal_standard] = useState(false);
    const [beneficiaryData, setBeneficiaryData] = useState(null);
    const [isFormDisabled, setIsFormDisabled] = useState({
        beneficiary: false,
        bank: false,
        address: false,
    });

    const toggleViewModal = () => setModal1(!modal1);

    const handleClose = () => {
        toggle();

        setactiveTab(1);
        setCompletedTabs([]);
        /*Noorul changes*/
        beneficiaryValidation.resetForm();
        bankValidation.resetForm();
        addressValidation.resetForm();
        documentValidation.resetForm();
        setIsFormDisabled(false);
    };


    /*Noorul changes*/
    const handleSuccessfulSubmit = (tab, response) => {
        setCompletedTabs((prev) => [...new Set([...prev, tab])]);

        setIsFormDisabled((prev) => ({
            ...prev,
            [tab === 1 ? 'beneficiary' : tab === 2 ? 'bank' : 'address']: true,
        }));
    };

    /*Noorul changes*/
    const toggleEnableTab = (tab) => {
        console.log(`Attempting to enable Tab ${tab}`);


        const isAccessible =
            tab === 1 ||
            editMode === 1 ||
            editMode === 2 ||
            completedTabs.includes(tab - 1);

        if (isAccessible) {
            toggleTab(tab);
        } else {
            console.warn(`Tab ${tab} is not accessible.`);
        }
    };


    function toggleTab(tab) {
        if (activeTab !== tab) {
            const modifiedSteps = new Set([...passedSteps, tab]);
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps([...modifiedSteps]);
            }
        }
    }

    //const ben = () => {
    //    if (pathname === "/dashboard") {
    //        return getAvaBeneficiary();
    //    }
    //    return fetchAllBeneficiary();
    //};


    const mapNationality = (nationality) => {
        if (!nationality) return [];
        return nationality.map((e) => ({
            label: e.nation,
            value: e.id
        }));
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const nationalResponse = await getApiData('api/Address/GetAllNationality');
            const national = await nationalResponse
            setNationalData(mapNationality(national));
            setLoading(false);
        } catch (error) {
            console.error("Error occurred while fetching data.", error);
        }

    };

    const accType = [
        { label: "personal", value: 1 },
        { label: "bussiness", value: 2 },

    ]

    const currencyIdToSend = createBenFor
        ? currencies
            .map((currency) => ({
                value: currency.currencyId,
                label: currency.currencyName,
                currencyCode: currency.currencyCode,
            }))
            .find(
                (option) =>
                    option.currencyCode === createBenFor ||
                    option.value === sendBeneficiaryDetails?.currencyId
            )?.value
        : sendBeneficiaryDetails?.currencyId;

    const beneficiaryValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            beneficiaryName: sendBeneficiaryDetails?.beneficiaryName || "",
            nationalId: sendBeneficiaryDetails?.nationalId || "",
            currencyId: currencyIdToSend || sendBeneficiaryDetails?.currencyId || "",
            mobile: sendBeneficiaryDetails?.mobile || "",
            email: sendBeneficiaryDetails?.email || "",
            dob: sendBeneficiaryDetails?.dob || "",
        },

        validationSchema: isDraft
            ?
            Yup.object({
                beneficiaryName: Yup.string().required(
                    "Please enter the Beneficiary Name"
                ),
            })
            :
            Yup.object({
                beneficiaryName: Yup.string()
                    .required("Please enter the Beneficiary Name"),
                dob: Yup.string()
                    .required("Please select your Date of Birth"),
                currencyId: Yup.string()
                    .required("Please select the Currency"),
                nationalId: Yup.string()
                    .required("Please select beneficiary Nationality"),
                email: Yup.string()
                    .email("Invalid Email address.")
                    .required("Please Enter Your Email"),
                mobile: Yup.string()
                    .required("Please Enter Your Mobile Number")
                    .matches(/^[0-9]*$/, 'Please enter only numbers in the Mobile field')
                    .matches(/^\+?\d{8,14}$/, "Mobile Number can not be less 8 digits"),
            }),

        onSubmit: async (values) => {
            try {
                setSaving(true);
                if (editMode === 3) {
                    const apiEndpoint = isDraft
                        ? "api/CustomerBeneficiary/DraftBeneficiaryDetails"
                        : "api/CustomerBeneficiary/CreateBeneficiaryDetails";
                    const response = await postApiData(apiEndpoint, values);
                    if (response.success === true) {
                        toast.success("Beneficiary Created successfully.", {
                            position: "top-right",
                            autoClose: 3000,
                        });
                        handleSuccessfulSubmit(1);

                        //fetchAllBeneficiary();
                        getAvaBeneficiary();
                        setBeneficiaryData(response?.data);
                        toggleTab(activeTab + 1);
                        setSaving(false);
                    } else {
                        toast.error("Error occurred while creating Beneficiary.", {
                            position: "top-right",
                            autoClose: 3000,
                        });
                        toggle();
                        setSaving(false);
                    }
                } else {
                    const hasChanges = Object.keys(values).some(
                        (key) => values[key] !== beneficiaryValidation.initialValues[key]
                    );
                    if (hasChanges) {
                        const response = await putApiData(
                            `api/CustomerBeneficiary/UpdateBeneficiary?CustBenId=${sendBeneficiaryDetails?.custBenId}`,
                            values
                        );
                        if (response.success === true) {
                            toast.success(
                                "Beneficiary detail's Updated successfully.",
                                {
                                    position: "top-right",
                                    autoClose: 3000,
                                });
                            //fetchAllBeneficiary();
                            getAvaBeneficiary();
                            toggleTab(activeTab + 1);

                            setSaving(false);
                        } else {
                            toast.error(
                                "Error occurred while updating Beneficiary detail's.",
                                {
                                    position: "top-right",
                                    autoClose: 300,
                                });
                            toggle();
                            setSaving(false);
                        }
                    } else {
                        toast.info("No Changes to update.", {
                            position: "top-right",
                            autoClose: 3000,
                        });
                        setSaving(false);
                    }
                }
            } catch (error) {
                toast.error("Error occurred while saving data.", {
                    position: "top-right",
                    autoClose: 3000,
                });
                toggle();
                setSaving(false);
            }
        }
    });

    const bankValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            swift: sendBeneficiaryDetails?.swift || "",
            other: sendBeneficiaryDetails?.other || "",
            accountNumber: sendBeneficiaryDetails?.accountNumber || "",
            isMyAccount: sendBeneficiaryDetails?.isMyAccount || false,
            accountType: sendBeneficiaryDetails?.accountType || "",
            bankAddress: sendBeneficiaryDetails?.bankAddress || "",
            bankName: sendBeneficiaryDetails?.bankName || "",
        },

        validationSchema: Yup.object({
            bankName: Yup.string()
                .required("Please enter the Bank Name"),
            swift: Yup.string()
                .required("Please enter the SWIFT Code"),
            accountType: Yup.string()
                .required("Please select the Account Type"),
            accountNumber: Yup.string()
                .required("Please enter the Account Number"),
            bankAddress: Yup.string()
                .required("Please provide the beneficiary's bank address"),

        }),

        onSubmit: async (values) => {
            try {
                setSaving(true);
                const custBenId = sendBeneficiaryDetails?.custBenId || beneficiaryData?.custBenId;
                const hasChanges = Object.keys(values).some(key => values[key] !== bankValidation.initialValues[key]);
                if (hasChanges) {
                    const response = await putApiData(`api/Customerbeneficiary/UpdateBeneficiaryBank?CustBenId=${custBenId}`, values);

                    if (response.success === true) {

                        toast.success(
                            editMode === 3
                                ?
                                "Beneficiary bank detail's Added successfully."
                                :
                                "Beneficiary bank detail's Updated successfully."
                            , {
                                position: "top-right",
                                autoClose: 3000,
                            });
                        setSaving(false);
                        /*Noorul changes*/
                        handleSuccessfulSubmit(2);
                        getAvaBeneficiary();

                        //fetchAllBeneficiary();
                        toggleTab(activeTab + 1);
                    } else {
                        toast.error("Error occurred while adding bank detail's.", {
                            position: "top-right",
                            autoClose: 3000
                        });
                        setSaving(false);

                        toggle();
                    }
                } else {
                    toast.info("No Changes to update.", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    setSaving(false);
                }

            } catch (error) {
                toast.error("Error occurred while saving data.", {
                    position: "top-right",
                    autoClose: 3000
                });
                setSaving(false);
                toggle();
            }
        },
    });

    const addressValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            street: sendBeneficiaryDetails?.street || '',
            city: sendBeneficiaryDetails?.city || '',
            state: sendBeneficiaryDetails?.state || '',
            country: sendBeneficiaryDetails?.country || '',
            postalcode: sendBeneficiaryDetails?.postalCode || '',
            nationality: (nationalData?.find(nation => nation.value === parseInt(sendBeneficiaryDetails?.nationality))) || null,
        },

        validationSchema: Yup.object({
            street: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "At least 3 letters required.")
                .required("Please Enter Your Street"),
            city: Yup.string()
                .max(50, "Max character's reached.")
                .min(2, "At least 2 letters required.")
                .matches(/^[a-zA-Z\s]*$/, "Please enter only letters in the city field")
                .required("Please Enter Your City"),
            state: Yup.string()
                .max(50, "Max character's reached.")
                .min(2, "At least 2 letters required.")
                .matches(/^[a-zA-Z\s]*$/, "Please enter only letters in the state field")
                .required("Please Enter Your State"),
            country: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "At least 3 letters required.")
                .matches(/^[a-zA-Z\s]*$/, "Please enter only letters in the country field")
                .required("Please Enter Your Country"),
            postalcode: Yup.string()
                .max(10, 'Postal code cannot exceed 10 characters.')
                .min(4, 'Postal code must have at least 4 characters.')
                .required("Please enter your PIN code."),
            nationality: Yup.object()
                .required("Please Select your nationality."),
        }),

        onSubmit: async (values) => {
            try {
                setSaving(true);
                const nationalityValue = values.nationality && values.nationality.value
                const custBenId = sendBeneficiaryDetails?.custBenId || beneficiaryData?.custBenId;

                const submissionValues = {
                    ...values,
                    nationality: nationalityValue
                };

                if (!custBenId) {
                    toast.error("Beneficiary not found. Cannot add address.", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    setSaving(false);
                    return;
                }

                const hasChanges = Object.keys(values).some(key => values[key] !== addressValidation.initialValues[key]);

                if (hasChanges) {

                    //fetchAllBeneficiary();
                    getAvaBeneficiary();
                } else {
                    toast.info("No Changes to update.", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    setSaving(false);
                }

                if (!hasChanges) {
                    toast.info("No changes to update.", {
                        position: "top-right",
                        autoClose: 3000
                    });
                    setSaving(false);
                }
                if (!sendBeneficiaryDetails?.addressId) {
                    await handleAddressCreate(submissionValues);
                } else {
                    await handleAddressUpdate(submissionValues);
                }
            } catch (error) {
                toast.error("Error processing address. Contact Admin", {
                    position: "top-right",
                    autoClose: 3000,
                });
                toggle();
                setSaving(false);
            }
        },
    });

    const handleAddressCreate = async (values) => {
        const custBenId = sendBeneficiaryDetails?.custBenId || beneficiaryData?.custBenId;

        if (!custBenId) {
            toast.error("Beneficiary not found. Cannot add address.", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }

        const response = await postApiData(`api/Customerbeneficiary/CreateBeneficiaryAddress?CustBenId=${custBenId}`, JSON.stringify(values));

        if (response.success === true) {
            toast.success("Address Added Successfully", {
                position: "top-right",
                autoClose: 3000
            });
            setSaving(false);
            /*Noorul changes*/
            handleSuccessfulSubmit(3);
            getAvaBeneficiary();
            toggleTab(activeTab + 1);
        } else {
            toast.error("Error Adding address. Contact Admin", {
                position: "top-right",
                autoClose: 3000,
            });
            toggle();
            setSaving(false);
        }
    };

    const handleAddressUpdate = async (values) => {

        const updatedResponse = await putApiData(`api/Address/UpdateAddress?AddressID=${sendBeneficiaryDetails?.addressId}`, values);
        if (updatedResponse.success === true) {
            toast.success("Address Updated Successfully", {
                position: "top-right",
                autoClose: 3000
            });

            toggleTab(activeTab + 1);

            setSaving(false);
            getAvaBeneficiary();
        } else {
            toast.error("Error saving Address info.", {
                position: "top-right",
                autoClose: 3000
            });
            toggle();
            setSaving(false);
        }
    };

    const documentValidation = useFormik({
        enableReinitialize: true,

        initialValues: {
            documentType: '',
            documentName: '',
            document: null,
            documentFormat: null,
        },

        validationSchema: Yup.object({
            documentType: Yup.string().required("Please Select the Document Type"),
            documentName: Yup.string().required("Please Enter the Document Name"),
            document: Yup.string().required("Please Upload the Document"),
        }),

        onSubmit: async (values, { resetForm }) => {
            const custBenId = sendBeneficiaryDetails?.custBenId || beneficiaryData?.custBenId;
            if (!custBenId) {
                setIsAddingDoc(true);
                toast.error("Beneficiary isn't availabel. Cannot add Document.", {
                    position: "top-right",
                    autoClose: 3000,
                });
                resetForm();
                setSaving(false);
                tog_standard();
                setIsAddingDoc(false);
                return;
            }
            console.log(documentData);
            if (custBenId) {
                setSaving(true);
                setIsAddingDoc(true);
                if (docIsValid) {
                    const combinedValues = { ...values, document: fileData, documentFormat: docFormat }

                    const response = await postApiData(`api/Customerbeneficiary/AddBeneficiaryDocument?CustBenId=${custBenId}`, combinedValues);
                    if (response.success === true) {
                        toast.success("Document Added Successfully", {
                            position: "top-right",
                            autoClose: 3000,
                        });

                        getAvaBeneficiary();
                        getDocument(custBenId);

                        setIsAddingDoc(false);

                        setSaving(false);
                    } else {
                        toast.error("Error Uploading Doc. Contact Admin", {
                            position: "top-right",
                            autoClose: 3000,
                        });
                        setIsAddingDoc(false);
                    }

                } else {
                    toast.warning("File Size Should be less than 5MB", {
                        position: "top-right",
                        autoClose: 3000,
                    })
                    setIsAddingDoc(false);
                }

            } else {
                toast.info("Max Doc space has been reached.", {
                    position: "top-right",
                    autoClose: 3000,
                });
                setIsAddingDoc(false);
            }
            resetForm();
            tog_standard();
        },
    });

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function handleDocTypeChanged(e) {
        documentValidation.handleChange(e);
    }

    function getDocumentType(obj) {
        if (obj === "I") {
            return "Identity Proof";
        } else if (obj === "A") {
            return "Address Proof";
        } else if (obj === "O") {
            return "Others";
        } else {
            return "";
        }
    }

    function handleFileChange(e) {
        setDocIsValid(true);
        const file = e.target.files[0];
        if (!file) return;
        if (file.size > 5000 * 1024) {
            toast.error("File Size Should be less than 5MB", {
                position: "top-right",
                autoClose: 3000
            });
            documentValidation.resetForm();
            tog_standard();
            setDocIsValid(false);
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            const type = reader.result.split(";")[0];
            const docType = type.split("/")[1];
            let base64String = '';
            const indexOfComma = reader.result.indexOf(',');
            if (indexOfComma !== -1) {
                base64String = reader.result.substring(indexOfComma + 1);
            }
            setDocFormat(docType);
            setFileData(base64String);
        };
        reader.readAsDataURL(file);
    }

    const getFileType = (contentType) => {
        switch (contentType) {
            case 'png':
                return 'image/png';
            case 'jpg':
                return 'image/jpg';
            case 'jpeg':
                return 'image/jpeg';
            case 'pdf':
                return 'application/pdf';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            default:
                return 'application/octet-stream';
        }
    };

    const viewDoc = (index) => {
        const data = documentData[index].document;
        const name = documentData[index].documentName;
        const docFormat = documentData[index].documentFormat;

        setViewDocument(data);

        setViewDocName(name);

        const imageTypes = ['png', 'jpg', 'jpeg'];

        if (imageTypes.includes(docFormat)) {
            toggleViewModal();
        } else {
            handleDownloadFile(index);
        }
    };

    const handleDownloadFile = (index) => {
        const data = documentData[index];
        const byteChars = atob(data.document);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: getFileType(data.documentFormat) });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${data.documentName}.${data.contentType !== null ? data.documentFormat : "png"}`;
        link.click();
        URL.revokeObjectURL(url);
    };

    const deleteDoc = async (index) => {
        const benId = sendBeneficiaryDetails?.custBenId || beneficiaryData?.custBenId;
        const response = await deleteApiData(`api/Customerbeneficiary/Delete?BeneficiaryId=${benId}&active=true`);
        if (response.success === true) {
            toast.success("Document Deleted Successfully", {
                position: "top-right",
                autoClose: 3000,
            });
            getDocument(sendBeneficiaryDetails?.custBenId);
        } else {
            toast.error("Error Deleting Document. Contact Admin", {
                position: "top-right",
                autoClose: 3000,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (isDraft === true || isDraft === false) {
            beneficiaryValidation.handleSubmit();
        }
    }, [isDraft]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            disabled={saving}
            centered={true}
            onClosed={() => {
                setactiveTab(1);
                setIsFormDisabled(false);
                setCompletedTabs([]);
                beneficiaryValidation.resetForm();
                bankValidation.resetForm();
                addressValidation.resetForm();
                documentValidation.resetForm();
            }}
            autoClose={true} size='lg'>

            <ModalHeader toggle={toggle}>
                {editMode === 1 ? "View Beneficiary" : editMode === 2 ? "Edit Beneficiary" : "Add Beneficiary"}
            </ModalHeader>

            {loading ?
                <Loader />
                :
                <ModalBody>

                    <Row>
                        <Col lg="12">

                            <div className="wizard clearfix">

                                <div className="steps clearfix">

                                    <ul>
                                        <NavItem
                                            style={{ cursor: "pointer" }}
                                            className={classnames(
                                                { "bg-primary-subtle": activeTab === 1, "bg-light": activeTab !== 1 },
                                                "p-1 me-1"
                                            )}
                                            onClick={() => toggleEnableTab(1)}
                                        >
                                            <span className="number">1.</span> Beneficiary Detail's
                                        </NavItem>

                                        <NavItem
                                            style={{
                                                cursor:
                                                    editMode === 1 || editMode === 2 || completedTabs.includes(1)
                                                        ? "pointer"
                                                        : "not-allowed",
                                            }}
                                            className={classnames(
                                                { "bg-primary-subtle": activeTab === 2, "bg-light": activeTab !== 2 },
                                                "p-1 me-1"
                                            )}
                                            onClick={() =>
                                                (editMode === 1 || editMode === 2 || completedTabs.includes(1)) &&
                                                toggleEnableTab(2)
                                            }
                                        >
                                            <span className="number">2.</span> Bank Detail's
                                        </NavItem>

                                        <NavItem
                                            style={{
                                                cursor:
                                                    editMode === 1 || editMode === 2 || completedTabs.includes(2)
                                                        ? "pointer"
                                                        : "not-allowed",
                                            }}
                                            className={classnames(
                                                { "bg-primary-subtle": activeTab === 3, "bg-light": activeTab !== 3 },
                                                "p-1 me-1"
                                            )}
                                            onClick={() =>
                                                (editMode === 1 || editMode === 2 || completedTabs.includes(2)) &&
                                                toggleEnableTab(3)
                                            }
                                        >
                                            <span className="number">3.</span> Address
                                        </NavItem>

                                        <NavItem
                                            style={{
                                                cursor:
                                                    editMode === 1 || editMode === 2 || completedTabs.includes(3)
                                                        ? "pointer"
                                                        : "not-allowed",
                                            }}
                                            className={classnames(
                                                { "bg-primary-subtle": activeTab === 4, "bg-light": activeTab !== 4 },
                                                "p-1 me-1"
                                            )}
                                            onClick={() =>
                                                (editMode === 1 || editMode === 2 || completedTabs.includes(3)) &&
                                                toggleEnableTab(4)
                                            }
                                        >
                                            <span className="number">4.</span> Document List
                                        </NavItem>
                                    </ul>

                                </div>

                                <div className="content clearfix">
                                    <TabContent activeTab={activeTab} className="body">

                                        <TabPane tabId={1} >
                                            <Form className="needs-validation"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    beneficiaryValidation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <Row>
                                                    <Col lg="6">
                                                        <Label for="beneficiaryName">Beneficiary Name</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            type="text"
                                                            id="beneficiaryName"
                                                            className="form-control"
                                                            placeholder="Beneficiary Name"
                                                            disabled={
                                                                editMode === 1
                                                                    ? true
                                                                    : editMode === 2
                                                                        ? false
                                                                        : isFormDisabled.beneficiary
                                                            }
                                                            onChange={beneficiaryValidation.handleChange}
                                                            onBlur={beneficiaryValidation.handleBlur}
                                                            value={beneficiaryValidation.values.beneficiaryName || ""}
                                                            invalid={
                                                                beneficiaryValidation.touched.beneficiaryName && beneficiaryValidation.errors.beneficiaryName ? true : false
                                                            } />
                                                        {beneficiaryValidation.touched.beneficiaryName && beneficiaryValidation.errors.beneficiaryName ? (
                                                            <FormFeedback type="invalid">{beneficiaryValidation.errors.beneficiaryName}</FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <Label for="dob">Date of Birth</Label>
                                                            <RequiredAsterisk />
                                                            <span style={{ color: "red" }}>*</span>

                                                            <DatePicker
                                                                id="dob"
                                                                selected={
                                                                    beneficiaryValidation.values.dob
                                                                        ? new Date(beneficiaryValidation.values.dob)
                                                                        : null
                                                                }
                                                                onChange={(date) => {
                                                                    const formattedDate = date
                                                                        ? moment(date).format("YYYY-MM-DD")
                                                                        : "";
                                                                    beneficiaryValidation.setFieldValue("dob", formattedDate);
                                                                    beneficiaryValidation.setFieldTouched("dob", true, false);
                                                                }}
                                                                onBlur={() => beneficiaryValidation.setFieldTouched("dob", true)}
                                                                dateFormat="yyyy-MM-dd"
                                                                className={`form-control ${beneficiaryValidation.touched.dob && beneficiaryValidation.errors.dob
                                                                        ? "is-invalid"
                                                                        : ""
                                                                    }`}
                                                                maxDate={new Date()} 
                                                                disabled={editMode === 1}
                                                                placeholderText="Date of Birth (YYYY-MM-DD)"
                                                            />

                                                            {beneficiaryValidation.touched.dob && beneficiaryValidation.errors.dob && (
                                                                <div className="invalid-feedback d-block">
                                                                    {beneficiaryValidation.errors.dob}
                                                                </div>
                                                            )}
                                                        </FormGroup>
                                                    </Col>

                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="country">Contact Email</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                placeholder="user@example.com"
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.beneficiary
                                                                }
                                                                onChange={beneficiaryValidation.handleChange}
                                                                onBlur={beneficiaryValidation.handleBlur}
                                                                value={beneficiaryValidation.values.email || ""}
                                                                invalid={
                                                                    beneficiaryValidation.touched.email && beneficiaryValidation.errors.email ? true : false
                                                                }
                                                            />
                                                            {beneficiaryValidation.touched.email && beneficiaryValidation.errors.email ? (
                                                                <FormFeedback type="invalid">{beneficiaryValidation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="postalcode">Mobile</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="mobile"
                                                                placeholder="Enter Mobile"
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.beneficiary
                                                                }
                                                                onChange={beneficiaryValidation.handleChange}
                                                                onBlur={beneficiaryValidation.handleBlur}
                                                                value={beneficiaryValidation.values.mobile || ""}
                                                                invalid={
                                                                    beneficiaryValidation.touched.mobile && beneficiaryValidation.errors.mobile ? true : false
                                                                }
                                                            />
                                                            {beneficiaryValidation.touched.mobile && beneficiaryValidation.errors.mobile ? (
                                                                <FormFeedback type="invalid">{beneficiaryValidation.errors.mobile}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="nationalId">National ID</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                type="text"
                                                                id="nationalId"
                                                                className="form-control"
                                                                placeholder="National Id Number"
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.beneficiary
                                                                }
                                                                onChange={beneficiaryValidation.handleChange}
                                                                onBlur={beneficiaryValidation.handleBlur}
                                                                value={beneficiaryValidation.values.nationalId || ""}
                                                                invalid={
                                                                    beneficiaryValidation.touched.nationalId && beneficiaryValidation.errors.nationalId ? true : false
                                                                }
                                                            />
                                                            {beneficiaryValidation.touched.nationalId && beneficiaryValidation.errors.nationalId ? (
                                                                <FormFeedback type="invalid">{beneficiaryValidation.errors.nationalId}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <FormGroup>
                                                                <Label for="currencyId">Currency</Label>
                                                                <RequiredAsterisk />
                                                                <ReactSelect
                                                                    id="currencyId"
                                                                    placeholder="Select Currency"
                                                                    styles={SelectStyle}

                                                                    isDisabled={

                                                                        createBenFor || editMode === 1
                                                                            ? true
                                                                            : editMode === 2
                                                                                ? false
                                                                                : isFormDisabled.beneficiary
                                                                    }
                                                                    options={currencies?.map(currency => ({
                                                                        value: currency.currencyId,
                                                                        label: currency.currencyName,
                                                                        currencyCode: currency.currencyCode,
                                                                    }))}

                                                                    value={
                                                                        createBenFor
                                                                            ? currencies
                                                                                ?.map((currency) => ({
                                                                                    value: currency.currencyId,
                                                                                    label: currency.currencyName,
                                                                                    currencyCode: currency.currencyCode,
                                                                                }))
                                                                                .find(
                                                                                    (option) =>
                                                                                        option.currencyCode === createBenFor
                                                                                ) || null
                                                                            : currencies
                                                                                ?.map((currency) => ({
                                                                                    value: currency.currencyId,
                                                                                    label: currency.currencyName,
                                                                                }))
                                                                                .find(
                                                                                    (option) =>
                                                                                        option.value ===
                                                                                        beneficiaryValidation.values
                                                                                            .currencyId
                                                                                ) || null
                                                                    }
                                                                    onBlur={beneficiaryValidation.handleBlur}
                                                                    onChange={(selectedOption) =>
                                                                        beneficiaryValidation.setFieldValue('currencyId', selectedOption ? selectedOption.value : '')
                                                                    }
                                                                    className={beneficiaryValidation.touched.currencyId && beneficiaryValidation.errors.currencyId ? 'is-invalid' : ''}
                                                                />



                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>

                                        <TabPane tabId={2} className="mb-1">

                                            <Form className="needs-validation"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    bankValidation.handleSubmit();
                                                    return false;
                                                }}>

                                                <Row className="mt-0 mb-0">

                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="accountType">Account Type</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                id="accountType"
                                                                name="accountType"
                                                                type="select"
                                                                value={bankValidation.values.accountType}
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.bank
                                                                }
                                                                onChange={bankValidation.handleChange}
                                                                onBlur={bankValidation.handleBlur}
                                                                invalid={!!bankValidation.errors.accountType && bankValidation.touched.accountType}
                                                            >
                                                                <option hidden={true} value="">Select Account Type</option>
                                                                {accType.map((index) => (
                                                                    <option key={index.value} value={index.value}>
                                                                        {index.label}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                            {bankValidation.errors.accountType && bankValidation.touched.accountType && (
                                                                <FormFeedback>{bankValidation.errors.accountType}</FormFeedback>
                                                            )}
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={6}>
                                                        <FormGroup check className="mt-4 pt-3">
                                                            <Label check>
                                                                <Input
                                                                    id="isMyAccount"
                                                                    name="isMyAccount"
                                                                    type="checkbox"
                                                                    onChange={bankValidation.handleChange}
                                                                    initialValues={bankValidation.values.isMyAccount || false}
                                                                    disabled={
                                                                        editMode === 1
                                                                            ? true
                                                                            : editMode === 2
                                                                                ? false
                                                                                : isFormDisabled.bank
                                                                    }
                                                                // checked={bankValidation.values.isMyAccount === true}
                                                                />
                                                                Is My Account
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="bankName">Bank Name</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                id="bankName"
                                                                name="bankName"
                                                                placeholder="Name of the Bank"
                                                                type="text"
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.bank
                                                                }
                                                                {...bankValidation.getFieldProps("bankName")}
                                                                invalid={!!bankValidation.errors.bankName && bankValidation.touched.bankName}
                                                            />
                                                            <FormFeedback>{bankValidation.errors.bankName}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="accountNumber">Account Number</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                id="accountNumber"
                                                                name="accountNumber"
                                                                placeholder="Bank Account Number"
                                                                type="text"
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.bank
                                                                }
                                                                {...bankValidation.getFieldProps("accountNumber")}
                                                                invalid={!!bankValidation.errors.accountNumber && bankValidation.touched.accountNumber}
                                                            />
                                                            <FormFeedback>{bankValidation.errors.accountNumber}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="mb-0">
                                                        <FormGroup>
                                                            <Label for="swift">SWIFT Code</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                id="swift"
                                                                placeholder="Swift Number"
                                                                type="text"
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.bank
                                                                }
                                                                {...bankValidation.getFieldProps("swift")}
                                                                invalid={!!bankValidation.errors.swift && bankValidation.touched.swift}
                                                            />
                                                            <FormFeedback>{bankValidation.errors.swift}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="other">Other Detail's ( Optional )</Label>
                                                            <Input
                                                                id="other"
                                                                type="text"
                                                                placeholder="Additional Info"
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.bank
                                                                }
                                                                {...bankValidation.getFieldProps("other")}
                                                                invalid={!!bankValidation.errors.other && bankValidation.touched.other}
                                                            />
                                                            <FormFeedback>{bankValidation.errors.other}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <FormGroup>
                                                        <Label for="bankAddress">Bank Address</Label>
                                                        <RequiredAsterisk />
                                                        <textarea
                                                            rows="1"
                                                            id="bankAddress"
                                                            className={`form-control ${bankValidation.touched.bankAddress && bankValidation.errors.bankAddress ? 'is-invalid' : ''}`}
                                                            disabled={
                                                                editMode === 1
                                                                    ? true
                                                                    : editMode === 2
                                                                        ? false
                                                                        : isFormDisabled.bank
                                                            }
                                                            placeholder="Write your Bank Address here..."
                                                            {...bankValidation.getFieldProps("bankAddress")}
                                                        />
                                                        {bankValidation.touched.bankAddress && bankValidation.errors.bankAddress && (
                                                            <FormFeedback type="invalid">{bankValidation.errors.bankAddress}</FormFeedback>
                                                        )}
                                                    </FormGroup>

                                                </Row>
                                            </Form>

                                        </TabPane>

                                        <TabPane tabId={3}>

                                            <Form className="needs-validation"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    addressValidation.handleSubmit();
                                                    return false;
                                                }}>

                                                <Row>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="street">Street</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="street"
                                                                placeholder="Enter street"
                                                                onChange={addressValidation.handleChange}
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.address
                                                                }
                                                                onBlur={addressValidation.handleBlur}
                                                                value={addressValidation.values.street || ""}
                                                                invalid={addressValidation.touched.street && addressValidation.errors.street ? true : false} />
                                                            {addressValidation.touched.street && addressValidation.errors.street ? (
                                                                <FormFeedback type="invalid">{addressValidation.errors.street}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="city">City</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="city"
                                                                placeholder="Enter City"
                                                                onChange={addressValidation.handleChange}
                                                                onBlur={addressValidation.handleBlur}
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.address
                                                                }
                                                                value={addressValidation.values.city || ""}
                                                                invalid={addressValidation.touched.city && addressValidation.errors.city ? true : false} />
                                                            {addressValidation.touched.city && addressValidation.errors.city ? (
                                                                <FormFeedback type="invalid">{addressValidation.errors.city}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="state">State</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="state"
                                                                placeholder="Enter State"
                                                                onChange={addressValidation.handleChange}
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.address
                                                                }
                                                                onBlur={addressValidation.handleBlur}
                                                                value={addressValidation.values.state || ""}
                                                                invalid={addressValidation.touched.state && addressValidation.errors.state ? true : false} />
                                                            {addressValidation.touched.state && addressValidation.errors.state ? (
                                                                <FormFeedback type="invalid">{addressValidation.errors.state}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="country">Country</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="country"
                                                                placeholder="Enter Country"
                                                                onChange={addressValidation.handleChange}
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.address
                                                                }
                                                                onBlur={addressValidation.handleBlur}
                                                                value={addressValidation.values.country || ""}
                                                                invalid={addressValidation.touched.country && addressValidation.errors.country ? true : false} />
                                                            {addressValidation.touched.country && addressValidation.errors.country ? (
                                                                <FormFeedback type="invalid">{addressValidation.errors.country}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label for="postalcode">Postal Code</Label>
                                                            <RequiredAsterisk />
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="postalcode"
                                                                placeholder="Enter Postal Code"
                                                                onChange={addressValidation.handleChange}
                                                                disabled={
                                                                    editMode === 1
                                                                        ? true
                                                                        : editMode === 2
                                                                            ? false
                                                                            : isFormDisabled.address
                                                                }
                                                                onBlur={addressValidation.handleBlur}
                                                                value={addressValidation.values.postalcode || ""}
                                                                invalid={addressValidation.touched.postalcode && addressValidation.errors.postalcode ? true : false} />
                                                            {addressValidation.touched.postalcode &&
                                                                addressValidation.errors.postalcode ? (
                                                                <FormFeedback type="invalid">{addressValidation.errors.postalcode}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <FormGroup>
                                                                <Label for="nationality">Nationality</Label>
                                                                <RequiredAsterisk />
                                                                <ReactSelect
                                                                    name="nationality"
                                                                    placeholder="Select Nationality"
                                                                    type="text"
                                                                    styles={SelectStyle}
                                                                    disabled={
                                                                        editMode === 1
                                                                            ? true
                                                                            : editMode === 2
                                                                                ? false
                                                                                : isFormDisabled.address
                                                                    }
                                                                    id="nationality"
                                                                    options={nationalData}
                                                                    value={addressValidation.values.nationality || null}
                                                                    onChange={(selectedOption) => addressValidation.setFieldValue('nationality', selectedOption)}
                                                                    onBlur={addressValidation.handleBlur}
                                                                    invalid={
                                                                        addressValidation.touched.nationality && addressValidation.errors.nationality ? true : false
                                                                    }
                                                                    isDisabled={editMode === 1 || isFormDisabled.address}
                                                                />
                                                            </FormGroup>
                                                            {addressValidation.touched.nationality && addressValidation.errors.nationality ? (
                                                                <FormFeedback type="invalid">{addressValidation.errors.nationality}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </TabPane>

                                        <TabPane tabId={4}>
                                            <Col lg="12">
                                                <div className="table-responsive mb-2 mt-0" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                    {documentData?.length > 0 ? (
                                                        <Table className="table table-striped table-bordered mt-0 mb-0">
                                                            <thead className="table-light mt-0 mb-0">
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Document Type</th>
                                                                    <th>Document Name </th>
                                                                    <th>View Details </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {documentData?.map((arr, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{getDocumentType(arr.documentType)}</td>
                                                                        <td>{arr.documentName}</td>
                                                                        <td>
                                                                            <Button
                                                                                type="button"
                                                                                title="View"
                                                                                color="primary"
                                                                                className="btn-sm btn-rounded me-2"
                                                                                onClick={() => viewDoc(index)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faEye} />
                                                                            </Button>
                                                                            {editMode === 2 && (
                                                                                <Button
                                                                                    type="button"
                                                                                    title="Delete"
                                                                                    color="danger"
                                                                                    className="btn-sm btn-rounded"
                                                                                    onClick={() => deleteDoc(index)}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    ) : (
                                                        <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                                            <h3>No document to show</h3>
                                                        </div>
                                                    )}
                                                </div>
                                                {(editMode === 2 || editMode === 3) && (
                                                    <Button
                                                        className="btn btn-success"
                                                        data-toggle="modal"
                                                        data-target="#myModal"
                                                        disabled={documentData?.length >= 5}
                                                        onClick={() => {
                                                            tog_standard();
                                                        }}
                                                    >
                                                        {documentData?.length >= 5
                                                            ? "Max Limit"
                                                            : "+ Add Document"
                                                        }
                                                    </Button>
                                                )}

                                            </Col>
                                            {/* View Document Modal */}
                                            <Modal
                                                size="lg"
                                                isOpen={modal1}
                                                toggle={toggleViewModal}
                                                className="modal-dialog-centered"
                                            >
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="myModalLabel">
                                                        {viewDocName}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setModal1(false);
                                                        }}
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <Row>
                                                    <Col>
                                                        <Card>
                                                            <CardBody style={{ padding: '0' }}>
                                                                <img
                                                                    src={`data:image/png;base64,${viewDocument}`}
                                                                    alt="Document"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: 'auto',
                                                                        maxHeight: '50vh'
                                                                    }}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <ModalFooter>
                                                    <Button
                                                        type="button"
                                                        color="success"
                                                        className="btn-sm btn-rounded mb-2 me-1"
                                                        onClick={() => handleDownloadFile(documentData?.findIndex(doc => doc.documentName === viewDocName))}
                                                    >
                                                        <FontAwesomeIcon icon={faDownload} /> Download
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>

                                            {/* Add Document Modal */}
                                            <Modal
                                                isOpen={modal_standard}
                                                toggle={() => { tog_standard() }}
                                            >
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                                        Manage Documents
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            documentValidation.resetForm();
                                                            setmodal_standard(false);
                                                            return false;
                                                        }}
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <Row className="mb-4">
                                                                    <Col sm={6}>
                                                                        <div className="mt-3">
                                                                            <Label for="documentType">Document Type</Label>
                                                                            <RequiredAsterisk />
                                                                            <div className="col-md-12">
                                                                                <Input
                                                                                    className="form-select"
                                                                                    type="select"
                                                                                    id="documentType"
                                                                                    name="documentType"
                                                                                    onChange={handleDocTypeChanged}
                                                                                    onBlur={documentValidation.handleBlur}
                                                                                    value={documentValidation.values.documentType}
                                                                                    invalid={documentValidation.touched.documentType &&
                                                                                        documentValidation.errors.documentType ? true : false}
                                                                                >
                                                                                    <option value="" disabled hidden>Choose Type</option>
                                                                                    <option value="I">ID Proof</option>
                                                                                    <option value="A">Address Proof</option>
                                                                                    <option value="O">Others</option>
                                                                                </Input>
                                                                                {documentValidation.touched.documentType &&
                                                                                    documentValidation.errors.documentType ? (
                                                                                    <FormFeedback type="invalid">{documentValidation.errors.documentType}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={6}>
                                                                        <div className="mt-3">
                                                                            <Label for="documentName">Document Name</Label>
                                                                            <RequiredAsterisk />
                                                                            <div className="col-md-12">
                                                                                <Input
                                                                                    type="text"
                                                                                    placeholder="Document Name"
                                                                                    id="documentName"
                                                                                    name="documentName"
                                                                                    onChange={documentValidation.handleChange}
                                                                                    onBlur={documentValidation.handleBlur}
                                                                                    value={documentValidation.values.documentName}
                                                                                    invalid={documentValidation.touched.documentName &&
                                                                                        documentValidation.errors.documentName ? true : false}
                                                                                />
                                                                                {documentValidation.touched.documentName &&
                                                                                    documentValidation.errors.documentName ? (
                                                                                    <FormFeedback type="invalid">{documentValidation.errors.documentName}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <div className="mt-1">
                                                                            <Label for="document">Select Document</Label>
                                                                            <RequiredAsterisk />
                                                                            <div className="col-md-12">
                                                                                <Input
                                                                                    type="file"
                                                                                    id="document"
                                                                                    aria-label="Upload"
                                                                                    accept=".png, .jpg, .jpeg, .pdf"
                                                                                    aria-describedby="inputGroupFileAddon04"
                                                                                    onChange={(e) => {
                                                                                        handleFileChange(e);
                                                                                        documentValidation.handleChange(e);
                                                                                    }}
                                                                                    onBlur={documentValidation.handleBlur}
                                                                                    invalid={
                                                                                        documentValidation.touched.document &&
                                                                                            documentValidation.errors.document
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                />
                                                                                {documentValidation.touched.document &&
                                                                                    documentValidation.errors.document ? (
                                                                                    <FormFeedback type="invalid">{documentValidation.errors.document}</FormFeedback>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <div className="mt-0 mb-4 me-3 text-end">
                                                    <Button
                                                        className="btn btn-primary"
                                                        color="primary"
                                                        disabled={isAddingDoc}
                                                        onClick={documentValidation.handleSubmit}>
                                                        {isAddingDoc ? 'Submiting Document...' : 'Submit'}
                                                    </Button>
                                                </div>
                                            </Modal>
                                        </TabPane>

                                    </TabContent>

                                </div>

                            </div>

                        </Col>
                    </Row>

                </ModalBody >
            }
            <ModalFooter className="mt-0">

                {editMode === 3 && activeTab === 1 && (
                    <div className="me-1 text-start">
                        <Button
                            color="secondary"
                            disabled={saving || completedTabs.includes(activeTab)}
                            onClick={() => setIsDraft(true)}
                        >
                            Draft
                        </Button>
                    </div>
                )}


                <div>
                    <Button
                        color="secondary"
                        className="me-1"
                        disabled={activeTab === 1 || saving && !(editMode === 1 || editMode === 2)}
                        onClick={() => {
                            console.log("Previous Tab");
                            toggleTab(activeTab - 1);
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="fa-lg" />
                    </Button>

                    <Button
                        color="secondary"
                        className="me-1"
                        disabled={(activeTab === 4) || (!completedTabs.includes(activeTab) || saving) && !(editMode === 1 || editMode === 2)}
                        onClick={() => {
                            toggleTab(activeTab + 1);
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowRight} className="fa-lg" />
                    </Button>



                    {editMode === 3 && activeTab === 1 && (
                        <Button
                            color="primary"
                            disabled={saving || completedTabs.includes(activeTab) || saving}
                            onClick={() => setIsDraft(false)}
                        >
                            {saving ? "Saving. . . ." : "Save"}
                        </Button>

                    )}

                    {editMode === 3 && activeTab === 2 && (
                        <Button
                            color="primary"
                            disabled={saving || completedTabs.includes(activeTab)}
                            onClick={() => {
                                bankValidation.handleSubmit();
                            }}
                        >
                            {saving ? "Saving. . . ." : "Save"}
                        </Button>
                    )}

                    {editMode === 3 && activeTab === 3 && (
                        <Button
                            color="primary"
                            disabled={saving || completedTabs.includes(activeTab)}
                            onClick={addressValidation.handleSubmit}

                        >
                            {saving ? "Saving. . . ." : "Save"}
                        </Button>
                    )}

                    {activeTab === 4 && (
                        <Button
                            color="danger"
                            className="ms-2"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    )}

                    {editMode === 2 && activeTab === 1 && (
                        <Button
                            color="primary"
                            disabled={saving}
                            onClick={beneficiaryValidation.handleSubmit}
                        >
                            {saving ? "Updating. . . ." : "Update"}
                        </Button>
                    )}

                    {editMode === 2 && activeTab === 2 && (
                        <Button
                            color="primary"
                            disabled={saving}
                            onClick={() => {
                                bankValidation.handleSubmit();
                            }}
                        >
                            {saving ? "Updating. . . ." : "Update"}
                        </Button>
                    )}

                    {editMode === 2 && activeTab === 3 && (
                        <Button
                            color="primary"
                            disabled={saving}
                            onClick={addressValidation.handleSubmit}
                        >
                            {saving ? "Updating. . . ." : "Update"}
                        </Button>
                    )}

                </div>
            </ModalFooter>

        </Modal >
    );
};

AVEBeneficiary.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    sendBeneficiaryDetails: PropTypes.object,
};

export default AVEBeneficiary;
