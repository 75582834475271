import React, { useState, useContext, useEffect } from "react";
import {
    Col,
    Row,
    Card,
    Form,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    CardBody,
    Container,
    FormFeedback,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import moment from "moment";
import DatePicker from "react-datepicker";

//Redux"
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Images
import logoImg from "../../../images/crescent_logo.png";
import profileImg from "../../../images/profile-img.png";
import singpassLogo from "../../../images/myinfo.png";

//Other Authentication
import { GoogleLogin } from '@react-oauth/google';
import { api } from '../../config';
import { BaseRegisterContext } from "../../common/data/context";

const Register = () => {

    document.title = "Crescent Exchange | Register";

    const navigate = useNavigate();
    const { singPassData } = useContext(BaseRegisterContext);

    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

    const isChrome = (() => {
        const isChromium = !!window.chrome;
        const isGoogle = /Google Inc/.test(navigator.vendor);
        const isNotEdge = !/Edg/.test(navigator.userAgent);
        const isNotOpera = !/OPR/.test(navigator.userAgent);
        return isChromium && isGoogle && isNotEdge && isNotOpera;
    })();

    const toggleModal = () => setModal(!modal);

    const handleSingpass = async () => {
        try {
            const response = await getApiData('/api/SingPass/Login');

            if (response && response.codeChallenge && response.State) {
                const client_id = api.singPass.clientId;
                const redirect = encodeURIComponent(api.singPass.redirectUri);
                const scope = encodeURIComponent("openid uinfin aliasname dob email mobileno name sex");
                const code_challenge = response.codeChallenge;
                const state = response.State;
                const nonce = "bb5e1672-a460-4a9b-874e-c38d55ac3922";

                const url = `${api.singPass.authUrl}?scope=${scope}&response_type=code&redirect_uri=${redirect}&nonce=${nonce}&client_id=${client_id}&code_challenge=${code_challenge}&code_challenge_method=S256&state=${state}`;

                console.log("Redirecting to:", url);
                sessionStorage.setItem("singpass", JSON.stringify(response));
                sessionStorage.setItem("isPage", 'Register');
                window.location.href = url;
            } else {
                console.error("Invalid API Response: Missing codeChallenge or State");
            }
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    const handleGoogle = (credentialResponse) => {
        const authorizationCode = credentialResponse.credential;

        postApiData('api/User/RegisterWithGoogle',
            JSON.stringify({ idToken: authorizationCode }),
        )
            .then(response => response)
            .then(data => {
                //console.log(data);
                //sessionStorage.setItem("authUser", JSON.stringify(data));
                if (data.success === true) {
                    toast.success("Register Successfully", {
                        position: "top-right",
                        autoClose: 1500,
                        onClose: () => {
                            navigate("/login");
                        },
                    });
                }
                else {
                    toast.warning(`Registration Failed, ${data.message}`, {
                        position: "top-right",
                        autoClose: 1500,

                    });
                }
            })
            .catch(error => {
                console.error('Error exchanging authorization code:', error);
            });
    };

    const handleGoogleError = (errorResponse) => {
        toast.error('Google login failed', errorResponse);
    };

    const validation = useFormik({

        enableReinitialize: true,

        initialValues: {
            dob: "" || singPassData?.dob,
            email: "" || singPassData?.email,
            mobile: "" || singPassData?.mobileNo,
            password: "" || singPassData?.password,
            lastname: "" || singPassData?.lastname,
            firstname: "" || singPassData?.name,
            middlename: "" || singPassData?.middlename,
            confirmPassword: "" || singPassData?.password,
            uinFin: "" || singPassData?.uinFin,
            policy: false,
        },

        validationSchema: Yup.object({
            firstname: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "Atleast 3 letter's required.")
                .required("Please Enter Your First name")
                .matches(/^[a-zA-Z\s]*$/, "Number's cannot be a Name"),
            middlename: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "Atleast 3 letter's required.")
                .matches(/^[a-zA-Z\s]*$/, "Number's cannot be a Name"),
            lastname: Yup.string()
                .max(50, "Max character's reached.")
                .min(3, "Atleast 3 letter's required.")
                .required("Please Enter Your Last name")
                .matches(/^[a-zA-Z\s]*$/, "Number's cannot be a Name"),
            email: Yup.string()
                .email("Invalid Email address.")
                .required("Please Enter Your Email"),
            dob: Yup.date()
                .required("Please Enter Your Date of Birth")
                .typeError("Invalid Date of Birth format"),
            mobile: Yup.string()
                .required("Please Enter Your Mobile Number")
                .matches(/^\+?\d{8,14}$/, "Mobile Number must be 8-14 digits")
                .matches(/^[0-9]*$/, 'Please enter only numbers in the Mobile field'),
            password: Yup.string()
                .required("Please Enter Your Password")
                .min(8, 'Password must be 8 characters long')
                .matches(/[^\w]/, 'Password requires a symbol')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter'),
            confirmPassword: Yup.string()
                .required("Please Enter Your Password to Confirm")
                .required("Confirm password is required")
                .oneOf([Yup.ref("password"), null], "Passwords must match"),
            policy: Yup.boolean()
                .oneOf([true], "You must agree to the terms and conditions")
                .required("You must agree to the terms and conditions"),
            uinFin: Yup.string()
                .matches(/^[a-zA-Z0-9]*$/, 'Please enter only letters and numbers in the FIN field')
        }),

        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            try {
                const response = await postApiData(
                    "api/User/Register",
                    JSON.stringify(values)
                );
                if (response.success === true) {
                    if (response.data.uid) {
                        values.uid = response.data.uid;
                        values.email = response.data.email;
                        toast.success(
                            "User Registered Successfully, redirecting to verification", {
                            position: "top-right",
                            autoClose: 1500,
                            onClose: () => {
                                navigate(`/emailverification/${response.data.uid}`);
                            },
                        });
                    }
                } else {
                    resetForm();
                    toast.error(response.message, {
                        position: "top-right",
                        autoClose: 1500,
                    });
                }
                setIsLoading(false);
            } catch (error) {
                toast.error("An error occurred while registering. Please try again.", {
                    position: "top-right",
                    autoClose: 1500,
                });
                resetForm();
                setIsLoading(false);
            }
        },

    });

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />

            <div className="home-btn d-block" style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 1000 }}>
                <Link
                    to="/home"
                    className="text-dark"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    <i className="bx bx-home h2" style={{ marginRight: '5px' }} />

                </Link>
            </div>

            <div className="account-pages my-3 pt-sm-3">
                <Container>
                    <Row className="justify-content-center">

                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-3">
                                                <h5 className="text-primary">Registration</h5>
                                                <p>Get your Crescent Exchange account now.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profileImg} alt="Banner.png" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">

                                    <div className="avatar-md profile-user-wid mb-2">
                                        <Link to="/" >
                                            <span className="avatar-title rounded-circle bg-light w-75 h-75 border border-dark border-2">
                                                <img
                                                    src={logoImg}
                                                    alt="crescent.png"
                                                    className="rounded-circle w-75"
                                                />
                                            </span>
                                        </Link>
                                    </div>

                                    <div className="p-0">

                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label">NRIC or FIN number</Label>

                                                        <Input
                                                            id="uinFin"
                                                            name="uinFin"
                                                            className="form-control"
                                                            placeholder="NRIC/FIN"
                                                            type="input"
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.uinFin || ""}
                                                            onChange={validation.handleChange}
                                                            disabled={isLoading}
                                                        />
                                                        <div>
                                                            <small className="text-muted">If you have a Singpass ID, you can register here. You can also log in by clicking "Login with Singpass" or using the standard login form.</small>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>

                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label">First Name</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="firstname"
                                                            name="firstname"
                                                            className="form-control"
                                                            placeholder="Enter First Name"
                                                            type="text"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.firstname || ""}
                                                            disabled={isLoading}
                                                            invalid={
                                                                validation.touched.firstname &&
                                                                    validation.errors.firstname
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.firstname &&
                                                            validation.errors.firstname ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.firstname}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label"> Middle Name</Label>
                                                        <Input
                                                            id="middlename"
                                                            name="middlename"
                                                            className="form-control"
                                                            placeholder="Optional"
                                                            type="input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.middlename || ""}
                                                            disabled={isLoading}
                                                            invalid={
                                                                validation.touched.middlename &&
                                                                    validation.errors.middlename
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.middlename &&
                                                            validation.errors.middlename ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.middlename}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>

                                            <Row>

                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Last Name</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="lastname"
                                                            name="lastname"
                                                            className="form-control"
                                                            placeholder="Enter Last Name"
                                                            type="input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.lastname || ""}
                                                            disabled={isLoading}
                                                            invalid={
                                                                validation.touched.lastname &&
                                                                    validation.errors.lastname
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.lastname &&
                                                            validation.errors.lastname ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.lastname}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Mobile</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="mobile"
                                                            name="mobile"
                                                            className="form-control"
                                                            placeholder="Enter Number"
                                                            type="input"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.mobile || ""}
                                                            disabled={isLoading}
                                                            invalid={
                                                                validation.touched.mobile &&
                                                                    validation.errors.mobile
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.mobile &&
                                                            validation.errors.mobile ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.mobile}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                            </Row>

                                            <Row>

                                                <Col lg={7}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Email</Label>
                                                        <RequiredAsterisk />
                                                        <Input
                                                            id="email"
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="example@gmail.com"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            disabled={isLoading}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>


                                                <Col lg={5}>
                                                    <div className="mb-3">
                                                        <Label for="dob">Date of Birth</Label>
                                                        <RequiredAsterisk />

                                                        <div style={{ position: 'relative', zIndex: 1 }}>
                                                            <DatePicker
                                                                id="dob"
                                                                selected={validation.values.dob ? new Date(validation.values.dob) : null}
                                                                onChange={(date) => {
                                                                    const formattedDate = date ? moment(date).format("YYYY-MM-DD") : "";
                                                                    validation.setFieldValue("dob", formattedDate);
                                                                    validation.setFieldTouched("dob", true, false);
                                                                }}
                                                                onBlur={() => validation.setFieldTouched("dob", true)}
                                                                dateFormat="yyyy-MM-dd"
                                                                className={`form-control ${validation.touched.dob && validation.errors.dob ? "is-invalid" : ""}`}
                                                                maxDate={new Date()}
                                                                disabled={isLoading}
                                                                placeholderText="(YYYY-MM-DD)"
                                                            />
                                                            {validation.touched.dob && validation.errors.dob && (
                                                                <div className="invalid-feedback d-block">
                                                                    {validation.errors.dob}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Row>
                                                    <Col>
                                                        <div className="mb-2" style={{ position: 'relative', zIndex: 0 }}>
                                                            <Label className="form-label">Password</Label>
                                                            <RequiredAsterisk />
                                                            <div className="input-group">
                                                                <Input
                                                                    name="password"
                                                                    type={isNewPasswordVisible ? "text" : "password"}
                                                                    placeholder="Enter Password"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.password || ""}
                                                                    disabled={isLoading}
                                                                    invalid={validation.touched.password && validation.errors.password ? true : false}
                                                                />
                                                                {isChrome && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn"
                                                                        style={{ border: '1px solid #ced4da' }}
                                                                        onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
                                                                    >
                                                                        {isNewPasswordVisible ? <i className="bx bx-hide"></i> : <i className="bx bx-show"></i>}
                                                                    </button>
                                                                )}
                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.password}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col>
                                                        <div className="mb-2" style={{ position: 'relative', zIndex: 0 }}>
                                                            <Label className="form-label">Confirm Password</Label>
                                                            <RequiredAsterisk />
                                                            <div className="input-group">
                                                                <Input
                                                                    name="confirmPassword"
                                                                    type={isConfirmPasswordVisible ? "text" : "password"}
                                                                    placeholder="Password"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.confirmPassword || ""}
                                                                    disabled={isLoading}
                                                                    invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                                                                />
                                                                {isChrome && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn"
                                                                        style={{ border: '1px solid #ced4da' }}
                                                                        onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                                                    >
                                                                        {isConfirmPasswordVisible ? <i className="bx bx-hide"></i> : <i className="bx bx-show"></i>}
                                                                    </button>
                                                                )}
                                                                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.confirmPassword}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>


                                            </Row>




                                            <div className="mt-2 text-center">
                                                <p>
                                                    <Input
                                                        id="policy"
                                                        name="policy"
                                                        type="checkbox"
                                                        checked={validation.values.policy}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        disabled={isLoading}
                                                        invalid={validation.touched.policy && validation.errors.policy ? true : false}
                                                    />

                                                    {" "}
                                                    Click here to agree to the terms and conditions <br /> of Crescent Exchange{" "}
                                                    <Link to="#" className="text-primary" onClick={toggleModal}>
                                                        Terms of Use
                                                    </Link>
                                                </p>
                                                {validation.touched.policy && validation.errors.policy ? (
                                                    <div className="invalid-feedback h-5" style={{ display: 'block' }}>
                                                        {validation.errors.policy}
                                                    </div>
                                                ) : null}
                                            </div>

                                            {/* Modal for Terms of Use */}
                                            <Modal isOpen={modal} toggle={toggleModal}>
                                                <ModalHeader toggle={toggleModal}>Terms of Use</ModalHeader>
                                                <ModalBody>
                                                    <p className="sub-header">
                                                        1. By using our money-changing and remittance services, you agree to comply with the following terms and conditions.
                                                    </p>
                                                    <p>2. We reserve the right to modify or terminate our services at any time without prior notice.</p>
                                                    <p>3. You are responsible for providing accurate and up-to-date information during the registration and transaction process.</p>
                                                    <p>4. Our services are subject to applicable laws and regulations regarding money-changing and remittance.</p>
                                                    <p>5. The exchange rates provided are based on real-time market conditions and may be subject to fluctuations.</p>
                                                    <p className="mb-0">
                                                        6. We strive to ensure the security and confidentiality of your personal information as outlined in our Privacy Policy.
                                                    </p>
                                                </ModalBody>
                                            </Modal>

                                            <Row>
                                                <div className="mt-2 text-end">
                                                    <button
                                                        type="submit"
                                                        disabled={isLoading}
                                                        className="btn btn-primary"
                                                    >
                                                        {isLoading ? "Registering..." : "Register"}
                                                    </button>
                                                </div>
                                            </Row>

                                            <hr />

                                            <div className="mt-2 text-center">

                                                <h5 className="font-size-14 mb-2">Register with</h5>

                                                <Row className="justify-content-center">

                                                    <Col xs="auto" className="mt-2 mt-sm-0">
                                                        <GoogleLogin
                                                            onSuccess={handleGoogle}
                                                            onerror={handleGoogleError}
                                                        />
                                                    </Col>

                                                    <Col xs="auto" className="mt-2 mt-sm-0">
                                                        <img
                                                            alt="Singpass_Logo.png"
                                                            src={singpassLogo}
                                                            onClick={handleSingpass}
                                                            style={{
                                                                cursor: "pointer",
                                                                width: "200px",
                                                                height: "40px",
                                                                padding: "0px 10px"
                                                            }}
                                                        />
                                                    </Col>

                                                </Row>

                                            </div>

                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-3 text-center">
                                <p>
                                    Already have an account ?{" "}
                                    <Link to="/login" className="font-weight-medium text-primary">
                                        {" "}
                                        <b>
                                            LOGIN
                                        </b>
                                    </Link>{" "}
                                </p>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default withRouter(Register);