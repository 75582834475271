import PropTypes from "prop-types";
import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    FormFeedback,
    Input,
    Label,
    Form,
} from "reactstrap";

//redux
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

//Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "../../../images/profile-img.png";
import logo from "../../../images/crescent_logo.png";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { postApiData } from "../../helpers/axiosHelper";

const ForgetPasswordPage = () => {

    document.title = "Crescent Exchange | Forget Password";

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const validation = useFormik({

        enableReinitialize: true,

        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            const response = await postApiData(
                "api/User/ResetUserPassword",
                JSON.stringify(values)
            );
            if (response.success === true) {
                toast.success("Link has been send to your Communication Email.", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        navigate("/reset-success", { replace: true });
                        setIsLoading(true);
                    }
                });

            } else {
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                });
                setIsLoading(true);
                resetForm();
            }
        },
    });

    return (
        <React.Fragment>
            <div className="home-btn d-block" style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 1000 }}>
                <Link
                    to="/home"
                    className="text-dark"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    <i className="bx bx-home h2" style={{ marginRight: '5px' }} />

                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Reset Password</h5>
                                                <p>Link will be send to your verified email.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/" className="fw-medium text-primary">
                                            <div className="avatar-md profile-user-wid mb-2">
                                                <span className="avatar-title rounded-circle bg-light w-75 h-75 border border-dark  border-2">
                                                    <img
                                                        src={logo}
                                                        alt="crescent.png"
                                                        className="rounded-circle w-75"

                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <ToastContainer closeButton={false} limit={1} />
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">Registered Email</Label>
                                                <RequiredAsterisk />
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="example@gmail.com"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    disabled={isLoading}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                        validation.touched.email && validation.errors.email
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.email}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <Row className="mb-3">
                                                <Col className="text-end">
                                                    <button
                                                        className="btn btn-primary w-md "
                                                        type="submit"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? "Sending.... " : "Send Link"}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Remember Password ? back to {" "}
                                    <Link to="/login" className="font-weight-medium text-primary"
                                    >
                                        <b> LOGIN.</b>
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ForgetPasswordPage.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
