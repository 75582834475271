import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../../images/crescent_logo.png"
import logoLight from "../../../images/logo-light.png";
import logoLightSvg from "../../../images/logo-light.svg";
import logoDark from "../../../images/logo-dark.png";


//i18n
import { withTranslation } from "react-i18next";
import { ProfileProvider } from "../../common/data/context";

const Header = ({ leftMenu, toggleLeftmenu, t }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(leftMenu);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle local state
        toggleLeftmenu(!isMenuOpen); // Update Redux state
    };

    //useEffect(() => {
    //    const handleOutsideClick = (event) => {
    //        const isInsideMenu = event.target.closest("#topnav-menu-content");
    //        const isMenuButton = event.target.closest(".header-item");
    //        if (isMenuOpen && !isInsideMenu && !isMenuButton ) {
    //            handleMenuToggle(); // Close the menu on outside click
    //        }
    //    };
    //    document.addEventListener("mousedown", handleOutsideClick);
    //    return () => {
    //        document.removeEventListener("mousedown", handleOutsideClick);
    //    };
    //}, [isMenuOpen]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const isLeftMenuOpen = leftMenu;
            const isMobileView = window.innerWidth <= 991;
            const isInsideMenu = event.target.closest("#topnav-menu-content");
            const isMenuButton = event.target.closest(".header-item");

            if (isLeftMenuOpen && isMobileView && !isInsideMenu && !isMenuButton) {
                toggleLeftmenu(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [leftMenu]);


    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/dashboard" className="logo logo-dark">
                                <span className="logo-sm rounded-circle">
                                    <img src={logo} alt="crescent.png" height="22" className="rounded-circle" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="name.png" height="18" />
                                </span>
                            </Link>

                            <Link to="/dashboard" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoLightSvg} alt="crescent.png" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLight} alt="name.png" height="19" />
                                </span>
                            </Link>
                        </div>


                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    aria-label="nav-menu"*/}
                        {/*    className="btn btn-sm px-3 font-size-16 d-lg-none header-item"*/}
                        {/*    data-toggle="collapse"*/}
                        {/*    style={{*/}
                        {/*        transition: "transform 0.3s ease",*/}
                        {/*        transform: isMenuOpen ? "rotate(90deg)" : "rotate(0deg)",*/}
                        {/*    }}*/}
                        {/*    onClick={handleMenuToggle}*/}
                        {/*    data-target="#topnav-menu-content"*/}
                        {/*>*/}
                        {/*    <i className={`fa ${isMenuOpen ? "fa-times" : "fa-bars"}`} />*/}
                        {/*</button>*/}
                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                            data-bs-toggle="collapse"
                            data-bs-target="#topnav-menu-content"
                            aria-expanded={leftMenu ? "true" : "false"}
                            aria-controls="topnav-menu-content"
                            onClick={() => toggleLeftmenu(!leftMenu)}
                        >
                            <i
                                className={`fa fa-fw ${leftMenu ? "fa-times" : "fa-bars"
                                    }`}
                            />
                        </button>


                    </div>

                    <div className="d-flex">

                        <ProfileMenu />

                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

Header.propTypes = {
    leftMenu: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
    const { layoutType, showRightSidebar, leftMenu } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
})(withTranslation()(Header));
