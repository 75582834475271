import Swal from "sweetalert2";
import ReactSelect from 'react-select';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Label, Input } from 'reactstrap';
import AVEBeneficiary from "../Beneficiary/AVEBeneficiary";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { SelectStyle } from "../../common/data/SelectStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faEquals, faDivide } from '@fortawesome/free-solid-svg-icons';
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";

const TransferForm = ({ branchID, transferCurrency, baseCurrency, globalTransferData, closeModal }) => {

    //  console.log({ branchID, transferCurrency, baseCurrency, globalTransferData, closeModal });

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [currency, setCurrency] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [modal1, setModal1] = useState(false);
    const toggleAddModal = () => setModal1(!modal1);

    const [mainBranch, setMainBranch] = useState(null);
    const [beneficiary, setBeneficiary] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [beneficiaryError, setBeneficiaryError] = useState("");
    const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

    const [totalSGD, setTotalSGD] = useState(0);

    const fromCurrencyTransfer = baseCurrency
    const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0");
    const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);

    const toCurrencyTransfer = transferCurrency;
    const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0");
    const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const transferData = globalTransferData?.find((x) => {
        return x.currencyCode === toCurrencyTransfer;
    });

    const transferRate = transferData ? parseFloat(transferData.rate) : 0;

    let wireFee = transferData ? parseFloat(transferData.wireTransferFee) : 0;
    let commissionFee = transferData ? parseFloat(transferData.commissionCharges) : 0;
    let agentFee = transferData ? parseFloat(transferData.agentCharges) : 0;


    const fetchCurrencies = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData("api/Currency/GetAllCurrencies");
            if (response.success) {
                const mappedResponse = response.data.map((item, index) => ({
                    index: index + 1,
                    currencyCode: item.currencyCode,

                    currencyId: item.currencyId,
                    currencyName: item.currencyName,

                }));
                setCurrency(mappedResponse);
                setCurrencies(response.data);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const getCurrencyFlag = (currencyCode) => {
        const currency = currencies.find(c => c.currencyCode === currencyCode);
        return currency ? `data:image/png;base64,${currency.currencyFlag}` : '';
    };

    const getAvaBeneficiary = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData(`api/CustomerBeneficiary/GetAllBeneficiaryByCustomer`);
            const filteredResponse = response.data.filter(item => item.status === 2 && item.active === 1);
            const mappedResponse = filteredResponse.map(item => ({
                label: item.beneficiaryName,
                value: item.custBenId,
                currencyName: item.currencyName.split('(')[1]?.replace(')', '') || "",
            }));
            console.log(mappedResponse)
            const transCurrency = mappedResponse.filter(cc => cc.currencyName === transferCurrency);
            setBeneficiary(transCurrency);
            setIsLoading(false);
        } catch (error) {
            console.log("Error fetching beneficiaries", error);
        }
    };


    const convertFromCurrency = (e) => {
        const inputValue = e.target.value;

        setFromCurrencyDisplay(inputValue);

        if (inputValue.trim() === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({ fromCurrency: "", toCurrency: "" });
            return;
        }

        const amount = parseFloat(inputValue);

        if (isNaN(amount) || amount <= 0) {
            setErrors({ fromCurrency: "Amount must be greater than 0." });
            return;
        }

        if (inputValue.length > 10) return;

        const totalCharges = wireFee + commissionFee + agentFee;
        const subSGD = amount - totalCharges;

        if (subSGD <= 10) {
            setErrors({ fromCurrency: `${fromCurrencyTransfer} must be greater than 10.` });
            return;
        }

        const convertedToValue = subSGD / transferRate;

        if (convertedToValue < 10) {
            setErrors({
                fromCurrency: "",
                toCurrency: "Converted value must be at least 10.",
            });
            return;
        }

        setErrors({ fromCurrency: "", toCurrency: "" });
        setFromCurrencyValueTransfer(amount);
        setToCurrencyValueTransfer(convertedToValue);
        setToCurrencyDisplay(convertedToValue.toFixed(2));
        setTotalSGD(subSGD.toFixed(2));
    };

    const convertToCurrency = (e) => {
        const inputValue = e.target.value;

        setToCurrencyDisplay(inputValue);

        if (inputValue.trim() === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({ fromCurrency: "", toCurrency: "" });
            return;
        }

        const amount = parseFloat(inputValue);

        if (isNaN(amount) || amount <= 0) {
            setErrors({ toCurrency: "Amount must be greater than 0." });
            return;
        }

        if (inputValue.length > 10) return;

        const convertedSGD = amount * transferRate;
        const totalCharges = wireFee + commissionFee + agentFee || 0;
        const convertedFromValue = convertedSGD + totalCharges;

        if (amount <= 10) {
            setErrors({ toCurrency: "Amount must be greater than 10." });
            return;
        }

        setErrors({ fromCurrency: "", toCurrency: "" });
        setToCurrencyValueTransfer(amount);
        setFromCurrencyValueTransfer(convertedFromValue);
        setFromCurrencyDisplay(convertedFromValue.toFixed(2));
        setTotalSGD(convertedSGD.toFixed(2));
    };


    const getMainBranch = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData("api/BranchMaster/GetMainBranch");
            if (response.success === true) {
                const mapMainBranch = response.data.map(branch => ({
                    branchId: branch.branchId,
                    branchName: branch.branchName,
                }));
                setMainBranch(mapMainBranch[0]);
            } else {
                setMainBranch(null);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error occuried", error)
        }

    };

    const handleNumericInput = (e) => {
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Enter"
        ];

        const inputValue = e.target.value;

        if (allowedKeys.includes(e.key)) return;

        if (e.key === ".") {
            if (inputValue.includes(".")) {
                e.preventDefault();
                return;
            }
        } else if (!/^\d$/.test(e.key)) {
            e.preventDefault();
            return;
        }

        if (inputValue.length >= 10) {
            e.preventDefault();
        }
    };

    const handlePrecision = (value) => {
        return Math.round((value + Number.EPSILON) * 1000000000) / 1000000000;
    };

    const handleSubmitTransfer = async (e) => {
        e.preventDefault();

        if (!selectedBeneficiary) {
            setBeneficiaryError("Please create a beneficiary.");
            return;
        }

        if (fromCurrencyValueTransfer <= 0) {
            setErrors({
                fromCurrency: "Value cannot be empty.",
                toCurrency: "Value cannot be empty.",
            });
            return;
        }

        const fromRateHistory = globalTransferData?.find(
            (x) => x.currencyCode === fromCurrencyTransfer
        );

        const toRateHistory = globalTransferData?.find(
            (x) => x.currencyCode === toCurrencyTransfer
        );

        const adjustedBranchId = branchID === 0 ? mainBranch?.branchId : branchID;

        const TransferData = {
            toCustBenId: selectedBeneficiary?.value,

            fromCurrType: fromCurrencyTransfer,
            fromCurrValue: handlePrecision(fromCurrencyValueTransfer),

            toCurrType: toCurrencyTransfer,
            toCurrValue: handlePrecision(toCurrencyValueTransfer),

            exchangeRate: transferRate.toFixed(10),

            fromBranchId: adjustedBranchId,
            fromRateHistoryId: fromRateHistory?.rateHistoryId,
            toRateHistoryId: toRateHistory?.rateHistoryId,
            transactionType: 3,
        };

        try {
            //console.log(TransferData);
            //return;
            setIsLoading(true);
            const response = await postApiData(
                "api/Transaction/InsertTransaction",
                TransferData
            );
            if (response.success === true) {
                toast.success("Transaction request has been completed", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/profile");
                    } else {
                        closeModal();
                    }
                });
            }
            closeModal();
            setIsLoading(false)
        } catch (error) {
            console.error("Error during transaction", error);
        }
    };

    const openADDBeneficiary = () => {
        toggleAddModal();
        setSearchTerm("");
    };

    const filteredBeneficiaries = beneficiary.filter(b =>
        b.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!filteredBeneficiaries.length && typedBeneficiaryName) {
        filteredBeneficiaries.push({
            label: `Create New Beneficiary "${typedBeneficiaryName}"`,
            value: "create-new",
        });
    }

    const getDocument = async (custBenId) => {
        const docResponse = await getApiData(`api/Customerbeneficiary/GetBeneficiaryDocByCustBenId?CustBenId=${custBenId}`);
        const document = await docResponse.data;
        setDocumentData(document)
    }

    useEffect(() => {
        getMainBranch();
        fetchCurrencies();
        getAvaBeneficiary();
    }, []);

    return (
        <>
            <AVEBeneficiary
                editMode={3}
                isOpen={modal1}
                currencies={currency}
                toggle={toggleAddModal}
                createBenFor={toCurrencyTransfer}
                getAvaBeneficiary={getAvaBeneficiary}
                getDocument={getDocument}
                documentData={documentData}
            />

            <Form onSubmit={handleSubmitTransfer}>

                <ToastContainer limit={1} />
                {isLoading
                    ? <Loader />
                    :
                    <>

                        <Row className="align-items-center">
                            <Col xs={10} md={10}>
                                <div className="mb-2">
                                    <Label>Select Beneficiary</Label>
                                    <RequiredAsterisk />
                                    <ReactSelect
                                        styles={SelectStyle}
                                        value={selectedBeneficiary}
                                        onInputChange={(inputValue) => {
                                            setSearchTerm(inputValue);
                                            setTypedBeneficiaryName(inputValue);
                                        }}
                                        onChange={(selectedOption) => {
                                            if (selectedOption?.value === "create-new") {
                                                openADDBeneficiary();
                                            } else {
                                                setErrors((prevErrors) => ({
                                                    ...prevErrors,
                                                    fromCurrency: "",
                                                    toCurrency: "",
                                                }));
                                                setBeneficiaryError("");
                                                setSelectedBeneficiary(selectedOption);
                                            }
                                        }}
                                        options={beneficiary.map((option) => ({
                                            value: option.value,
                                            label: option.label,
                                            currencyName: option.currencyName,
                                            icon: getCurrencyFlag(option.currencyName),
                                        }))}
                                        formatOptionLabel={({ label, icon, currencyName }) => (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <span>{label}</span>
                                                <img
                                                    src={icon}
                                                    alt="flag"
                                                    style={{ width: 20, height: 20, marginLeft: 8 }}
                                                />
                                                <span style={{ marginLeft: 5, fontSize: 12, }}>
                                                    ( {currencyName})
                                                </span>

                                            </div>
                                        )}
                                        isClearable
                                    />



                                </div>
                            </Col>

                            <Col xs={2} md={2} className="d-flex justify-content-start mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={openADDBeneficiary}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>You Give</Label>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        background: "#F8F8F8",
                                        padding: "2px",
                                    }}
                                >
                                    <img
                                        src={getCurrencyFlag(fromCurrencyTransfer)}
                                        alt=""
                                    />
                                    <Input
                                        value={fromCurrencyTransfer}
                                        disabled
                                        style={{ border: "none" }}
                                    />
                                </div>

                                {errors.amount && <div className="text-danger">{errors.amount}</div>}
                            </Col>

                            <Col md={6}>
                                <Label>{fromCurrencyTransfer} Amount </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    value={fromCurrencyDisplay}
                                    onKeyDown={handleNumericInput}
                                    onChange={convertFromCurrency}
                                    onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                />
                                {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                            </Col>

                        </Row>


                        {wireFee > 0 && (
                            <Row className="mb-3 text-end">
                                <Col lg={6} className="text-start">
                                    <span>Wire Transfer Fee</span>
                                </Col>
                                <Col lg={6}>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} /> {wireFee.toFixed(2)}{" "}  {fromCurrencyTransfer}
                                    </span>
                                </Col>
                            </Row>
                        )}

                        {commissionFee > 0 && (
                            <Row className="mb-3 text-end">
                                <Col lg={6} className="text-start">
                                    <span>Commission Fee</span>
                                </Col>
                                <Col lg={6}>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} /> {commissionFee.toFixed(2)}{" "}  {fromCurrencyTransfer}
                                    </span>
                                </Col>
                            </Row>
                        )}

                        {agentFee > 0 && (
                            <Row className="mb-3 text-end">
                                <Col lg={6} className="text-start">
                                    <span>Agent Fee</span>
                                </Col>
                                <Col lg={6}>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} /> {agentFee.toFixed(2)}{" "}  {fromCurrencyTransfer}
                                    </span>
                                </Col>
                            </Row>
                        )}

                        <hr />

                        <Row className="mb-3 text-end">
                            <Col lg={6} className="text-start">
                                <span>Total {fromCurrencyTransfer || "N/A"}</span>
                            </Col>
                            <Col lg={6}>
                                <span>
                                    <FontAwesomeIcon icon={faEquals} />
                                    {" "}
                                    {totalSGD || 0} {fromCurrencyTransfer}
                                </span>
                            </Col>
                        </Row>

                        <Row className="mb-3 text-end">
                            <Col lg={6} className="text-start">
                                <span>Transfer Rate</span>
                            </Col>
                            <Col lg={6}>
                                <span>
                                    <FontAwesomeIcon icon={faDivide} className="me-1" />
                                    {transferRate.toFixed(4)}
                                </span>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Label>Beneficiary Receives</Label>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        background: "#F8F8F8",
                                        padding: "2px",
                                    }}
                                >
                                    <img
                                        src={getCurrencyFlag(toCurrencyTransfer)}
                                        alt={`${toCurrencyTransfer} flag`}
                                    />
                                    <Input
                                        value={toCurrencyTransfer}
                                        disabled
                                        style={{ border: "none" }}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <Label>{toCurrencyTransfer} Amount </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    value={toCurrencyDisplay}
                                    onKeyDown={handleNumericInput}
                                    onChange={convertToCurrency}
                                    onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
                                />
                                {errors.toCurrency && (
                                    <div className="text-danger">{errors.toCurrency}</div>
                                )}
                            </Col>
                        </Row>

                    </>
                }

                <div className="text-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                            isLoading ||
                            Object.values(errors).some((error) => error !== "")
                        }
                    >
                        {isLoading ? "Loading..." : "Submit"}
                    </button>
                </div>

            </Form>

        </>
    );
};

export default TransferForm;


