import { Link } from "react-router-dom";
import logo from "../../../images/crescent_logo.png"
import logoDark from "../../../images/logo-dark.png";
import { FaExclamationTriangle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import logoLight from "../../../images/logo-light.png";
import logoLightSvg from "../../../images/logo-light.svg";
import axios, { getApiData } from "../../helpers/axiosHelper";
import { useSearchParams, useNavigate } from "react-router-dom";
import { BaseRegisterContext } from "../../common/data/context";
import React, { useContext, useEffect, useState, useRef } from "react";

const Callback = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setSingPassData } = useContext(BaseRegisterContext);

    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const isPage = sessionStorage.getItem("isPage");
    const singpass = sessionStorage.getItem("singpass");

    const isCallbackHandled = useRef(false);
    const [isDataFetched, setIsDataFetched] = useState(null);

    useEffect(() => {
        const callback = async () => {
            if (!code || !state) {
                console.error("Missing required parameters: code or state");
                return;
            }
            if (isCallbackHandled.current) {
                return;
            }
            isCallbackHandled.current = true;

            try {
                const response = await getApiData(`api/SingPass/callback?code=${code}&state=${state}&singpass=${singpass}&isPage=${isPage}`);
                console.log("API Response:", response);

                if (response) {
                    setIsDataFetched("LogIn");
                    sessionStorage.removeItem("singpass");
                    if (response?.data?.navigateUrl === "/dashboard") {
                        const logged_user = {
                            login: true,
                            uid: response.data.uid,
                            name: response.data.name,
                            token: response.data.token,
                            email: response.data.email,
                            authType: response.data.authType
                        };

                        const expirValidation = {
                            uid: response.data.uid,
                            expiration: response.data.expiration,
                        };

                        axios.defaults.headers.common["Authorization"] = "Bearer " + response.token;
                        sessionStorage.setItem("expirValidation", JSON.stringify(expirValidation));
                        sessionStorage.setItem("forceLogout", "false");
                        sessionStorage.setItem("custUser", JSON.stringify(logged_user));

                        toast.success("Login Successful", {
                            position: "top-right",
                            autoClose: 1500,
                            onClose: () => {
                                navigate(response.data.navigateUrl);
                            },
                        });
                    } else if (response?.message === "User verification required.") {
                        toast.warning("User verification Required", {
                            position: "top-right",
                            autoClose: 1500,
                            onClose: () => {
                                navigate(response.data.navigateUrl);
                            },
                        });
                    } else if (response.uinFin) {
                        setIsDataFetched(true);
                        setSingPassData(response);

                        sessionStorage.setItem("RegisterSingpassValues", JSON.stringify(response));

                        toast.success("User Data Fetched Successfully", {
                            position: "top-right",
                            autoClose: 1500,
                            onClose: () => {
                                navigate("/register");
                            },
                        });
                    } else {
                        setIsDataFetched(false);
                        toast.warning(response.message, {
                            position: "top-right",
                            autoClose: 1500,
                        });
                    }
                } else {
                    setIsDataFetched(false);
                    toast.warning("Failed to fetch user details", {
                        position: "top-right",
                        autoClose: 1500,
                    });
                }
            } catch (error) {
                console.error("Error during callback:", error);
                navigate("/error");
            }
        };

        callback();
    }, [code, state, singpass, navigate, setSingPassData, isPage]);

    return (
        <>
            <ToastContainer closeButton={false} limit={1} />

            <div >
                <header style={{ background: "#007bff" }}>
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/dashboard" className="logo logo-dark">
                                    <span className="logo-sm rounded-circle">
                                        <img src={logo} alt="crescent.png" height="22" className="rounded-circle" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="name.png" height="18" />
                                    </span>
                                </Link>

                                <Link to="/dashboard" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoLightSvg} alt="crescent.png" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="name.png" height="19" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            <div style={{ textAlign: "center", padding: "20px" }}>

                {isDataFetched === null && (
                    <div style={styles.container}>
                        <div style={styles.iconWrapper}>
                        </div>
                        <p style={styles.text}>
                            <i className="fas fa-spinner fa-spin" style={styles.spinnerIcon}></i>
                            Callback processing...
                        </p>
                    </div>
                )}

                {isDataFetched === "LogIn" && (
                    <div style={styles.container}>
                        <div style={styles.iconWrapper}>
                        </div>
                        <p style={styles.text}>
                            Logged In Successfully...
                        </p>
                    </div>
                )}

                {isDataFetched === false && (
                    <div style={styles.container}>
                        <div style={styles.iconWrapper}>

                            <div style={styles.warningText}>
                                <FaExclamationTriangle size={50} color="orange" />
                                <p style={{ color: "orange", fontSize: "18px", fontWeight: "bold" }}>
                                    Failed to fetch user details
                                </p>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <button
                                    onClick={() => navigate("/login")}
                                    style={{
                                        marginRight: "10px",
                                        padding: "10px 20px",
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    Login
                                </button>

                                <button
                                    onClick={() => navigate("/register")}
                                    style={{
                                        padding: "10px 20px",
                                        backgroundColor: "#28a745",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    Register
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {isDataFetched === true && (
                    <div style={styles.container}>
                        <p style={styles.successText}>User data is successfully fetched. Please wait...</p>
                    </div>
                )}

            </div>

        </>
    );
};

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#f9f9f9",
    },
    iconWrapper: {
        marginBottom: "10px",
    },
    spinnerIcon: {
        fontSize: "40px",
        color: "#007bff",
    },
    text: {
        fontSize: "18px",
        fontWeight: "500",
        color: "#007bff",
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    infoIcon: {
        fontSize: "20px",
    },
    successText: {
        fontSize: "18px",
        fontWeight: "500",
        color: "green",
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    warningText: {
        fontSize: "18px",
        fontWeight: "500",
        color: "orange",
        alignItems: "center",
        gap: "10px",
    }
};

export default Callback;
