import "./js/i18n";
import React from 'react';
import App from './js/setup';
import store from "./js/store";
import { Provider } from "react-redux";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CurrencyProvider, ImageProvider, ProfileProvider,RegisterProvider } from "./js/common/data/context";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <React.Fragment>
            <BrowserRouter>
                <GoogleOAuthProvider clientId="483164320992-qbqbeqv8fthaa429lqaur63sbd009v2g.apps.googleusercontent.com">
                    <ProfileProvider>
                        <CurrencyProvider>
                            <ImageProvider>
                                <RegisterProvider>
                                <App />
                                </RegisterProvider>
                            </ImageProvider>

                          
                        </CurrencyProvider>
                    </ProfileProvider>
                </GoogleOAuthProvider>
            </BrowserRouter>
        </React.Fragment>
    </Provider>
);


