import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";

//Toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// actions
import { useFormik } from "formik";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
} from "reactstrap";

// import images
import profile from "../../../images/profile-img.png";
import logo from "../../../images/crescent_logo.png";
import withRouter from "../../components/Common/withRouter";
import { getApiData, putApiData } from "../../helpers/axiosHelper";

const ResetPassword = () => {

    document.title = "Reset Password | Crescent Exchange";

    const navigate = useNavigate();
    //const token = props.router.params.token;
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);


    const isChrome = (() => {
        const isChromium = !!window.chrome;
        const isGoogle = /Google Inc/.test(navigator.vendor);
        const isNotEdge = !/Edg/.test(navigator.userAgent);
        const isNotOpera = !/OPR/.test(navigator.userAgent);
        return isChromium && isGoogle && isNotEdge && isNotOpera;
    })();
    let token = ""

    function Search() {
        const location = useLocation();
        const queryString = new URLSearchParams(location.search);
        const searchTerm = queryString.get('token');
        return searchTerm;
    }

    token = Search();

    const verifyToken = async () => {
        try {
            const response = await getApiData(`api/User/VerifyResetPasswordToken?token=${token}`);
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        verifyToken();
    }, []);


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            password: "",
            token: token,
        },
        validationSchema: Yup.object({
              
            password: Yup.string().required("Please Enter Your Password")
                .min(8, 'Password must be 8 characters long')
                .matches(/[^\w]/, 'Password requires a symbol')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter')            ,

            password_confirmation: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Confirm password is required")
                .min(8, 'Password must be 8 characters long')
                .matches(/[^\w]/, 'Password requires a symbol')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter')            ,
        }),
        onSubmit: async (values, { resetForm }) => {
            const { password_confirmation, ...apiValues } = values;
            const response = await putApiData(
                "api/User/UpdateUserPassword",
                JSON.stringify(apiValues)
            );
            if (response.success === true) {
                setIsLoading(true);
                toast.success("Password Changed Successfully.", {
                    position: "top-right",
                    autoClose: 3000,
                    onClose: () => {
                        navigate("/login", { replace: true });
                        setIsLoading(false);
                    }
                });

            } else {
                setIsLoading(true);
                toast.warning("Somthing went wrong please try again.", {
                    position: "top-right",
                    autoClose: 3000,
                });
                resetForm()
                setIsLoading(false);
            }

        },
    });

    useEffect(() => {
        console.log(isLoading)
    }, [isLoading]);

    return (
        <React.Fragment>
            <div className="account-pages my-3 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary"> Reset Password</h5>
                                                <p>You're about to change your account password.</p>
                                            </div>
                                        </Col>
                                        <Col xs={5} className="align-self-end">
                                            <img src={profile} alt="banner.png" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/" >
                                            <div className="avatar-md profile-user-wid mb-2">
                                                <span className="avatar-title rounded-circle bg-light w-75 h-75 border border-dark  border-2">
                                                    <img
                                                        src={logo}
                                                        alt="crescent.png"
                                                        className="rounded-circle w-75"

                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="p-2">

                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >

                                            <div className="mb-3">
                                                <Label className="form-label">New Password</Label>
                                                <span className="text-danger">*</span>
                                                <div className="input-group">
                                                <Input
                                                    name="password"
                                                        type={isNewPasswordVisible ? "text" : "password"}
                                                    placeholder="Enter New Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    disabled={isLoading}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password &&
                                                            validation.errors.password
                                                            ? true
                                                            : false
                                                    }
                                                    />
                                                    {isChrome && (
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            style={{ border: '1px solid #ced4da' }}
                                                            onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
                                                        >
                                                            {isNewPasswordVisible ? (
                                                                <i className="bx bx-hide"></i>
                                                            ) : (
                                                                <i className="bx bx-show"></i>
                                                            )}
                                                        </button>
                                                    )}
                                                {validation.touched.password &&
                                                    validation.errors.password ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.password}
                                                    </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="mb-2">
                                                <Label htmlFor="confirmPassword" className="form-label">
                                                    Confirm Password{" "}
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <div className="input-group">
                                                <Input
                                                    name="password_confirmation"
                                                    type={isConfirmPasswordVisible ? "text" : "password"}
                                                    placeholder="Confirm Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password_confirmation || ""}
                                                    disabled={isLoading}
                                                    invalid={
                                                        validation.touched.password_confirmation &&
                                                            validation.errors.password_confirmation
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {isChrome && (
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        style={{ border: '1px solid #ced4da' }}
                                                        onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                                    >
                                                        {isConfirmPasswordVisible ? (
                                                            <i className="bx bx-hide"></i>
                                                        ) : (
                                                            <i className="bx bx-show"></i>
                                                        )}
                                                    </button>
                                                )}

                                                {validation.touched.password_confirmation &&
                                                    validation.errors.password_confirmation ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.password_confirmation}</div>
                                                    </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="text-end">
                                                <button
                                                    className="btn btn-primary w-md "
                                                    type="submit"
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? "Changing.... " : "Change"}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Remember password ? Go back to {" "}
                                    <Link to="/login" className="fw-medium text-primary">
                                        {" "}
                                        <b>
                                            LOGIN
                                        </b>
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(ResetPassword);
