import moment from "moment";
import Swal from "sweetalert2";
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Col, Form, Input, Label, Row } from 'reactstrap';
import { SelectStyle } from "../../common/data/SelectStyle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { faDivide, faEquals, faMinus } from '@fortawesome/free-solid-svg-icons';

const BuyForm = ({ branchID, buyingCurrency, rateType, rate, buyCharge, rateHisID, closeModal, }) => {

    // console.log({ branchID, isAllBranch, buyingCurrency, rateType, rate, buyCharge, rateHisID, });

    const navigate = useNavigate();

    const fromCurrencyBuying = "SGD";
    const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0");
    const [fromCurrencyValueBuying, setFromCurrencyValueBuying] = useState(0);

    const toCurrencyBuying = buyingCurrency;
    const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0");
    const [toCurrencyValueBuying, setToCurrencyValueBuying] = useState(0);

    const [branchOptions, setBranchOptions] = useState([]);
    const [branchDetails, setBranchDetails] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);

    const currentDate = new Date();
    const [optime, setOptime] = useState({});
    const [startDate, setStartDate] = useState(null);

    const [errors, setErrors] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchCurrencies = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData('api/Currency/GetAllCurrencies');
            if (response.success === true) {
                setCurrencies(response.data);
            } else {
                setCurrencies(response.data);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const validateDate = (selectedDate) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const selectedDateObj = new Date(selectedDate);

        if (!selectedDate) {
            setErrors({ date: 'Date is required' });
        } else if (selectedDateObj < currentDate) {
            setErrors({ date: 'You cannot select a past date' });
        } else {
            setErrors({});
        }
    };

    const buyBranchID = () => {
        if (branchID === 0) {
            return selectedBranch?.value
        } else {
            return branchDetails?.value
        }
    }

    const handlePrecision = (value) => {
        return Math.round((value + Number.EPSILON) * 1000000000) / 1000000000;
    };

    const handleSubmitBuying = async (e) => {
        e.preventDefault();

        if (branchID === 0 && selectedBranch === null) {
            setErrors({
                branch: "Please select a branch.",
            });
            return;
        };

        if (!startDate) {
            toast.info("Please select a pickup date and time.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        if (!fromCurrencyValueBuying && !toCurrencyValueBuying) {
            setErrors({
                fromCurrency: "Enter the amount you wish to Buy.",
                toCurrency: "Enter the amount you wish to Buy.",
            });
            return;
        };

        const formattedDate = startDate ? new Date(startDate).toISOString() : "";


        const BuyingData = {

            fromCurrType: fromCurrencyBuying,
            fromCurrValue: handlePrecision(fromCurrencyValueBuying),

            toCurrType: toCurrencyBuying,
            toCurrValue: handlePrecision(toCurrencyValueBuying),

            transactionType: 2,
            toRateHistoryId: rateHisID,
            exchangeRate: rate,

            fromBranchId: buyBranchID(),
            pickup: formattedDate,
        };

        try {
            //console.log(BuyingData);
            //return;
            setIsLoading(true);
            const response = await postApiData('api/Transaction/InsertTransaction', BuyingData);

            if (response.success) {
                toast.success("Transaction completed successfully.", {
                    position: "top-right",
                    autoClose: 1500,
                });
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile",
                    icon: "info",
                    showConfirmButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/profile");
                    } else {
                        closeModal();
                    }
                });
            }
            closeModal();
            resetTransaction();
            setIsLoading(false);
        } catch (error) {
            console.error("Error during transaction", error);
            resetTransaction();
        }
    };

    const fetchBranches = async () => {
        try {
            setIsLoading(true);
            let response;

            if (branchID === 0) {
                response = await getApiData(
                    `api/BranchMaster/GetBranchesWithCurrencyStock?currencyCode=${toCurrencyBuying}`
                );
                const branches = response.data || [];
                handleAllBranch(branches);
            } else {
                response = await getApiData(
                    `api/RateMaster/GetBranchStockByCurrency?branchId=${branchID}&currencyCode=${toCurrencyBuying}&rateType=${rateType}`
                );
                const branches = response.data || [];
                handleSingleBranch(branches);
            }

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching branches", error);
        }
    };

    const handleAllBranch = (branches) => {
        const filteredBranches = branches
            .filter((entry) => entry.branch && entry.stock !== null)
            .map((entry) => ({
                value: entry.branch.branchId,
                label: entry.branch.branchName,
                address: `${entry.branch.street}, ${entry.branch.city}, ${entry.branch.postalCode}`,
                stock: entry.stock,
            }));

        setBranchOptions(filteredBranches);
    };

    const handleSingleBranch = (branches) => {
        const filteredBranch = branches.find(
            (branch) => branch.active && branch.allBranch === 0
        );

        if (filteredBranch) {
            const branchDetails = {
                value: filteredBranch.branchId,
                label: filteredBranch.branchName,
                address: `${filteredBranch.street}, ${filteredBranch.city}, ${filteredBranch.postalcode}`,
                availableAmount: filteredBranch.availableAmount,
            };

            setBranchDetails(branchDetails);
            getBranchOpTime(branchDetails?.value);
        }
    };

    const convertTo12HourFormat = (time) => {
        const [hour, minute] = time.split(":");
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute} ${ampm}`;
    };

    const getBranchOpTime = async (branch) => {
        try {
            setIsLoading(true);
            const branchResponse = await getApiData(`api/BranchMaster/GetBranchWithOperation?branchId=${branch}`);
            const operationalTime = branchResponse.data.operationalTime[0];
            if (operationalTime) {
                setOptime({
                    startTime: convertTo12HourFormat(operationalTime.startTime),
                    endTime: convertTo12HourFormat(operationalTime.endTime),
                });
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching branch operational time", error);
        }
    };

    const handleNumericInput = (e) => {
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Enter"
        ];

        const inputValue = e.target.value;

        if (allowedKeys.includes(e.key)) return;

        if (e.key === ".") {
            if (inputValue.includes(".")) {
                e.preventDefault();
                return;
            }
        } else if (!/^\d$/.test(e.key)) {
            e.preventDefault();
            return;
        }

        if (inputValue.length >= 10) {
            e.preventDefault();
        }
    };


    const convertFromCurrency = (e) => {
        const inputValue = e.target.value;

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueBuying("");
            setToCurrencyValueBuying("");
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: ""
            }));
            return;
        }

        if (inputValue.length > 20) return;

        const amount = parseFloat(inputValue) || 0;
        const exchangeRate = parseFloat(rate) > 0 ? parseFloat(rate) : 0;
        const charges = parseFloat(buyCharge) > 0 ? parseFloat(buyCharge) : 0;;

        setFromCurrencyValueBuying(amount);

        if (amount <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD cannot be 0 or less tham 0.",
                toCurrency: ""
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (amount <= 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than 1.",
                toCurrency: ""
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (branchID !== 0) {
            if ((amount - charges) / rate >= branchDetails?.availableAmount) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "",
                    toCurrency: `${toCurrencyBuying} must not be greater than available stock!!!`
                }));
                return;
            }
        } else {
            if ((amount - charges) / rate >= selectedBranch?.stock) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "",
                    toCurrency: `${toCurrencyBuying} must not be greater than available stock.`
                }));
                return;
            }
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than the buying fee.",
                toCurrency: "",
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
        }

        const convertedToCurrency = (amount - charges) / exchangeRate;

        if (convertedToCurrency <= 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Converted amount must be greater than 1."
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (convertedToCurrency.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Amount cannot exceed 10 digits."
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
        }));

        setToCurrencyValueBuying(
            convertedToCurrency > 0 ? convertedToCurrency : 0
        );
        setToCurrencyDisplay(convertedToCurrency.toFixed(2));
    };

    const convertToCurrency = (e) => {
        const inputValue = e.target.value;

        setToCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueBuying("");
            setToCurrencyValueBuying("");
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: ""
            }));
            return;
        }

        const amount = parseFloat(inputValue) || 0;
        const exchangeRate = parseFloat(rate) > 0 ? parseFloat(rate) : 0;
        const charges = parseFloat(buyCharge) > 0 ? parseFloat(buyCharge) : 0;

        setToCurrencyValueBuying(amount);

        if (amount <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `${toCurrencyBuying}  must be greater than 10.`
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (branchID !== 0) {
            if (amount >= branchDetails?.availableAmount) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "",
                    toCurrency: `${toCurrencyBuying} must not be greater than available stock!!!`
                }));
                return;
            }
        } else {
            if (amount >= selectedBranch?.stock) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fromCurrency: "",
                    toCurrency: `${toCurrencyBuying} must not be greater than available stock.`
                }));
                return;
            }
        }

        if ((amount * rate) <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than the buying fee.",
                toCurrency: ""
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        const convertedFromCurrency = (amount * exchangeRate) + charges;

        if (convertedFromCurrency <= 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Converted SGD must be greater than 1.",
                toCurrency: ""
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (convertedFromCurrency.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount cannot exceed 10 digits",
                toCurrency: ""
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
        }));

        setFromCurrencyDisplay(convertedFromCurrency.toFixed(2));

        setFromCurrencyValueBuying(
            convertedFromCurrency > 0 ? convertedFromCurrency : 0
        );
    };


    const getCurrencyFlag = (currencyCode) => {
        const currency = currencies.find(c => c.currencyCode === currencyCode);
        return currency ? `data:image/png;base64,${currency.currencyFlag}` : '';
    };

    const resetTransaction = () => {
        setSelectedBranch(null);
        setFromCurrencyDisplay("0");
        setToCurrencyDisplay("0");
        setFromCurrencyValueBuying(0);
        setToCurrencyValueBuying(0);
    };

    useEffect(() => {
        if (branchID !== null && toCurrencyBuying !== null && rateType != null) {
            fetchBranches();
        }
        fetchCurrencies();
    }, [branchID, toCurrencyBuying, rateType]);

    return (

        <Form onSubmit={handleSubmitBuying}>

            <ToastContainer limit={1} />

            {isLoading
                ?
                <Loader />
                :
                <>
                    <Row>
                        <Col md={6}>
                            <Label>You Give</Label>
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #000', background: '#F8F8F8', padding: '2px' }}>
                                <img
                                    src={getCurrencyFlag(fromCurrencyBuying)}
                                    alt={`${fromCurrencyBuying} flag`}
                                    style={{ width: '30px', height: '30px', marginRight: '5px' }}
                                />
                                <Input value={fromCurrencyBuying} disabled style={{ border: 'none', fontWeight: '600', fontSize: '1rem' }} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <Label>You Get</Label>
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #000', background: '#F8F8F8', padding: '2px' }}>
                                <img
                                    src={getCurrencyFlag(toCurrencyBuying)}
                                    alt={`${toCurrencyBuying} flag`}
                                    style={{ width: '30px', height: '30px', marginRight: '5px' }}
                                />
                                <Input value={toCurrencyBuying} disabled style={{ border: 'none', fontWeight: '600', fontSize: '1rem' }} />
                            </div>
                        </Col>
                    </Row>

                    <div className="mt-2 mb-2">
                        <Label>Branch</Label>
                        {branchID === 0
                            ?
                            <ReactSelect
                                styles={SelectStyle}
                                options={branchOptions}
                                value={selectedBranch}
                                onChange={(option) => {
                                    setSelectedBranch(option);
                                    getBranchOpTime(option?.value);
                                    setErrors({ branch: "" });
                                }}
                                placeholder="Select Branch"
                                getOptionLabel={(option) => (
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <span>{option.label}</span>
                                        <span style={{ fontSize: "0.9em" }}>
                                            {option.stock !== null ? `Stock: ${option.stock}` : "No Stock"}
                                        </span>
                                    </div>
                                )}
                            />
                            :
                            <div
                                style={{
                                    padding: '10px',
                                    border: '1px solid #000',
                                    cursor: 'pointer',
                                    marginBottom: '5px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <strong>{branchDetails?.label}</strong>
                                    <div style={{ fontSize: '0.9em', color: '#888' }}>{branchDetails?.address}</div>
                                </div>
                                Max: {branchDetails?.availableAmount} {" "} {toCurrencyBuying}
                            </div>}
                    </div>
                    {errors.branch && <div className="text-danger">{errors.branch}</div>}

                    <Row className="mb-2">
                        <Col md={12}>
                            <Label>
                                Pick Currency by{" "}
                                {optime?.startTime && optime?.endTime ? (
                                    `${optime.startTime} - ${optime.endTime}`
                                ) : (
                                    branchID === 0
                                        ? <span style={{ fontSize: "0.8em", color: "#888" }}>
                                            Select a branch to view branch availablity time
                                        </span>
                                        : <span style={{ fontSize: "0.8em", color: "#888" }}>
                                            No Branch Specific Time available
                                        </span>
                                )}
                            </Label>
                            <div style={{ border: "1px solid black" }}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        validateDate(date);
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm aa"
                                    timeIntervals={30}
                                    className="form-control"
                                    minTime={optime?.startTime}
                                    maxTime={optime?.endTime}
                                    dateFormat="yyyy/MM/dd - h:mm aa"
                                    placeholderText="Select your Pickup Date and Time"
                                />
                            </div>
                            {errors.date && <div className="text-danger">{errors.date}</div>}
                        </Col>
                    </Row>

                    <div className="mb-2">
                        <Label>{fromCurrencyBuying} Amount</Label>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter Amount"
                            value={fromCurrencyDisplay}
                            onChange={convertFromCurrency}
                            onKeyDown={handleNumericInput}
                            onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                        />
                        {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}

                    </div>

                    {parseFloat(buyCharge) !== 0 &&
                        buyCharge !== "" &&
                        <>

                            <Row className="mb-2 text-end">
                                <Col lg={6} className="text-start">
                                    <span>Agent Charges</span>
                                </Col>
                                <Col lg={6}>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} className="me-1" />
                                        {buyCharge
                                            ? parseFloat(buyCharge).toFixed(2)
                                            : "0"
                                        }
                                        {" "}SGD
                                    </span>
                                </Col>
                            </Row>

                            <Row className="mb-2 text-end">
                                <Col lg={6} className="text-start">
                                    <span>Total SGD we calculate</span>
                                </Col>
                                <Col lg={6}>
                                    <span>
                                        <FontAwesomeIcon icon={faEquals} className="me-1" />
                                        {fromCurrencyValueBuying
                                            ? (fromCurrencyValueBuying - parseFloat(buyCharge)).toFixed(2)
                                            : 0
                                        }
                                        {" "}
                                        SGD

                                    </span>
                                </Col>
                            </Row>

                            <hr />

                        </>
                    }

                    <Row className="mb-2 text-end">
                        <Col lg={6} className="text-start">
                            <span>Exchange Rate</span>
                        </Col>
                        <Col lg={6}>
                            <span>
                                <FontAwesomeIcon icon={faDivide} className="me-1" />
                                {parseFloat(rate).toFixed(4)} SGD
                            </span>
                        </Col>
                    </Row>

                    <div className="mb-2">
                        <Label>{toCurrencyBuying} Amount</Label>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter Amount"
                            value={toCurrencyDisplay}
                            onKeyDown={handleNumericInput}
                            onChange={convertToCurrency}
                            onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                        />
                        {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}

                    </div>

                    <div className="text-end">
                        <button
                            type="submit"
                            disabled={
                                isLoading ||
                                Object.values(errors).some((error) => error !== "")
                            }
                            className="btn btn-primary"

                        >
                            {isLoading ? "Loading..." : "Submit"}
                        </button>

                    </div>
                </>
            }

        </Form >

    );
};

export default BuyForm;