const config = {

    // apiUrl: 'https://localhost:7298/',

    // apiUrl: 'https://cexcustomerapi.jaffar.au/',

    apiUrl: 'https://sitapi.crescentexchange.com/customer/',

    // apiUrl: 'https://prodapi.crescentexchange.com/customer/',

};

export default config;