import { useState } from "react";
import { getApiData } from "../../helpers/axiosHelper";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const useBranch = () => {
    const [branch, setBranch] = useState([]);

    const mapBranchOptions = (branches) => {
        if (!branches) return [];
        return branches.map((branch) => ({
            label: branch.branchName,
            value: branch.branchId,

        }));
    };

    const fetchBranch = async () => {
        try {
            const response = await getApiData("api/BranchMaster/GetBranches");
            setBranch(mapBranchOptions(response.data));
        } catch (error) {
            console.error(error);
        }
    };

    return { branch, fetchBranch, mapBranchOptions };
};

export const useCurrency = () => {

    const [currency, setCurrency] = useState([])

    const mapCurrencyOptions = (currency) => {
        if (!currency) return [];
        const startingNumber = 1;
        let currentNumber = startingNumber;
        return currency.map((e) => ({
            label: e.currencyCode,
            value: currentNumber++,
        }));
    };

    const getCurrency = async () => {
        try {
            const response = await getApiData("api/Currency/GetAllCurrencies")
            setCurrency(mapCurrencyOptions(response.data))
        } catch (error) {
            console.error(error);
        }
    }

    return { currency, getCurrency };
}

export const fetchUserData = async () => {
    try {
        const response = await getApiData('api/User/GetCustomerByUID');
        if (response.success === true) {
            return response.data;
        }
        return;
    } catch (error) {
        console.error(error);
    }
};

export function forceLogout(error) {
    var forceLogout = sessionStorage.getItem("forceLogout");
    console.log(error);
    if (error.response?.status === 403 && error.response?.data === "User loggen in different device or session" && forceLogout === "false") {

        sessionStorage.removeItem("custUser");

        <Navigate to="/login" replace />;

        Swal.fire({
            title: "Force Logout",
            text: error.data,
            icon: "warning",
            confirmButtonText: "OK",
        });
    }

    sessionStorage.setItem("forceLogout", "true");
}