import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { getApiData } from "../../helpers/axiosHelper";
import { ProfileContext } from "../../common/data/context";
import { fetchUserData } from "../../common/data/commonFunction";

function CardUser() {
    const { profileData, setProfileData } = useContext(ProfileContext);
    const [profileImage, setProfileImage] = useState(null);

    useEffect(() => {
        async function fetchUserDataAsync() {
            const getUser = await fetchUserData();
            setProfileData(getUser);
        }
        fetchUserDataAsync();
    }, [setProfileData]);

    useEffect(() => {
        const fetchProfileImage = async () => {
            try {
                const { data: image } = await getApiData("api/ProfileImage/GetProfileImage");
                setProfileImage(image?.isRecognised ? image : null);
            } catch (error) {
                console.error("Error fetching profile image.", error);
            }
        };
        fetchProfileImage();
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg="4">
                                    <div className="d-flex">
                                        <div className="me-3">
                                            {profileImage ? (
                                                <img
                                                    src={`data:image/png;base64,${profileImage.normalImage}`}
                                                    alt="Profile"
                                                   
                                                    style={{
                                                        objectFit: "cover", borderRadius: "50%", width: "60px",
                                                        height: "60px", }}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        marginTop: "10px",
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="text-muted">
                                                <p className="mb-2 text-primary">Welcome to Crescent</p>
                                                <h5 className="mb-1">
                                                    {profileData?.firstName
                                                        ? `${profileData.firstName} ${profileData.lastName}`
                                                        : ""}
                                                </h5>
                                                <p className="mb-0">Customer</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default CardUser;
