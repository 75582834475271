import React, { useEffect, useMemo, useState } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    Row,
} from "reactstrap";
import { useFormik } from "formik";
import { Tooltip } from "reactstrap";
import ReactSelect from "react-select";
import { FaFilter } from 'react-icons/fa';
import ViewTransaction from "./ViewTransaction";
import ReceiptModal from "../Transaction/Receipt";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { SelectStyle } from "../../common/data/SelectStyle";
import withRouter from "../../components/Common/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import TableContainer from "../../components/Common/TableContainer";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { mapStatus, statusOptions } from "../../common/data/StatusLabels";
import { useBranch, useCurrency } from "../../common/data/commonFunction";
import { formatDateToUserTimeZone } from "../../common/data/FormateDateTime";
import { faEye, faDownload, faDollar, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const TransactionHistory = () => {

    document.title = "Transaction History | Crescent Exchange";

    //  const [error, setError] = useState();
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const { currency, getCurrency } = useCurrency();
    const [currencyFlags, setCurrencyFlags] = useState({});

    const [optime, setOptime] = useState([]);
    const { branch, fetchBranch } = useBranch();
    const [branchID, setBranchID] = useState("");
    const [branchData, setBranchData] = useState();

    const [modal1, setModal1] = useState(false);
    const toggleViewModal = () => setModal1(!modal1);
    const [showModal, setShowModal] = useState(false);
    const [commentData, setCommentData] = useState([]);

    const [columnName, setColumnName] = useState();
    const [filterType, setFilterType] = useState("");
    const [totalPages, setTotalPages] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [totalRecord, setTotalRecords] = useState(null);
    const [modal_filter, setmodal_filter] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const [buyRate, setBuyRate] = useState(0);
    const [receiptData, setReceiptData] = useState({});
    const [transferRate, setTransferRate] = useState(0);
    const [chargesData, setChargesData] = useState(null);
    const [transactionID, setTransactionID] = useState("");
    const [transactionType, setTransactionType] = useState(null);
    const [triggerDownload, setTriggerDownload] = useState(false);
    const [transactionHistoryData, setTransactionHistoryData] = useState([]);
    const [sendTransactionDetails, setSendTransactiondetails] = useState(null);

    const [showFilterApplied, setShowFilterApplied] = useState(null);

    const [state, setState] = useState({
        pageSize: 10,
        status: null,
        currentPage: 1,
        currency: null,
        branchName: null,
        submitting: false,
        clearFiltersClicked: false,
    });

    const fetchCurrencies = async () => {
        try {
            const response = await getApiData('api/Currency/GetAllCurrencies');
            const flags = {};
            response.data.forEach(currency => {
                flags[currency.currencyCode] = currency.currencyFlag;
            });
            setCurrencyFlags(flags);
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const handleHelpClick = () => {
        window.open('https://sitapi.crescentexchange.com/help/customer/Transaction Histroy.pdf');
    };

    const handleClose = () => {
        setShowModal(false);
        setTriggerDownload(false);
    };

    const Days = {
        0: "AlL Days",
        1: "Sunday",
        2: "Monday",
        3: "Tuesday",
        4: "Wednesday",
        5: "Thursday",
        6: "Friday",
        7: "Saturday",

    };

    const transactionTypeMap = {
        2: { label: "Buy", color: "success" },
        1: { label: "Sell", color: "danger" },
        3: { label: "Transfer", color: "primary" },
    };

    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(":");
        const meridiem = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${meridiem}`;
    };

    const ViewTransactionDetails = (cellProps) => {
        const { transactionId, branchId } = cellProps;

        setTransactionID(transactionId);
        setBranchID(branchId);

        const getTransactionDetails = async () => {
            try {
                // Fetch transaction details
                const transactionResponse = await getApiData(
                    `api/Transaction/GetTransaction?TransactionID=${transactionId}`
                );

                // Fetch branch details
                const branchResponse = await getApiData(
                    `api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`
                );

                // Map operational times to 12-hour format
                const operationalTimes = branchResponse.data.operationalTime.map((item) => ({
                    day: Days[item.day],
                    startTime: convertTo12HourFormat(item.startTime),
                    endTime: convertTo12HourFormat(item.endTime),
                }));

                setBranchData(branchResponse.data.branch);
                setOptime(operationalTimes);
                setSendTransactiondetails(transactionResponse);
                setTransactionType(transactionResponse.transactionType);
                var comment = transactionResponse.comments;
                setCommentData(comment.reverse());

                // Fetch transaction charges
                const { transactionType, fromCurrType, toCurrType, fromRateHistoryId, toRateHistoryId, buyRateHistoryIdMainBranch } = transactionResponse;

                await fetchTransactionCharges(transactionType, fromCurrType, toCurrType, fromRateHistoryId, toRateHistoryId, buyRateHistoryIdMainBranch);

            } catch (error) {
                console.error("Error occurred:", error);
            }
        };

        getTransactionDetails();
        toggleViewModal();
    };

    const fetchTransactionCharges = async (transactionType, fromCurrType, toCurrType, fromRateHistoryId, toRateHistoryId, buyRateHisId) => {

        try {
            let rateHistoryId = transactionType === 1 ? fromRateHistoryId : toRateHistoryId;

            let buyRate = 0;
            let transferRate = 0;


            if (transactionType === 3) {
                if (fromCurrType === "SGD") {
                    const transferRateResponse = await getApiData(
                        `api/RateMaster/GetRateHistoryIdRate?rateHistoryId=${toRateHistoryId}`
                    );
                    if (transferRateResponse?.success) {
                        transferRate = transferRateResponse.data?.rate || 0;
                        setTransferRate(transferRate);
                    }
                } else {
                    const buyRateResponse = await getApiData(
                        `api/RateMaster/GetRateHistoryIdRate?rateHistoryId=${buyRateHisId}`
                    );
                    if (buyRateResponse?.success) {
                        buyRate = buyRateResponse.data?.rate || 0;
                        setBuyRate(buyRate);
                    }

                }
            }

            if (rateHistoryId) {
                const chargesResponse = await getApiData(
                    `api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`
                );

                if (chargesResponse?.success) {
                    setChargesData(chargesResponse.data);
                } else {
                    console.error("Failed to fetch charges:", chargesResponse.message);
                }
            } else {
                console.warn("Rate history ID is null. Skipping getTransactionCharges API call.");
            }
        } catch (error) {
            console.error("Error fetching transaction charges:", error);
        }
    };

    const handleShow = (data, download, transactionType, fromRateHistoryId, toRateHistoryId,) => {

        setReceiptData(data)
        setTriggerDownload(download);
        setShowModal(true);

        fetchTransactionCharges(transactionType, fromRateHistoryId, toRateHistoryId,);
    };

    function tog_addFilters() {
        setmodal_filter(prevState => !prevState);
    };

    const clearFilters = () => {
        return (
            <Button
                type="button"
                color="danger"
                data-toggle="modal"
                data-target="#myModal"
                onClick={resetFormValues}
            >
                Clear Filters
            </Button>
        );
    };

    const resetForm = () => {
        setFilterType('')
        setColumnName(null)
        validation.resetForm();
        setSelectedOption(null);
        setState({
            pageSize: 10,
            status: null,
            currentPage: 1,
            currency: null,
            branchName: null,
            submitting: false,
        })
        setFilterValue(null)
        setState((prevState) => ({ ...prevState, clearFiltersClicked: true }))
    }

    const resetFormValues = () => {
        setFilterType('');
        setColumnName(null);

        validation.resetForm();
        setSelectedOption(null);
        setShowFilterApplied(null);
        setState({
            pageSize: 10,
            status: null,
            currentPage: 1,
            currency: null,
            branchName: null,
            submitting: false,
        });
        setFilterValue(null);
        setState((prevState) => ({ ...prevState, clearFiltersClicked: true }));
        validation.handleSubmit();
        getTransactionHistory();
    }

    const columnList = [
        { label: "Status", value: "status" },
        { label: "Branch", value: "branchName" },
        { label: "Currency", value: "fromCurrType" },
    ]

    const mapResponse = (data) => {
        return data.map((item, index) => ({
            transactionId: item.transactionId,
            branchId: item.fromBranchId,
            index: index + 1,
            name: item.name,
            fromRateHistoryId: item.fromRateHistoryId,
            toRateHistoryId: item.toRateHistoryId,
            branch: item.branchName,
            transactionType: item.transactionType,

            date: formatDateToUserTimeZone(item.transactionDate),
            fromCurrType: item.fromCurrType,
            toCurrType: item.toCurrType,
            fromCurrValue: item.fromCurrValue,
            toCurrValue: item.toCurrValue,
            status: mapStatus(item.status),
            exchangeRate: parseFloat(item.exchangeRate).toFixed(8),
            pickup: item.pickup ? formatDateToUserTimeZone(item.pickup) : 'No Pickup Date',
            transactingCurrency: item.toCurrType,
        }));

    }

    const getTransactionHistory = async () => {
        try {
            setIsLoading(true);
            const response = await postApiData(
                `api/Transaction/GetTransactionByCustomerPagination`,
                JSON.stringify({ pagenumber: 1, pagecount: 10 })
            );

            if (response?.success === true) {
                if (response.model.length === 0) {
                    setTransactionHistoryData([]);
                } else {
                    const mappedData = mapResponse(response.model);
                    setTransactionHistoryData(mappedData);
                    setTotalPages(response.totalPage);
                    setTotalRecords(response.totalRecord);
                }
            } else {
                setTransactionHistoryData([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching transaction history', error);
        }
    };

    useEffect(() => {
        fetchBranch()
        getCurrency()
        fetchCurrencies();
        getTransactionHistory();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "transactionId",
                filterable: false,
                disableFilters: true,
            },
            {
                Header: "Branch",
                accessor: "branch",
                filterable: false,
                disableFilters: true,
            },
            {
                Header: "Currency",
                accessor: "transactingCurrency",
                disableFilters: true,
                Cell: (cellProps) => {
                    const { transactionType, fromCurrType, toCurrType } = cellProps.row.original;
                    const transactingCurrency = transactionType === 1 ? fromCurrType : toCurrType;
                    const { label, color } = transactionTypeMap[transactionType] || {};
                    const flagIcon = currencyFlags[transactingCurrency];

                    if (transactionType === 3) {
                        const fromFlagIcon = currencyFlags[fromCurrType];
                        const toFlagIcon = currencyFlags[toCurrType];

                        return (
                            <div>

                                {fromFlagIcon ? (
                                    <img src={`data:image/png;base64,${fromFlagIcon}`} alt="" style={{ width: '20px', marginRight: '5px' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faDollar} />
                                )}
                                {/*<span>{fromCurrType}</span>*/}
                                <Badge className={`font-size-11 badge-soft-${color}`}>
                                    {label}
                                </Badge>
                                {toFlagIcon ? (
                                    <img src={`data:image/png;base64,${toFlagIcon}`} alt="" style={{ width: '20px', marginLeft: '5px' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faDollar} />
                                )}
                                {/*<span>{toCurrType}</span>*/}
                            </div>
                        );
                    }

                    return (
                        <div className="text-center">
                            <Badge className={`font-size-11 badge-soft-${color}`}>
                                {label}
                            </Badge>
                            {flagIcon ? (
                                <img src={`data:image/png;base64,${flagIcon}`} alt="" style={{ width: '20px', marginRight: '5px', marginLeft: "5px" }} />
                            ) : (
                                <FontAwesomeIcon icon={faDollar} />
                            )}
                            <span>{transactingCurrency}</span>
                        </div>
                    );
                },
            },
            {
                Header: "From Currency",
                accessor: "fromCurrValue",
                filterable: false,
                disableFilters: true,
                Cell: (cellProps) => {
                    const { fromCurrValue, fromCurrType } = cellProps.row.original;

                    return <span>{fromCurrValue}  <strong style={{ color: "#556ee6" }}> {fromCurrType} </strong></span>;
                },
            },
            {
                Header: "Rate",
                accessor: "exchangeRate",
                filterable: false,
                disableFilters: true,
            },
            {
                Header: "To Currency",
                accessor: "toCurrValue",
                filterable: false,
                disableFilters: true,
                Cell: (cellProps) => {
                    const { toCurrValue, toCurrType } = cellProps.row.original;

                    const formattedValue = !isNaN(toCurrValue) ? Number(toCurrValue).toFixed(2) : "0.00";

                    return (
                        <span>
                            {formattedValue}{" "}
                            <strong style={{ color: "#556ee6" }}>{toCurrType}</strong>
                        </span>
                    );
                },
            },
            {
                Header: "Pickup Date",
                accessor: "pickup",
                disableFilters: true,
                Cell: ({ value }) => {
                    return value ? value : "No Pickup Date";
                },
            },
            {
                Header: "Status",
                accessor: "status",
                filterable: false,
                disableFilters: true,
                Cell: ({ row }) => (
                    <Badge className={`font-size-11 badge-soft-${row.original.status.color}`}>
                        {row.original.status.label}
                    </Badge>
                ),
            },
            {
                Header: "View Details",
                accessor: "view",
                disableFilters: true,
                Cell: (cellProps) => {
                    const isDownloadable = cellProps.row.original.status === mapStatus(13);
                    const {
                        transactionId,
                        date,
                        transactionType,
                        name,
                        fromCurrValue,
                        toCurrType,
                        fromRateHistoryId,
                        toRateHistoryId,
                        toCurrValue,
                        fromCurrType,
                        branch,

                        exchangeRate
                    } = cellProps.row.original;

                    const receiptData = {
                        transactionId,
                        date,
                        name,
                        fromCurrType,
                        fromRateHistoryId,
                        toRateHistoryId,
                        transactionType,
                        branch,
                        exchangeRate,

                        fromCurrValue,
                        toCurrType,
                        toCurrValue,
                    };

                    return (
                        <div>
                            <Button
                                type="button"
                                title="View"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={(e) => {
                                    setEditMode(true);
                                    e.preventDefault();
                                    ViewTransactionDetails(cellProps.row.original);
                                }}

                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>

                            {isDownloadable && (
                                <Button
                                    type="button"
                                    style={{ backgroundColor: "#34c38f", border: "none" }}
                                    className="btn-sm btn-rounded me-2 ms-2"
                                    onClick={() => handleShow(receiptData, true, receiptData.transactionType, receiptData.fromRateHistoryId, receiptData.toRateHistoryId)}
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                            )}
                        </div>
                    );
                },
            }

        ],
        [currencyFlags]
    );

    const handleChange = (pageSize, currentPage) => {
        if (pageSize !== state.pageSize) {
            setState((prevState) => ({
                ...prevState,
                pageSize: pageSize,
            }));
        }
        setState((prevState) => ({
            ...prevState,
            currentPage: currentPage,
        }));
        validation.setValues((values) => ({
            ...values,
            pagecount: pageSize,
            pagenumber: currentPage,
        }));
        validation.handleSubmit();
    };

    const validation = useFormik({

        enableReinitialize: true,

        initialValues: {
            pagenumber: state.currentPage,
            pagecount: state.pageSize,
        },

        onSubmit: async (values) => {
            setState((prevState) => ({ ...prevState, submitting: true }))
            if (modal_filter) {
                values.pagenumber = 1
                values.pagecount = 10
                setState((prevState) => ({ ...prevState, currentPage: 1, pageSize: 10 }))
            }
            let combinedValues = { ...values };
            if (columnName) {
                if (columnName && filterType === 'Customer Name') {
                    combinedValues = {
                        ...combinedValues,
                        filterColumns: [
                            {
                                columnName: columnName,
                                filterValue: state.customerName !== '' && state.customerName,
                            },
                        ],
                    };
                }
                else {
                    combinedValues = {
                        ...combinedValues,
                        filterColumns: [
                            {
                                columnName: columnName,
                                filterValue:
                                    ((state.branchName !== null) && state.branchName?.label) ||
                                    ((state.currency !== null) && state.currency?.label) ||
                                    ((state.status !== null) && `${state.status?.value}`),
                            },
                        ],
                    };
                }
            }
            setShowFilterApplied(combinedValues.filterColumns?.[0] || null);
            const response = await postApiData(`api/Transaction/GetTransactionByCustomerPagination`, JSON.stringify(combinedValues))
            if (response.success === true) {
                setTransactionHistoryData(mapResponse(response.model))
                setTotalPages(response?.totalPage)
                setTotalRecords(response?.totalRecord)
                if (modal_filter) {
                    tog_addFilters()
                }
                setState((prevState) => ({ ...prevState, submitting: false }))
            }
            else {
                toast.error(`${response.message}`, {
                    position: 'top-right',
                    autoClose: 1500,
                    closeButton: false,
                    onClose: () => {
                        setState((prevState) => ({ ...prevState, submitting: false }))
                    }
                })
            }
        },
    });

    const getFilterLabel = (columnName) => {
        switch (columnName) {
            case "status":
                return "Status";
            case "branchName":
                return "Branch";
            case "fromCurrType":
                return "Currency";
            default:
                return "Unknown";
        }
    };

    const getFilterValue = ({ columnName, filterValue }) => {
        if (columnName === "status") {
            return mapStatus(parseFloat(filterValue)).label;
        }
        return filterValue;
    };

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            status: null,
            currency: null,
            branchName: null,
        }))
    }, [filterType])

    return (
        <React.Fragment>

            <ToastContainer closeButton={false} limit={1} />

            {showModal && (
                <ReceiptModal
                    show={showModal}
                    handleClose={handleClose}
                    buyRate={buyRate}
                    chargesData={chargesData}
                    receiptData={receiptData}
                    triggerDownload={triggerDownload}
                />
            )}

            <ViewTransaction
                optime={optime}
                isOpen={modal1}
                showAction={false}
                branchID={branchID}
                transactionType={transactionType}
                chargesData={chargesData}
                buyRate={buyRate}
                transferRate={transferRate}
                editMode={editMode}
                branchData={branchData}
                toggle={toggleViewModal}
                commentData={commentData}
                transactionID={transactionID}
                sendTransactionDetails={sendTransactionDetails}
            />

            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="card-title mb-4">Transaction History</h4>
                                <div className="ms-3">
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        onClick={handleHelpClick}
                                        color="#556ee6"
                                        id="helpIcon"
                                        style={{ fontSize: '1.2em', cursor: "pointer" }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen}
                                        target="helpIcon"
                                        toggle={() => setTooltipOpen(!tooltipOpen)}
                                    >
                                        Help
                                    </Tooltip>
                                </div>
                            </div>

                            <Modal
                                size="md"
                                isOpen={modal_filter}
                                toggle={() => {
                                    tog_addFilters();
                                    setFilterType('');

                                }}
                            >

                                <div className="modal-header">
                                    <h5
                                        className="modal-title mt-0"
                                        id="mySmallModalLabel"
                                    >
                                        Select Filters
                                    </h5>
                                    <button
                                        onClick={() => {
                                            setmodal_filter(false);
                                            validation.resetForm();
                                            setFilterType('');
                                            setFilterValue('');
                                        }}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <Row>
                                        <Col lg="12">
                                            <div className="mb-2">
                                                <Col lg="12">
                                                    <Label for="filterBy">Filter By</Label>
                                                </Col>
                                                <ReactSelect
                                                    options={columnList.map(column => ({ value: column.value, label: column.label }))}
                                                    value={selectedOption}
                                                    styles={SelectStyle}
                                                    onChange={(selected) => {
                                                        setSelectedOption(selected);
                                                        setFilterType(selected.label);
                                                        setColumnName(selected.value);
                                                        setFilterValue('');
                                                    }}
                                                    placeholder="Select Filter"
                                                />
                                            </div>
                                        </Col>
                                        {filterType === 'Branch' ? (
                                            <Col lg="12">
                                                <div className="mb-3">
                                                    <Label for="filterValue">{`Select ${filterType}`}</Label>
                                                    <RequiredAsterisk />
                                                    <ReactSelect
                                                        options={branch}
                                                        value={state.branchName}
                                                        styles={SelectStyle}
                                                        onChange={(e) => {
                                                            setState((prevState) => ({ ...prevState, branchName: e }));
                                                            setFilterValue("e");
                                                        }}
                                                        placeholder="Select Branch..."
                                                    />
                                                </div>
                                            </Col>
                                        ) :
                                            filterType === 'Currency' ? (
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="filterValue">{`Select ${filterType} [From Currency]`}</Label>
                                                        <RequiredAsterisk />
                                                        <ReactSelect
                                                            options={currency}
                                                            value={state.currency}
                                                            styles={SelectStyle}
                                                            onChange={(e) => {
                                                                setState((prevState) => ({ ...prevState, currency: e }));
                                                                setFilterValue("e");
                                                            }}
                                                            placeholder="Select Currency..."
                                                        />
                                                    </div>
                                                </Col>
                                            ) : filterType === 'Status' ? (
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="filterValue">{`Select ${filterType}`}</Label>
                                                        <RequiredAsterisk />
                                                        <ReactSelect
                                                            options={statusOptions}
                                                            value={state.status}
                                                            styles={SelectStyle}
                                                            onChange={(e) => {
                                                                setState((prevState) => ({ ...prevState, status: e }));
                                                                setFilterValue("e");
                                                            }}
                                                            placeholder="Select Status..."
                                                        />
                                                    </div>
                                                </Col>
                                            ) : null}
                                    </Row>
                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={validation.handleSubmit}
                                        disabled={state.submitting || !filterType || !filterValue}
                                    >
                                        Apply Filters
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={resetForm}
                                        disabled={state.submitting}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </Modal>

                            <div className="mb-2 d-flex justify-content-start gap-1 flex-wrap">
                                <Button
                                    type="button"
                                    onClick={tog_addFilters}
                                    color="primary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                >
                                    Add Filters
                                </Button>
                                {clearFilters()}
                            </div>

                            <div className="mt-3 mb-3 w-100">
                                {showFilterApplied ? (
                                    <div className="card shadow-sm p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <FaFilter className="me-2" size={20} color="#007bff" />
                                                <span className="fw-bold">Filter By:</span>
                                                <span className="ms-2">{getFilterLabel(showFilterApplied.columnName)}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <span className="fw-bold">Filter Value:</span>
                                                <span className="ms-2">{getFilterValue(showFilterApplied)}</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-muted p-3">
                                        <span className="fw-bold">No filter applied</span>
                                    </div>
                                )}
                            </div>

                            {isLoading
                                ?
                                <Loader />
                                :
                                transactionHistoryData.length > 0
                                    ?
                                    <TableContainer
                                        columns={columns}
                                        data={transactionHistoryData}
                                        customPageSize={10}
                                        isPageSelect={false}
                                        onPageChange={handleChange}
                                        totalPage={totalPages}
                                        totalRecord={totalRecord}
                                        currentPage={state.currentPage}
                                        goToPage={true}
                                        clearFilters={clearFilters}
                                        changePageOnResult={modal_filter}
                                        refresh={true}
                                    />
                                    :
                                    <div className="text-muted d-flex justify-content-center align-items-center" style={{ minHeight: "100px" }}>
                                        <h3>No Records to Show</h3>
                                    </div>
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>

        </React.Fragment>
    );
};
export default React.memo(withRouter(TransactionHistory));