import PropTypes from "prop-types";
import React, { useState } from "react";
import {
    Col,
    Row,
    Card,
    Form,
    Label,
    Input,
    CardBody,
    Container,
    FormFeedback,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import axios, { getApiData, postApiData } from "../../helpers/axiosHelper";

//Redux
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "../../../images/profile-img.png";
import logo from "../../../images/crescent_logo.png"
import singpassLogo from "../../../images/final_singpass.png";

//Other Authentication
import { api } from '../../config'
import { GoogleLogin } from '@react-oauth/google';


const Login = () => {

    document.title = "Crescent Exchange | Login";

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const isChrome = (() => {
        const isChromium = !!window.chrome;
        const isGoogle = /Google Inc/.test(navigator.vendor);
        const isNotEdge = !/Edg/.test(navigator.userAgent);
        const isNotOpera = !/OPR/.test(navigator.userAgent);
        return isChromium && isGoogle && isNotEdge && isNotOpera;
    })();

    const handleSingpass = async () => {
        try {
            const response = await getApiData('/api/SingPass/Login');

            if (response && response.codeChallenge && response.State) {
                const client_id = api.singPass.clientId;
                const redirect = encodeURIComponent(api.singPass.redirectUri);
                const scope = encodeURIComponent("openid uinfin aliasname dob email mobileno name sex");
                const code_challenge = response.codeChallenge;
                const state = response.State;
                const nonce = "bb5e1672-a460-4a9b-874e-c38d55ac3922";

                const url = `${api.singPass.authUrl}?scope=${scope}&response_type=code&redirect_uri=${redirect}&nonce=${nonce}&client_id=${client_id}&code_challenge=${code_challenge}&code_challenge_method=S256&state=${state}`;

                sessionStorage.setItem("singpass", JSON.stringify(response));
                sessionStorage.setItem("isPage", 'Login');
                window.location.href = url;
            } else {
                console.error("Invalid API Response: Missing codeChallenge or State");
            }
        } catch (error) {
            console.error("Error during login:", error);
        }
    };

    const handleGoogle = (credentialResponse) => {
        const authorizationCode = credentialResponse.credential;

        postApiData('api/User/LoginWithGoogle',
            JSON.stringify({ idToken: authorizationCode }),
        )
            .then(response => response)
            .then(data => {
                if (data.success === true) {
                    if (data.data.token) {
                        const logged_user = {
                            login: true,
                            uid: data.data.uid,
                            name: data.data.name,
                            email: data.data.email,
                            userType: data.data.userType,
                            token: data.data.token,
                        };

                        const expirValidation = {
                            uid: data.data.uid,
                            expiration: data.data.expiration,
                        };
                        sessionStorage.setItem("forceLogout", "false");
                        sessionStorage.setItem("expirValidation", JSON.stringify(expirValidation));
                        sessionStorage.setItem("custUser", JSON.stringify(logged_user));
                        axios.defaults.headers.common["Authorization"] = "Bearer " + data.token;

                        toast.success("Customer Login Successful.", {
                            position: "top-right",
                            autoClose: 1500,
                            onClose: () => {
                                navigate("/dashboard", { replace: true });
                                validation.resetForm();
                            },
                        });
                    }
                }
                else {
                    toast.warning(`Login Failed, ${data.message}`, {
                        position: "top-right",
                        autoClose: 1500,
                    });
                    validation.resetForm();
                }
            })
            .catch(error => {
                console.error("Error occuried", error);
            });
    }

    const handleGoogleError = (errorResponse) => {
        toast.error('Google login failed', errorResponse);
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            login: "",
            password: "",
        },

        validationSchema: Yup.object({
            login: Yup.string()
                .required("Please enter your Mobile Number or Email")
                .test("is-email-or-phone", "Invalid Mobile Number or Email", function (value) {
                    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                    const isPhone = /^\+?[0-9]{8,14}$/.test(value);
                    const isUinFin = /^[a-zA-Z0-9]*$/.test(value);
                    return isEmail || isPhone || isUinFin;
                }),
            password: Yup.string()
                .required("Please Enter Your Password")
                .min(8, "Password must be at least 8 characters long")
                .matches(/[A-Z]/, "Password must have at least one uppercase letter")
                .matches(/[a-z]/, "Password must have at least one lowercase letter")
                .matches(/[0-9]/, "Password must have at least one number")
                .matches(/[^\w\s]/, "Password must have at least one special character"),
        }),

        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            try {
                const response = await postApiData(
                    "api/Login/CustomerLogin",
                    JSON.stringify(values),
                );

                if (response.success === true) {
                    if (response.data.token) {
                        values.userType = response.data.userType;
                        values.token = response.data.token;
                        const logged_user = {
                            login: true,
                            uid: response.data.uid,
                            name: response.data.name,
                            token: response.data.token,
                            email: response.data.email,
                            authType: response.data.authType,
                        };

                        const expirValidation = {
                            uid: response.data.uid,
                            expiration: response.data.expiration,
                        };

                        axios.defaults.headers.common["Authorization"] = "Bearer " + response.token;
                        sessionStorage.setItem("custUser", JSON.stringify(logged_user));
                        sessionStorage.setItem("forceLogout", "false");
                        sessionStorage.setItem("expirValidation", JSON.stringify(expirValidation));

                        if (response.data.userType === "Customer") {
                            toast.success("Login Successful", {
                                position: "top-right",
                                autoClose: 1500,
                                onClose: () => {
                                    navigate("/dashboard", { replace: true });
                                    setIsLoading(false);
                                },
                            });
                        } else {
                            toast.error(response.message, {
                                position: "top-right",
                                autoClose: 1500,
                            });
                            resetForm();
                            setIsLoading(false);
                        }
                    } else {
                        toast.warning("User verification Required", {
                            position: "top-right",
                            autoClose: 1500,
                            onClose: () => {
                                navigate(`/emailverification/${response.data.uid}`);
                                setIsLoading(false);
                            },
                        });
                    }
                } else {
                    toast.error("Login Failed", response.message, {
                        position: "top-right",
                        autoClose: 1500,
                    });
                    resetForm();
                    setIsLoading(false);
                }

            } catch (error) {
                console.error("Login Failed", error);
                resetForm();
                setIsLoading(false);
            }

        },

    });

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />

            <div className="home-btn d-block" style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: 1000 }}>
                <Link
                    to="/home"
                    className="text-dark"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    <i className="bx bx-home h2" style={{ marginRight: '5px' }} />

                </Link>
            </div>


            <div className="account-pages my-2 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">

                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back, User!</h5>
                                                <p>Log In to continue using Crescent Exchange.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">

                                    <div className="avatar-md profile-user-wid mb-2">
                                        <Link to="/" className="fw-medium text-primary" >
                                            <span className="avatar-title rounded-circle bg-light w-75 h-75 border border-dark border-2">
                                                <img
                                                    src={logo}
                                                    alt="crescent.png"
                                                    className="rounded-circle w-75"

                                                />
                                            </span>
                                        </Link>
                                    </div>

                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <div className="mb-2">
                                            <Label
                                                className="text-primary fw-bold"
                                            >
                                                Email
                                            </Label>
                                            <Label className='ms-2 me-2'>/</Label>
                                            <Label
                                                className="text-primary fw-bold"
                                            >
                                                Mobile Number
                                            </Label>
                                            <Label className='ms-2 me-2'>/</Label>
                                            <Label
                                                className="text-primary fw-bold"
                                            >
                                                NRIC
                                            </Label>
                                            <Label
                                                className="ms-1 me-1"
                                            >
                                                or
                                            </Label>
                                            <Label
                                                className="text-primary fw-bold"
                                            >
                                                FIN Number
                                            </Label>
                                            <Input
                                                id="login"
                                                className="form-control"
                                                placeholder={"Enter either Email or Mobile number or NRIC/FIN."}
                                                disabled={isLoading}
                                                onBlur={validation.handleBlur}
                                                onChange={validation.handleChange}
                                                value={validation.values.login || ""}
                                                invalid={
                                                    validation.touched.login && validation.errors.login
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.login && validation.errors.login ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.login}
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-2">
                                            <Label className="form-label">Password</Label>
                                            <RequiredAsterisk />
                                            <div className="input-group">
                                                <Input
                                                    type={isPasswordVisible ? "text" : "password"}
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    disabled={isLoading}
                                                    onBlur={(e) => {
                                                        validation.handleBlur(e);
                                                    }}
                                                    onChange={validation.handleChange}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {isChrome && (
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        style={{ border: '1px solid #ced4da' }}
                                                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                                    >
                                                        {isPasswordVisible ? (
                                                            <i className="bx bx-hide"></i>
                                                        ) : (
                                                            <i className="bx bx-show"></i>
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                            {validation.touched.password && validation.errors.password ? (
                                                <FormFeedback type="invalid" style={{ display: "block" }}>
                                                    {validation.errors.password}
                                                </FormFeedback>
                                            ) : null}

                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customControlInline"
                                                >
                                                    Remember me
                                                </label>
                                            </div>

                                            <Link
                                                to="/forgot-password"
                                                className="text-muted"
                                            >
                                                <i className="mdi mdi-lock me-1" />
                                                Forgot password?
                                            </Link>
                                        </div>

                                        <div className="mt-2 d-grid">
                                            <button
                                                className="btn btn-primary btn-block"
                                                type="submit"
                                                disabled={isLoading}
                                            >
                                                <b> {isLoading ? "Logging in.... " : "Login"}</b>
                                            </button>
                                        </div>

                                        <hr />

                                        <div className="mt-2 text-center">
                                            <h5 className="font-size-14 mb-2">Login with</h5>
                                            <Row className="justify-content-center">
                                                <Col xs="auto" className="mt-2 mt-sm-0">
                                                    <GoogleLogin
                                                        onSuccess={handleGoogle}
                                                        onError={handleGoogleError}
                                                    />
                                                </Col>

                                                <Col xs="auto" className="mt-2 mt-sm-0">
                                                    <img
                                                        alt="Singpass_Logo.png"
                                                        src={singpassLogo}
                                                        onClick={handleSingpass}
                                                        style={{
                                                            cursor: "pointer",
                                                            width: "200px",
                                                            padding: "0px 10px"
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>


                                    </Form>
                                </CardBody>
                            </Card>

                            <div className="text-center">
                                <p>
                                    Don&#39;t have an account ?{" "}
                                    <Link
                                        to="/register"
                                        className="fw-medium text-primary"
                                    >
                                        <b>
                                            Register Now
                                        </b>
                                    </Link>
                                </p>

                            </div>

                        </Col>

                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default withRouter(Login);

Login.propTypes = {
    history: PropTypes.object,
};
