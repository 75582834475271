import React, { useEffect, useState, useContext } from "react";
import {
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    Input,
    Label,
    Row,
    CardTitle,
    Form,
    TabContent,
    TabPane,
    Container,
    Button,
} from "reactstrap";
import moment from "moment";
import Swal from "sweetalert2";
import classnames from "classnames";
import { Tooltip } from "reactstrap";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import ReactSelect, { components } from 'react-select';
import AVEBeneficiary from "../Beneficiary/AVEBeneficiary";
import { SelectStyle } from "../../common/data/SelectStyle";
import { BaseCurrencyContext } from "../../common/data/context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { faMinus, faEquals, faX, faDivide, faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const CustomOption = (props) => {

    return (
        <components.Option {...props}>
            <div className="d-flex justify-content-between">
                <div>
                    <div>{props.data.label}</div>
                    <div style={{ fontSize: '0.8em' }}>{props.data.address}</div>
                </div>
                <div className="text-danger" style={{ fontSize: '0.9em', fontWeight: 'bold' }}>
                    {props.ratetype === 1 &&
                        <span className="text-dark">  Max: </span>
                    }
                    {props.data.availableAmount}
                </div>
            </div>
        </components.Option>
    );
};

const AddTransaction = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [customActiveTab, setCustomActiveTab] = useState("1");

    const [errors, setErrors] = useState({});
    const [mainBranch, setMainBranch] = useState(null);

    const [currencies, setCurrencies] = useState([]);
    const { currencyData } = useContext(BaseCurrencyContext);

    const [optime, setOptime] = useState([]);
    const [pickupDT, setPickupDT] = useState(null);
    const currentDate = new Date().toLocaleString();

    const [rateHistoryId, setRateHistoryId] = useState(null);

    const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0");
    const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0");


    // Buying
    const fromCurrencyBuying = "SGD";
    const [fromCurrencyValueBuying, setFromCurrencyValueBuying] = useState(0);

    const [buyingCurrency, setBuyingCurrency] = useState([]);
    const [toCurrencyValueBuying, setToCurrencyValueBuying] = useState(0);
    const [selectedToCurrencyBuying, setSelectedToCurrencyBuying] = useState(null);

    const [buyCharge, setBuyCharge] = useState(0);

    const [buyBranch, setBuyBranch] = useState([]);
    const [selectedBuyBranch, setSelectedBuyBranch] = useState(null);

    const buyExchnageRate = parseFloat(selectedBuyBranch?.conversionValue !== "" ? selectedBuyBranch?.conversionValue : 0);


    // Selling
    const [sellingCurrency, setSellingCurrency] = useState([]);
    const [fromCurrencyValueSelling, setFromCurrencyValueSelling] = useState(0);
    const [selectedFromCurrencySelling, setSelectedFromCurrencySelling] = useState(null);

    const toCurrencySelling = "SGD";
    const [toCurrencyValueSelling, setToCurrencyValueSelling] = useState(0);

    const [sellCharge, setSellCharge] = useState(0);

    const [sellBranch, setSellBranch] = useState([])
    const [selectedSellBranch, setSelectedSellBranch] = useState(null);

    const sellExchnageRate = parseFloat(selectedSellBranch?.conversionValue !== "" ? selectedSellBranch?.conversionValue : 0);


    // Transfer
    const [searchTerm, setSearchTerm] = useState("");
    const [beneficiaryError, setBeneficiaryError] = useState("");

    const [modal1, setModal1] = useState(false);
    const toggleAddModal = () => setModal1(!modal1);
    const [enableTransfer, setEnableTransfer] = useState(true);

    const [beneficiary, setBeneficiary] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

    const fromCurrencyTransfer = currencyData?.value;
    const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);

    const toCurrencyTransfer = selectedBeneficiary?.currencyName;
    const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState(0);

    const [buyRate, setBuyRate] = useState(0);

    const [globalTransferData, setGlobalTransferData] = useState(null);

    const transferData = globalTransferData?.find((x) => {
        const targetCurrency = toCurrencyTransfer;
        return x.currencyCode === targetCurrency;
    });

    const transferRate = transferData ? parseFloat(transferData.rate) : 0;

    const calculateFee = (fee, fromCurrency, toCurrency, buyRate) => {
        if (fromCurrency === "SGD") {
            return fee;
        } else if (toCurrency === "SGD") {
            return fee / buyRate;
        } else {
            console.log({ fee, fromCurrency, toCurrency, buyRate });
            return fee / buyRate;
        }

    };

    let agentFee = transferData ? parseFloat(transferData.agentCharges) : 0;
    let wireFee = transferData ? parseFloat(transferData.wireTransferFee) : 0;
    let commissionFee = transferData ? parseFloat(transferData.commissionCharges) : 0;

    const convertedWireFee = calculateFee(wireFee, fromCurrencyTransfer, toCurrencyTransfer, buyRate);
    const convertedAgentFee = calculateFee(agentFee, fromCurrencyTransfer, toCurrencyTransfer, buyRate);
    const convertedCommissionFee = calculateFee(commissionFee, fromCurrencyTransfer, toCurrencyTransfer, buyRate);

    const setExchangeRate = () => {

        if (fromCurrencyTransfer === "SGD") {
            return transferRate.toFixed(10);
        } else if (toCurrencyTransfer === "SGD") {
            return buyRate.toFixed(10);
        } else {
            return (buyRate / transferRate).toFixed(10);
        }

    };

    console.log({
        buyRate,
        transferData, transferRate,
        fromCurrencyTransfer, toCurrencyTransfer,
        wireFee, commissionFee, agentFee,
        convertedWireFee, convertedCommissionFee, convertedAgentFee,
    });

    const resetTransaction = () => {
        setIsLoading(false);

        setPickupDT(null);

        setErrors((prevErrors) => ({
            ...prevErrors,
            branch: "",
            fromCurrency: "",
            toCurrency: ""
        }));

        setFromCurrencyDisplay("0");
        setToCurrencyDisplay("0");

        //Buying
        setFromCurrencyValueBuying(0);
        setToCurrencyValueBuying(0);
        setSelectedToCurrencyBuying(null);
        setSelectedBuyBranch(null);

        //Selling
        setFromCurrencyValueSelling(0);
        setSelectedFromCurrencySelling(null);
        setToCurrencyValueSelling(0);
        setSelectedSellBranch(null);

        //Transfer
        setSelectedBeneficiary(null);
        setFromCurrencyValueTransfer(0);
        setToCurrencyValueTransfer(0);

    };

    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                setIsLoading(true);
                const response = await getApiData("api/Currency/GetAllCurrencies");
                if (response.success === true) {
                    setCurrencies(response.data);
                } else {
                    setCurrencies([]);
                }
                setIsLoading(false);
            } catch (error) {
                console.error("Failed to fetch currencies:", error);
            }
        };

        fetchCurrencies();
    }, []);

    const handleHelpClick = () => {
        window.open("https://sitapi.crescentexchange.com/help/customer/Booking Transactions.pdf");
    };

    const getCurrencyFlag = (currencyCode) => {
        const currency = currencies.find(c => c.currencyCode === currencyCode);
        return currency ? `data:image/png;base64,${currency.currencyFlag}` : '';
    };

    const getMainBranch = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData("api/BranchMaster/GetMainBranch");
            if (response.success === true) {
                const mapMainBranch = response.data.map(branch => ({
                    branchId: branch.branchId,
                    branchName: branch.branchName,
                }));
                setMainBranch(mapMainBranch[0]);
            } else {
                setMainBranch(null);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error occuried", error)
        }

    };

    const getGlobalTransferRates = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData("api/RateMaster/GetGlobalTransferRate");

            const mappedTransferRates = response.data.map(rate => ({
                rateHistoryId: rate.rateHistoryId,
                currencyCode: rate.currencyCode,
                currencyName: rate.currencyName,
                rate: rate.value !== "" ? parseFloat(rate.value) : 0,
                worldRate: rate.worldRate !== "" ? parseFloat(rate.worldRate) : 0,
                transferRate: rate.transferRate !== "" ? parseFloat(rate.transferRate) : 0,
                wireTransferFee: rate.wireTransferFee !== "" ? parseFloat(rate.wireTransferFee) : 0,
                agentCharges: rate.agentCharges !== "" ? parseFloat(rate.agentCharges) : 0,
                commissionCharges: rate.commissionCharges !== "" ? parseFloat(rate.commissionCharges) : 0,
                totalFeesAdmin: rate.totalFeesAdmin !== "" ? parseFloat(rate.totalFeesAdmin) : 0,
                buyRateMainBranch: rate.buyRateMainBranch !== "" ? parseFloat(rate.buyRateMainBranch) : 0,
                buyRateHistoryIdMainBranch: rate.buyRateHistoryIdMainBranch ? parseFloat(rate.buyRateHistoryIdMainBranch) : 0,
            }));
            console.log(mappedTransferRates);
            setGlobalTransferData(mappedTransferRates);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching global transfer rates", error);
        }
    };

    const getAvaBeneficiary = async () => {
        try {
            setIsLoading(true);
            const response = await getApiData(`api/Customerbeneficiary/GetAllBeneficiaryByCustomer`);

            const filteredResponse = response.data.filter(item => item.status === 2 && item.active === 1);
            const mappedResponse = filteredResponse.map(item => ({
                label: item.beneficiaryName,
                value: item.custBenId,

                currencyCode: item.currencyName.split('(')[1]?.replace(')', '') || "",
            }));
            setBeneficiary(mappedResponse.filter(x => x.currencyCode !== fromCurrencyTransfer));
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching beneficiaries", error);
        }

    };

    const fetchTrancfer = async (fromCurr) => {
        try {
            const resp = await getApiData(`api/RateMaster/GetTransactionRate?fromCurr=${fromCurr}`);
            if (resp.success === true) {
                setRateHistoryId(resp.data.rateHistoryId);
                setBuyRate(parseFloat(resp.data.buyRate));
            } else {
                setBuyRate(null);
                setRateHistoryId(null);
            }
        } catch (error) {
            console.error("Error occuried", error);
        }
    }

    const getBSTCurrency = async (tab) => {
        try {
            setIsLoading(true);
            if (tab === "1") {
                const buyingCurrencyResponse = await getApiData("api/Currency/GetBuyingCurrency");
                const mappedBuyingCurrency = buyingCurrencyResponse.data
                    .filter(x => x.currencyCode !== "SGD")
                    .map(buyCurrency => ({
                        ratetype: buyCurrency.ratetype,
                        currencyId: buyCurrency.currencyId,
                        currencyCode: buyCurrency.currencyCode,
                        currencyName: buyCurrency.currencyName,
                    }));
                setBuyingCurrency(mappedBuyingCurrency);
            } else if (tab === "2") {
                const sellingCurrencyResponse = await getApiData("api/Currency/GetSellingCurrency");
                const mappedSellingCurrency = sellingCurrencyResponse.data
                    .filter(x => x.currencyCode !== "SGD")
                    .map(sellCurrency => ({
                        ratetype: sellCurrency.ratetype,
                        currencyId: sellCurrency.currencyId,
                        currencyCode: sellCurrency.currencyCode,
                        currencyName: sellCurrency.currencyName,
                    }));
                setSellingCurrency(mappedSellingCurrency);
            } else {
                getMainBranch();
                getGlobalTransferRates();
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching currencies", error);
        }
    };

    const getBranchByCurrCode = async (currencyCode, rateType) => {
        try {
            setIsLoading(true);
            if (rateType === 2) {
                const buyResponse = await getApiData(`api/BranchMaster/GetBuyBranchesByCurrency?currencyCode=${currencyCode}`);

                if (buyResponse.success === true) {
                    const formattedBranch = buyResponse.data
                        .map(branch => ({
                            active: branch.currencyView.active,
                            value: branch.currencyView.branchId,
                            label: branch.currencyView.branchName,
                            conversionValue: branch.currencyView.value !== "" ? parseFloat(branch.currencyView.value) : 0,
                            agentCharges: branch.currencyView.agentCharges !== "" ? parseFloat(branch.currencyView.agentCharges) : 0,
                            buyrateHistoryId: branch.currencyView.rateHistoryId !== "" ? parseFloat(branch.currencyView.rateHistoryId) : 0,
                            availableAmount: branch.currencyView.availableAmount !== "" ? parseFloat(branch.currencyView.availableAmount) : 0,
                            address: `${branch.currencyView.street}, ${branch.currencyView.city}, ${branch.currencyView.postalcode}`,

                            operationalTime: branch.operationalTime.map(op => ({
                                day: op.day,
                                startTime: convertTo12HourFormat(op.startTime),
                                endTime: convertTo12HourFormat(op.endTime),
                            })),
                        }));

                    setBuyBranch(formattedBranch);
                } else {
                    setBuyBranch([]);
                }
            } else {
                const sellResponse = await getApiData(`api/BranchMaster/GetSellBranchesByCurrency?currencyCode=${currencyCode}`);

                if (sellResponse.success === true) {
                    const formattedSellBranch = sellResponse.data
                        .map(branch => ({
                            active: branch.branch.active,
                            value: branch.branch.branchId,
                            label: branch.branch.branchName,
                            conversionValue: branch.rateValue !== "" ? parseFloat(branch.rateValue) : 0,
                            agentCharges: branch.agentCharges !== "" ? parseFloat(branch.agentCharges) : 0,
                            sellrateHistoryId: branch.rateHistoryId !== "" ? parseFloat(branch.rateHistoryId) : 0,
                            address: `${branch.branch.street}, ${branch.branch.city}, ${branch.branch.postalCode}`,

                            operationalTime: branch.operationalTime.map(op => ({
                                day: op.day,
                                startTime: convertTo12HourFormat(op.startTime),
                                endTime: convertTo12HourFormat(op.endTime),
                            })),
                        }));

                    setSellBranch(formattedSellBranch);
                } else {
                    setSellBranch([]);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching branches:", error);
        }
    };

    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(":");
        const meridiem = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${meridiem}`;
    };

    const validateDate = (selectedDate) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const selectedDateObj = new Date(selectedDate);

        if (!selectedDate) {
            setErrors({ date: 'Date is required' });
        } else if (selectedDateObj < currentDate) {
            setErrors({ date: 'You cannot select a past date' });
        } else {
            setErrors({});
        }
    };

    const handlePrecision = (value) => {
        return Math.round((value + Number.EPSILON) * 1000000000) / 1000000000;
    };


    const handleSubmitBuying = async (e) => {
        e.preventDefault();

        if (!selectedToCurrencyBuying) {
            toast.info("Please select the currencies you want to exchange.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        if (!pickupDT) {
            toast.info("Please select a pickup date and time.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        const formattedDate = pickupDT ? new Date(pickupDT).toISOString() : "";

        if (!selectedBuyBranch) {
            toast.info("Please select a Branch.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        if (!fromCurrencyValueBuying && !toCurrencyValueBuying) {
            toast.info("Please Enter the Amount you want to Exchange.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        const BuyingData = {

            fromCurrType: fromCurrencyBuying,
            fromCurrValue: handlePrecision(fromCurrencyValueBuying),

            toCurrType: selectedToCurrencyBuying?.value,
            toCurrValue: handlePrecision(toCurrencyValueBuying),

            fromBranchId: selectedBuyBranch?.value,
            exchangeRate: selectedBuyBranch?.conversionValue,
            toRateHistoryId: selectedBuyBranch?.buyrateHistoryId,

            transactionType: 2,
            pickup: formattedDate,

        };

        try {
            //console.log({ BuyingData, formattedDate });
            //return;
            setIsLoading(true);
            const response = await postApiData('api/Transaction/InsertTransaction', BuyingData)
            if (response.success === true) {
                toast.success("Transaction request has been completed ", {
                    position: "top-right",
                    autoClose: 1500,
                });
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile ",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/profile");
                    }
                });
            }
            resetTransaction();
        } catch (error) {
            toast.error("Transaction failed, Please try again.", {
                position: "top-right",
                autoClose: 1500,
            });
            resetTransaction();
        };
    };

    const handleSubmitSelling = async (e) => {
        e.preventDefault();

        if (!selectedFromCurrencySelling) {
            toast.info("Please select the currencies you want to exchange.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        if (!pickupDT) {
            toast.info("Please select a pickup date and time.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        if (!selectedSellBranch) {
            toast.info("Please select a Branch.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        if (!fromCurrencyValueSelling && !toCurrencyValueSelling) {
            toast.info("Please Enter the Amount you want to Exchange.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        };

        const formattedDate = pickupDT ? new Date(pickupDT).toISOString() : "";

        const SellingData = {

            fromCurrType: selectedFromCurrencySelling?.value,
            fromCurrValue: handlePrecision(fromCurrencyValueSelling),

            toCurrType: toCurrencySelling,
            toCurrValue: handlePrecision(toCurrencyValueSelling),

            fromBranchId: selectedSellBranch?.value,
            fromRateHistoryId: selectedSellBranch?.sellrateHistoryId,

            transactionType: 1,
            exchangeRate: selectedSellBranch?.conversionValue,

            pickup: formattedDate,
        };

        try {
            //console.log(SellingData);
            //return;
            setIsLoading(true);
            const response = await postApiData('api/Transaction/InsertTransaction', SellingData)
            if (response.success === true) {
                toast.success("Transaction request has been completed", {
                    position: "top-right",
                    autoClose: 1500
                });
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile ",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/profile")
                    }
                });
            }
            resetTransaction();
        } catch (error) {
            toast.error("Transaction failed, Please try again.", {
                position: "top-right",
                autoClose: 1500,
            });
            resetTransaction();
        };
    };

    const handleSubmitTransfer = async (e) => {
        e.preventDefault();

        if (!selectedBeneficiary) {
            toast.info("Please select a Beneficiary.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        }

        if (!fromCurrencyValueTransfer && !toCurrencyValueTransfer) {
            toast.info("Please Enter the Amount you want to Exchange.", {
                position: "top-right",
                autoClose: 1500,
            });
            return;
        }

        const fromRateHistory = globalTransferData?.find(
            (x) => x.currencyCode === fromCurrencyTransfer
        );

        const toRateHistory = globalTransferData?.find(
            (x) => x.currencyCode === toCurrencyTransfer
        );

        const TransferData = {

            toCustBenId: selectedBeneficiary?.value,

            fromCurrType: fromCurrencyTransfer,
            fromCurrValue: handlePrecision(fromCurrencyValueTransfer),

            toCurrType: toCurrencyTransfer,
            toCurrValue: handlePrecision(toCurrencyValueTransfer),

            exchangeRate: setExchangeRate(),

            fromBranchId: mainBranch?.branchId,
            transactionType: 3,

            fromRateHistoryId: fromRateHistory?.rateHistoryId,
            toRateHistoryId: toRateHistory?.rateHistoryId,
            BuyRateHistoryIdMainBranch: rateHistoryId,

        };

        try {
            //console.log(TransferData);
            //return;
            setIsLoading(true);
            const response = await postApiData('api/Transaction/InsertTransaction', TransferData);
            if (response.success === true) {
                toast.success("Transaction request has been completed", {
                    position: "top-right",
                    autoClose: 1500,
                });
            } else {
                Swal.fire({
                    text: response.message + "! Click Ok to go to profile ",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#556ee6",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        navigate("/profile")
                    }
                });
            }
            resetTransaction();
        } catch (error) {
            toast.error("Transaction failed, Please try again!", {
                position: "top-right",
                autoClose: 1500,
            });
            resetTransaction();
        };
    };


    useEffect(() => {
        if (selectedToCurrencyBuying) {
            getBranchByCurrCode(selectedToCurrencyBuying.value, 2);
        }
    }, [selectedToCurrencyBuying]);

    useEffect(() => {
        if (selectedFromCurrencySelling) {
            getBranchByCurrCode(selectedFromCurrencySelling.value, 1);
        }
    }, [selectedFromCurrencySelling]);


    const convertFromBuyCurrency = (e) => {
        const inputValue = e.target.value;

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueBuying("");
            setToCurrencyValueBuying("");
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "",
            }));
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue);
        const charges = parseFloat(buyCharge) || 0;
        const rate = parseFloat(buyExchnageRate) || 0;

        setFromCurrencyValueBuying(amount);

        if (amount < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than 10.",
                toCurrency: "",
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount must be greater than buying fee.",
                toCurrency: "",
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
            return;
        }

        const subAmount = amount - charges;
        const convertedToCurrency = subAmount / rate;

        if (convertedToCurrency < 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Converted amount must be greater than 1.",
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (convertedToCurrency > selectedBuyBranch?.availableAmount) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `Amount cannot exceed the available stock of ${selectedBuyBranch?.availableAmount}.`,
            }));
            setToCurrencyValueBuying(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (convertedToCurrency.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Amount cannot exceed 10 digits."
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
        }));

        console.log({ amount, charges, rate, convertedToCurrency });

        setToCurrencyDisplay(convertedToCurrency.toFixed(2));
        setToCurrencyValueBuying(convertedToCurrency > 0 ? convertedToCurrency : 0);
    };

    const convertToBuyCurrency = (e) => {
        const inputValue = e.target.value;

        setToCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueBuying("");
            setToCurrencyValueBuying("");
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "",
            }));
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue);
        const charges = parseFloat(buyCharge) || 0;
        const rate = parseFloat(buyExchnageRate) || 0;

        setToCurrencyValueBuying(amount);

        if (amount < 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "Amount must be greater than 1.",
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (amount >= selectedBuyBranch?.availableAmount) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: `Amount cannot exceed the available stock of ${selectedBuyBranch?.availableAmount}.`,
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        const convertedSGD = amount * rate;
        const convertedFromCurrency = convertedSGD + charges;

        if (convertedSGD <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than buying charges.",
                toCurrency: "",
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (convertedFromCurrency < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "SGD must be greater than 10.",
                toCurrency: "",
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (convertedFromCurrency.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount cannot exceed 10 digits",
                toCurrency: ""
            }));
            setFromCurrencyValueBuying(0);
            setFromCurrencyDisplay("0");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
        }));

        console.log({ amount, charges, rate, convertedFromCurrency });

        setFromCurrencyDisplay(convertedFromCurrency.toFixed(2));
        setFromCurrencyValueBuying(convertedFromCurrency > 0 ? convertedFromCurrency : 0);
    };


    const convertFromSellCurrency = (e) => {
        const inputValue = e.target.value.trim();

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueSelling("");
            setToCurrencyValueSelling("");
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "",
            }));
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue) || 0;
        const charges = sellCharge || 0;
        const rate = parseFloat(sellExchnageRate) || 0;

        setFromCurrencyValueSelling(amount);

        if (amount <= 1) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `${selectedFromCurrencySelling?.value} must be greater than 1.`,
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: `${selectedFromCurrencySelling?.value} must be greater than the selling fee.`,
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0");
            return;
        }

        const convertedSGD = amount * rate
        const convertedToCurrency = convertedSGD - charges;

        if (convertedToCurrency <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "SGD amount must be greater than 10."
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (convertedToCurrency.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount cannot exceed 10 digits",
                toCurrency: ""
            }));
            setToCurrencyValueSelling(0);
            setToCurrencyDisplay("0");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: ""
        }));

        setToCurrencyDisplay(convertedToCurrency.toFixed(2));
        setToCurrencyValueSelling(convertedToCurrency > 0 ? convertedToCurrency : 0);

    };

    const convertToSellCurrency = (e) => {
        const inputValue = e.target.value.trim();

        setToCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueSelling(0);
            setToCurrencyValueSelling(0);
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
                toCurrency: "",
            }));
            return;
        }

        if (inputValue.length > 10) return;

        const amount = parseFloat(inputValue) || 0;
        const charges = parseFloat(sellCharge) || 0;
        const rate = parseFloat(sellExchnageRate) || 0;

        setToCurrencyValueSelling(amount);

        if (amount <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: "SGD cannot be less than 10.",
            }));
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (amount <= charges) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: "SGD must be greater than the selling fee.",
            }));
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0");
            return;
        }

        const totalSGD = amount + charges
        const convertedFromCurrency = totalSGD / rate;

        if (convertedFromCurrency <= 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "",
            }));
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (convertedFromCurrency.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount cannot exceed 10 digits",
                toCurrency: ""
            }));
            setFromCurrencyValueSelling(0);
            setFromCurrencyDisplay("0");
            return;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            fromCurrency: "",
            toCurrency: "",
        }));

        setFromCurrencyDisplay(convertedFromCurrency.toFixed(2));
        setFromCurrencyValueSelling(convertedFromCurrency > 0 ? convertedFromCurrency : 0);

    };


    const convertFromTransferCurrency = (e) => {
        const inputValue = e.target.value;

        setFromCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setFromCurrencyValueTransfer("");
            setToCurrencyValueTransfer("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        let convertedValue = 0;
        const amount = parseFloat(inputValue) || 0;
        const totalFee = parseFloat((convertedWireFee || 0) + (convertedCommissionFee || 0) + (convertedAgentFee || 0));

        setFromCurrencyValueTransfer(amount);

        if (amount <= 10 || amount < totalFee) {
            setErrors({
                fromCurrency: `Amount must be greater than 10, total fees (${totalFee.toFixed(2)}), and cannot be negative.`,
                toCurrency: "",
            });
            setToCurrencyValueTransfer(0);
            setToCurrencyDisplay("0");
            return;
        }

        if (fromCurrencyTransfer === "SGD") {
            // Scenario 1: From currency is SGD
            if (transferRate > 0) {
                convertedValue = (amount - totalFee) / transferRate;
            } else {
                setErrors({
                    fromCurrency: "Transfer rate is invalid.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                return;
            }
        } else if (toCurrencyTransfer === "SGD") {
            // Scenario 2: To currency is SGD
            if (buyRate > 0) {
                convertedValue = (amount - totalFee) * buyRate;
                console.log({ amount, buyRate, totalFee, convertedValue });
                if (convertedValue < 1) {
                    setErrors({
                        fromCurrency: "",
                        toCurrency: "Converted SGD cannot be less than 1",
                    });
                    setToCurrencyValueTransfer(0);
                    setToCurrencyDisplay("0");
                    return;
                }
            } else {
                setErrors({
                    fromCurrency: "Convertion Rate not available.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                setToCurrencyDisplay("0");
                return;
            }
        } else {
            // Scenario 3: Neither currency is SGD
            if (buyRate <= 0 || transferRate <= 0) {
                setErrors({
                    fromCurrency: "Convertion Rateor not available.",
                    toCurrency: "",
                });
                setToCurrencyValueTransfer(0);
                setToCurrencyDisplay("0");
                return;
            }

            const subAmount = amount - totalFee;
            const convertedSGD = subAmount * buyRate;
            convertedValue = convertedSGD / transferRate;
        }

        if (convertedValue.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount cannot exceed 10 digits",
                toCurrency: ""
            }));
            setToCurrencyValueTransfer(0);
            setToCurrencyDisplay("0");
            return;
        }

        setErrors({
            fromCurrency: "",
            toCurrency: "",
        });

        setToCurrencyValueTransfer(convertedValue);
        setToCurrencyDisplay(convertedValue.toFixed(2));
    };

    const convertToTransferCurrency = (e) => {
        const inputValue = e.target.value;

        setToCurrencyDisplay(inputValue);

        if (inputValue === "") {
            setToCurrencyValueTransfer("");
            setToCurrencyDisplay("");
            setErrors({
                fromCurrency: "",
                toCurrency: "",
            });
            return;
        }

        if (inputValue.length > 10) return;

        let convertedValue = 0;
        const amount = parseFloat(inputValue) || 0;
        const totalFee = parseFloat((convertedWireFee || 0) + (convertedCommissionFee || 0) + (convertedAgentFee || 0));

        setToCurrencyValueTransfer(amount);

        if (amount <= 0 || amount < 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                toCurrency: `Amount must be greater than 10 and cannot be negative.`,
            }));
            setFromCurrencyValueTransfer(0);
            setFromCurrencyDisplay("0");
            return;
        }

        if (fromCurrencyTransfer === "SGD") {
            // Scenario 1: From currency is SGD
            if (transferRate > 0) {
                convertedValue = (amount * transferRate) + totalFee;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Convertion Rateor not available.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0");
                return;
            }
        } else if (toCurrencyTransfer === "SGD") {
            // Scenario 2: To currency is SGD
            if (buyRate > 0) {
                convertedValue = (amount / buyRate) + totalFee;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Convertion Rateor not available.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0");
                return;
            }
        } else {
            // Scenario 3: Neither currency is SGD
            if (buyRate <= 0 || transferRate <= 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    toCurrency: "Invalid buy or transfer rates.",
                }));
                setFromCurrencyValueTransfer(0);
                setFromCurrencyDisplay("0");
                return;
            }

            const convertedSGD = amount * transferRate;
            convertedValue = (convertedSGD / buyRate) + totalFee;
        }

        if (convertedValue.length > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                fromCurrency: "Amount cannot exceed 10 digits",
                toCurrency: ""
            }));
            setFromCurrencyValueTransfer(0);
            setFromCurrencyDisplay("0");
            return;
        }

        setErrors({
            fromCurrency: "",
            toCurrency: "",
        });

        setFromCurrencyValueTransfer(convertedValue);
        setFromCurrencyDisplay(convertedValue.toFixed(2));
    };


    useEffect(() => {
        if (customActiveTab === "3" && fromCurrencyTransfer !== undefined) {
            getAvaBeneficiary();
        }

        if (toCurrencyTransfer === "SGD" && !buyRate) {
            setEnableTransfer(false);
            Swal.fire({
                text: "Transfer not available for this Currency!",
                icon: "info",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                confirmButtonColor: "#556ee6",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    resetTransaction();
                }
            });
        }

        if (toCurrencyTransfer !== undefined && toCurrencyTransfer !== "SGD" && !transferRate) {
            setEnableTransfer(false);
            Swal.fire({
                text: "Transfer not available for this Beneficiary!",
                icon: "info",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                confirmButtonColor: "#556ee6",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    resetTransaction();
                }
            });
        } else {
            setEnableTransfer(true);
        }

        if (fromCurrencyTransfer === toCurrencyTransfer) {
            resetTransaction();
        }
        if (fromCurrencyTransfer !== "SGD" &&
            fromCurrencyTransfer !== undefined) {
            fetchTrancfer(fromCurrencyTransfer);
        }
    }, [fromCurrencyTransfer, toCurrencyTransfer, customActiveTab, transferRate, buyRate]);

    const filteredBeneficiaries = beneficiary.filter(b =>
        b.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!filteredBeneficiaries.length && typedBeneficiaryName) {
        filteredBeneficiaries.push({
            label: `Create New Beneficiary "${typedBeneficiaryName}"`,
            value: "create-new",
        });
    };

    const openADDBeneficiary = () => {
        toggleAddModal();
        setSearchTerm("");
    };

    useEffect(() => {
        getBSTCurrency(customActiveTab);
        if (currencyData?.value !== "SGD") {
            toggleCustom("3");
        }
    }, [currencyData, customActiveTab]);

    const handleNumericInput = (e) => {
        const allowedKeys = [
            "Backspace",
            "Delete",
            "ArrowLeft",
            "ArrowRight",
            "Tab",
            "Enter"
        ];

        const inputValue = e.target.value;

        if (allowedKeys.includes(e.key)) return;

        if (e.key === ".") {
            if (inputValue.includes(".")) {
                e.preventDefault();
                return;
            }
        } else if (!/^\d$/.test(e.key)) {
            e.preventDefault();
            return;
        }

        if (inputValue.length >= 10) {
            e.preventDefault();
        }
    };

    const getDocument = async (custBenId) => {
        const docResponse = await getApiData(`api/Customerbeneficiary/GetBeneficiaryDocByCustBenId?CustBenId=${custBenId}`);
        const document = await docResponse.data;

        setDocumentData(document)
    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
            resetTransaction();
        }
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <AVEBeneficiary
                editMode={3}
                isOpen={modal1}
                currencies={currencies}
                toggle={toggleAddModal}
                getDocument={getDocument}
                documentData={documentData}
                createBenFor={toCurrencyTransfer}
                getAvaBeneficiary={getAvaBeneficiary}
            />
            <Container className='min-vh-100 mb-3' style={{ marginTop: '10rem', }}>

                <Card>

                    {isLoading
                        ?
                        <Loader />
                        :
                        <CardBody>

                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <CardTitle className="h4">Transaction</CardTitle>
                                <div className="ms-3">
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        onClick={handleHelpClick}
                                        color="#556ee6"
                                        id="helpIcon"
                                        style={{ fontSize: '1.2em', cursor: "pointer" }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen}
                                        target="helpIcon"
                                        toggle={() => setTooltipOpen(!tooltipOpen)}
                                    >
                                        Help
                                    </Tooltip>
                                </div>
                            </div>

                            {currencyData?.value === "SGD" ?
                                <>
                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                Buying
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                Selling
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "3",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("3");
                                                }}
                                            >
                                                Transfer
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </>
                                : <Row>
                                    <Label className="text-center text-primary fs-4" >Transfer</Label>
                                </Row>
                            }

                            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                {currencyData?.value === "SGD" &&
                                    <>

                                        <TabPane tabId="1">
                                            <Form onSubmit={handleSubmitBuying}>

                                                <Row>
                                                    <Col md={6}>
                                                        <Label>You Give</Label>
                                                        <div style={{ display: 'flex', alignItems: 'center', border: '2px solid #ced4da', background: '#F8F8F8', padding: '2px' }}>
                                                            <img
                                                                src={getCurrencyFlag(fromCurrencyBuying)}
                                                                alt={"Currency_Flag"}
                                                                style={{ width: '20px', height: '20px', marginRight: '5px', marginLeft: '10px' }}
                                                            />
                                                            <Input value={fromCurrencyBuying} disabled style={{ border: 'none' }} />
                                                        </div>
                                                    </Col>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label>You Get</Label>
                                                            <ReactSelect
                                                                options={buyingCurrency.map(({ currencyCode, currencyName }) => ({
                                                                    value: currencyCode,
                                                                    label: currencyName,
                                                                }))}
                                                                styles={SelectStyle}
                                                                value={selectedToCurrencyBuying}
                                                                formatOptionLabel={({ value, label }) => (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <img src={getCurrencyFlag(value)} alt={`${value} Flag`} style={{ width: '20px', marginRight: '5px' }} />
                                                                        {label}
                                                                    </div>
                                                                )}
                                                                onChange={(selected) => {
                                                                    setSelectedToCurrencyBuying(selected);
                                                                    setSelectedBuyBranch(null);
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="mb-3">
                                                    <Label>Branch</Label>
                                                    <ReactSelect
                                                        id="branch"
                                                        styles={SelectStyle}
                                                        isDisabled={!selectedToCurrencyBuying}
                                                        value={selectedBuyBranch}
                                                        onChange={(selected) => {
                                                            console.log(selected);
                                                            if (selected.conversionValue === 0) {
                                                                setErrors(() => ({
                                                                    branch: "Selected branch has corrupted converstion value, Please contact Admin.",
                                                                }));
                                                            } else {
                                                                setSelectedBuyBranch(selected);
                                                            }

                                                            if (selected.operationalTime && selected.operationalTime.length > 0) {
                                                                const operationalTime = selected.operationalTime[0];

                                                                setOptime({
                                                                    startTime: operationalTime.startTime,
                                                                    endTime: operationalTime.endTime,
                                                                });
                                                            } else {
                                                                setOptime(null);
                                                            }

                                                            setBuyCharge(selected.agentCharges || 0);
                                                        }}
                                                        options={buyBranch}
                                                        components={{ Option: CustomOption }}
                                                    />
                                                </div>
                                                {errors.branch && <div className="text-danger">{errors.branch}</div>}

                                                {selectedBuyBranch && (

                                                    <Row className="mb-2">
                                                        <Col md={12}>
                                                            <Label>
                                                                Pick Currency by {' '}
                                                                {optime && optime.startTime && optime.endTime
                                                                    ? `${optime.startTime} - ${optime.endTime}`
                                                                    : <span style={{ fontSize: '0.8em', color: '#888' }}>No Branch Specific Time available</span>
                                                                }
                                                            </Label>
                                                            <div>
                                                                <DatePicker
                                                                    selected={pickupDT}
                                                                    onChange={(date) => {
                                                                        setPickupDT(date)
                                                                        validateDate(date);
                                                                    }}
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={30}
                                                                    dateFormat="yyyy/MM/dd - h:mm aa"
                                                                    timeCaption="Time"
                                                                    className="form-control"
                                                                    placeholderText={moment(currentDate).format("YYYY/MM/DD - HH:mm")}
                                                                />
                                                            </div>
                                                            {errors.date && <div className="text-danger">{errors.date}</div>}
                                                        </Col>
                                                    </Row>

                                                )}

                                                {selectedBuyBranch && (
                                                    <>

                                                        <div className="mb-3">
                                                            <Label>{fromCurrencyBuying} Amount</Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Amount"
                                                                value={fromCurrencyDisplay}
                                                                onChange={convertFromBuyCurrency}
                                                                onKeyDown={handleNumericInput}
                                                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                                            />
                                                            {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                                                        </div>

                                                        {buyCharge > 0 &&
                                                            <>

                                                                <Row className="mb-2 text-end">
                                                                    <Col lg={6} className="text-start">
                                                                        <span>Buy Charges</span>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                                            {buyCharge
                                                                                ? parseFloat(buyCharge).toFixed(2)
                                                                                : "0"
                                                                            }
                                                                            {" "}SGD

                                                                        </span>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="mb-2 text-end">
                                                                    <Col lg={6} className="text-start">
                                                                        <span style={{ fontWeight: 'bold' }}>Total SGD we calculate</span>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <span style={{ fontWeight: 'bold' }}>
                                                                            <FontAwesomeIcon icon={faEquals} className="me-1" />
                                                                            {fromCurrencyValueBuying > buyCharge ? (fromCurrencyValueBuying - buyCharge).toFixed(2) : 0} SGD

                                                                        </span>
                                                                    </Col>
                                                                </Row>

                                                                <hr />

                                                            </>
                                                        }

                                                        <Row className="mb-2 text-end">
                                                            <Col lg={6} className="text-start">
                                                                <span>Exchange Rate</span>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faDivide} className="me-1" />
                                                                    {parseFloat(buyExchnageRate).toFixed(4)}
                                                                </span>
                                                            </Col>
                                                        </Row>


                                                        <div className="mb-2">
                                                            <Label>{selectedToCurrencyBuying?.value} Amount</Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Amount"
                                                                value={toCurrencyDisplay}
                                                                onKeyDown={handleNumericInput}
                                                                onChange={convertToBuyCurrency}
                                                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                                            />
                                                            {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                                                        </div>

                                                    </>
                                                )}

                                                <div className="d-flex justify-content-between mt-3 mb-3">
                                                    <Button
                                                        disabled={isLoading}
                                                        onClick={resetTransaction}
                                                        className="btn btn-danger w-md"
                                                    >
                                                        Cancel
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            isLoading ||
                                                            Object.values(errors).some((error) => error !== "")
                                                        }
                                                        onClick={handleSubmitBuying}
                                                        className="btn bg-primary w-md"
                                                    >
                                                        {isLoading ? "Loading..." : "Submit"}
                                                    </Button>
                                                </div>

                                            </Form>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <Form onSubmit={handleSubmitSelling}>

                                                <Row>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label>You Give</Label>
                                                            <ReactSelect
                                                                options={sellingCurrency.map(({ currencyCode, currencyName }) => ({
                                                                    value: currencyCode,
                                                                    label: currencyName,
                                                                }))}
                                                                value={selectedFromCurrencySelling}
                                                                formatOptionLabel={({ value, label }) => (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <img src={getCurrencyFlag(value)} alt={"Currency_Flag"} style={{ width: '20px', marginRight: '5px' }} />
                                                                        {label}
                                                                    </div>
                                                                )}
                                                                onChange={(selected) => {
                                                                    setSelectedFromCurrencySelling(selected);
                                                                    setSelectedSellBranch(null);
                                                                }}
                                                                styles={SelectStyle}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col md={6}>

                                                        <div className="mb-3">
                                                            <Label>You Get</Label>
                                                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ced4da', background: '#F8F8F8', padding: '2px' }}>
                                                                <img
                                                                    src={getCurrencyFlag(toCurrencySelling)}
                                                                    alt={`${toCurrencySelling} flag`}
                                                                    style={{ width: '20px', height: '20px', marginRight: '5px', marginLeft: '10px' }}
                                                                />
                                                                <Input value={toCurrencySelling} disabled style={{ border: 'none' }} />
                                                            </div>

                                                        </div>
                                                    </Col>

                                                </Row>

                                                <div className="mb-3">
                                                    <Label>Branch</Label>
                                                    <ReactSelect
                                                        id="activeBranch"
                                                        value={selectedSellBranch}
                                                        isDisabled={!selectedFromCurrencySelling}
                                                        onChange={(selected) => {
                                                            if (selected.conversionValue === 0) {
                                                                setErrors(() => ({
                                                                    branch: "Selected branch has corrupted converstion value, Please contact Admin.",
                                                                }));
                                                            } else {
                                                                setSelectedSellBranch(selected);
                                                            }
                                                            console.log(selectedSellBranch);
                                                            if (selected.operationalTime && selected.operationalTime.length > 0) {
                                                                const operationalTime = selected.operationalTime[0];

                                                                setOptime({
                                                                    startTime: operationalTime.startTime,
                                                                    endTime: operationalTime.endTime,
                                                                });
                                                            } else {
                                                                setOptime(null);
                                                            }

                                                            setSellCharge(selected.agentCharges || 0);
                                                        }}
                                                        options={sellBranch}
                                                        components={{ Option: CustomOption }}
                                                    />
                                                </div>
                                                {errors.branch && <div className="text-danger">{errors.branch}</div>}

                                                {selectedSellBranch && (

                                                    <Row className="mb-3">
                                                        <Col md={12}>
                                                            <Label>
                                                                Pick Currency by {' '}
                                                                {optime && optime.startTime && optime.endTime
                                                                    ? `${optime.startTime} - ${optime.endTime}`
                                                                    : <span style={{ fontSize: '0.8em', color: '#888' }}>No Branch Specific Time available</span>
                                                                }
                                                            </Label>
                                                            <div>
                                                                <DatePicker
                                                                    selected={pickupDT}
                                                                    onChange={(date) => {
                                                                        setPickupDT(date)
                                                                        validateDate(date);
                                                                    }}
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={30}

                                                                    dateFormat="yyyy/MM/dd - h:mm aa"
                                                                    timeCaption="Time"
                                                                    className="form-control"
                                                                    placeholderText={moment(currentDate).format("YYYY/MM/DD - HH:mm")}
                                                                />
                                                            </div>

                                                            {errors.date && <div className="text-danger">{errors.date}</div>}
                                                        </Col>
                                                    </Row>

                                                )}

                                                {selectedSellBranch && (
                                                    <>

                                                        <div className="mb-3">
                                                            <Label>{selectedFromCurrencySelling?.value} Amount</Label>

                                                            <Input
                                                                type="text"
                                                                value={fromCurrencyDisplay}
                                                                className="form-control"
                                                                placeholder="Enter Amount"
                                                                onKeyDown={handleNumericInput}
                                                                onChange={convertFromSellCurrency}
                                                            />
                                                            {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                                                        </div>


                                                        {sellCharge > 0 &&
                                                            <>

                                                                <Row className="mb-2 text-end">
                                                                    <Col lg={6} className="text-start">
                                                                        <span>Converted SGD</span>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faEquals} className="me-1" />
                                                                            {fromCurrencyValueSelling
                                                                                ? (fromCurrencyValueSelling * parseFloat(selectedSellBranch?.conversionValue)).toFixed(2)
                                                                                : 0
                                                                            }
                                                                            {" "}
                                                                            SGD
                                                                        </span>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="mb-2 text-end">
                                                                    <Col lg={6} className="text-start">
                                                                        <span>Sell Charges</span>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <span>

                                                                            <FontAwesomeIcon icon={faMinus} className="me-1" />
                                                                            {sellCharge
                                                                                ? parseFloat(sellCharge).toFixed(2)
                                                                                : "0"
                                                                            }
                                                                            {" "}SGD
                                                                        </span>
                                                                    </Col>
                                                                </Row>

                                                                <hr />

                                                            </>

                                                        }

                                                        <Row className="mb-2 text-end">
                                                            <Col lg={6} className="text-start">
                                                                <span>Exchange Rate</span>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faX} className="me-1" />
                                                                    {selectedSellBranch.conversionValue
                                                                        ? `${parseFloat(selectedSellBranch.conversionValue).toFixed(4)} SGD`
                                                                        : "N/A"}
                                                                </span>
                                                            </Col>
                                                        </Row>

                                                        <div className="mb-2">
                                                            <Label>{toCurrencySelling} Amount</Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                onKeyDown={handleNumericInput}
                                                                placeholder="Enter Amount"
                                                                value={toCurrencyDisplay}
                                                                onChange={convertToSellCurrency}
                                                                onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                                            />
                                                            {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                                                        </div>
                                                    </>
                                                )}
                                                <div className="d-flex justify-content-between mt-3 mb-3">
                                                    <Button
                                                        disabled={isLoading}
                                                        onClick={resetTransaction}
                                                        className="btn btn-danger w-md"
                                                    >
                                                        Cancel
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            isLoading ||
                                                            Object.values(errors).some((error) => error !== "")
                                                        }
                                                        className="btn bg-primary w-md"
                                                        onClick={handleSubmitSelling}
                                                    >
                                                        {isLoading ? "Loading..." : "Submit"}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </TabPane>

                                    </>
                                }

                                <TabPane tabId="3">
                                    <Form onSubmit={handleSubmitTransfer}>

                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-2">
                                                    <Label>Select Beneficiary</Label>
                                                    <RequiredAsterisk />
                                                    <Row className="align-items-center">
                                                        <Col xs={10} md={10}>
                                                            <ReactSelect
                                                                styles={SelectStyle}
                                                                value={selectedBeneficiary}
                                                                onInputChange={(inputValue) => {
                                                                    setSearchTerm(inputValue);
                                                                    setTypedBeneficiaryName(inputValue);
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    if (selectedOption?.value === "create-new") {
                                                                        openADDBeneficiary();
                                                                    } else {
                                                                        setErrors((prevErrors) => ({
                                                                            ...prevErrors,
                                                                            fromCurrency: "",
                                                                            toCurrency: "",
                                                                        }));
                                                                        resetTransaction();
                                                                        setBeneficiaryError("");
                                                                        setSelectedBeneficiary(selectedOption);
                                                                        console.log(selectedBeneficiary);
                                                                    }
                                                                }}

                                                                options={beneficiary.map((option) => ({
                                                                    value: option.value,
                                                                    label: option.label,
                                                                    currencyName: option.currencyCode,
                                                                    icon: getCurrencyFlag(option.currencyCode),
                                                                }))}
                                                                formatOptionLabel={({ label, icon, currencyName }) => (
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <span>{label}</span>
                                                                        <img
                                                                            src={icon}
                                                                            alt="flag"
                                                                            style={{ width: 20, height: 20, marginLeft: 8 }}
                                                                        />
                                                                        <span style={{ marginLeft: 5, fontSize: 12, }}>
                                                                            ( {currencyName})
                                                                        </span>

                                                                    </div>
                                                                )}
                                                                isClearable={() => {
                                                                    resetTransaction();
                                                                }}
                                                            />
                                                        </Col>

                                                        <Col xs={2} md={2} className="d-flex justify-content-start">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={openADDBeneficiary}
                                                            >
                                                                <span className="d-block d-md-none">
                                                                    <FontAwesomeIcon icon={faPlus} />
                                                                </span>
                                                                <span className="d-none d-md-inline">
                                                                    <FontAwesomeIcon icon={faPlus} /> Add Beneficiary
                                                                </span>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                    {beneficiaryError && <div className="text-danger mt-1">{beneficiaryError}</div>}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <Label>You Give</Label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        background: "#F8F8F8",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <img
                                                        src={getCurrencyFlag(fromCurrencyTransfer)}
                                                        alt={`${fromCurrencyTransfer} flag`}
                                                    />
                                                    <Input
                                                        value={fromCurrencyTransfer}
                                                        disabled
                                                        style={{ border: "none" }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={6}>
                                                <Label>{fromCurrencyTransfer} Amount </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Amount"
                                                    value={fromCurrencyDisplay}
                                                    disabled={!selectedBeneficiary}
                                                    onKeyDown={handleNumericInput}
                                                    onChange={convertFromTransferCurrency}
                                                    onFocus={(e) => (e.target.style.border = '2px solid #556ee6')}
                                                />
                                                {errors.fromCurrency && <div className="text-danger">{errors.fromCurrency}</div>}
                                            </Col>
                                        </Row>

                                        {selectedBeneficiary !== null
                                            && enableTransfer &&
                                            <>

                                                {convertedWireFee > 0 &&
                                                    <Row className="mb-3 text-end mt-2">
                                                        <Col lg={6} className="text-start">
                                                            <span>Wire Transfer Fee</span>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <span>
                                                                <FontAwesomeIcon icon={faMinus} />
                                                                {" "}
                                                                {convertedWireFee.toFixed(3)}
                                                                {" "}
                                                                {fromCurrencyTransfer}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                }

                                                {convertedCommissionFee > 0 &&
                                                    <Row className="mb-3 text-end">
                                                        <Col lg={6} className="text-start">
                                                            <span>Commission Fee</span>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <span>
                                                                <FontAwesomeIcon icon={faMinus} />
                                                                {" "}
                                                                {convertedCommissionFee.toFixed(3)}
                                                                {" "}
                                                                {fromCurrencyTransfer}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                }

                                                {convertedAgentFee > 0 &&
                                                    <Row className="mb-3 text-end">
                                                        <Col lg={6} className="text-start">
                                                            <span>Agent Fee</span>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <span>
                                                                <FontAwesomeIcon icon={faMinus} />
                                                                {" "}
                                                                {convertedAgentFee.toFixed(3)}
                                                                {" "}
                                                                {fromCurrencyTransfer}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                }

                                                {(convertedWireFee > 0 || convertedCommissionFee > 0 || convertedAgentFee > 0) &&
                                                    <>
                                                        <hr />
                                                        <Row className="mb-3 text-end">
                                                            <Col lg={6} className="text-start">
                                                                <span>Total {fromCurrencyTransfer}</span>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faEquals} />
                                                                    {" "}
                                                                    {fromCurrencyValueTransfer > 0
                                                                        ? (fromCurrencyValueTransfer - (convertedWireFee + convertedCommissionFee + convertedAgentFee)).toFixed(3)
                                                                        : 0
                                                                    }
                                                                    {" "}
                                                                    {fromCurrencyTransfer}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }

                                                <Row className="mb-3 mt-3 text-end">
                                                    <Col lg={6} className="text-start">
                                                        <span>Transfer Rate</span>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <span>
                                                            {toCurrencyTransfer === "SGD"
                                                                ?
                                                                <FontAwesomeIcon icon={faX} />
                                                                :
                                                                <FontAwesomeIcon icon={faDivide} />
                                                            }
                                                            {" "}
                                                            {toCurrencyTransfer === "SGD" && fromCurrencyTransfer === "SGD"
                                                                ? 0
                                                                : toCurrencyTransfer === "SGD"
                                                                    ? (buyRate.toFixed(4))
                                                                    : fromCurrencyTransfer === "SGD"
                                                                        ? transferRate.toFixed(4)
                                                                        : toCurrencyTransfer !== "SGD" && fromCurrencyTransfer !== "SGD"
                                                                            ? (buyRate / transferRate).toFixed(4)
                                                                            : 0
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>

                                            </>
                                        }

                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <Label>Beneficiary Receives</Label>
                                                {selectedBeneficiary ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: "5px",
                                                            fontSize: "16px",
                                                        }}>
                                                        <img
                                                            src={getCurrencyFlag(toCurrencyTransfer)}
                                                            alt={"Currency flag"}
                                                            style={{ width: "20px", marginRight: "5px" }}
                                                        />
                                                        <span>{toCurrencyTransfer}</span>
                                                    </div>
                                                ) : (
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        disabled
                                                        value="Please select Beneficiary"
                                                    />
                                                )}
                                            </Col>

                                            <Col md={6}>
                                                <Label>{toCurrencyTransfer} Amount</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Amount"
                                                    value={toCurrencyDisplay}
                                                    onKeyDown={handleNumericInput}
                                                    disabled={!selectedBeneficiary}
                                                    onChange={convertToTransferCurrency}
                                                    onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
                                                />
                                                {errors.toCurrency && <div className="text-danger">{errors.toCurrency}</div>}
                                            </Col>
                                        </Row>

                                        <div className="d-flex justify-content-between mt-3">
                                            <Button
                                                disabled={isLoading}
                                                onClick={resetTransaction}
                                                className="btn btn-danger w-md"
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                className="btn bg-primary w-md"
                                                disabled={
                                                    isLoading ||
                                                    fromCurrencyTransfer === toCurrencyTransfer ||
                                                    Object.values(errors).some((error) => error !== "")
                                                }
                                            >
                                                {isLoading ? "Loading..." : "Submit"}
                                            </Button>
                                        </div>

                                    </Form>
                                </TabPane>

                            </TabContent>
                        </CardBody>
                    }

                </Card>

            </Container>
        </React.Fragment>
    );
};

export default AddTransaction;
