const api = {
    google: {
        API_KEY: "",
        CLIENT_ID: "483164320992-qbqbeqv8fthaa429lqaur63sbd009v2g.apps.googleusercontent.com",
        SECRET: "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",
    },
    facebook: {
        APP_ID: "",
    },
    singPass: {
        clientId: "fozgBYZykkeUdOLM7oZ6ynekCNr1zaVn",
        authUrl: "https://stg-id.singpass.gov.sg/auth",
        //redirectUri: "http://localhost:3000/callback",
        redirectUri: "https://sit.crescentexchange.com/callback",
        // redirectUri: "https://prod.crescentexchange.com/callback",
        responseType: "code",
        scope: "openid",
    },
    api: {
        API_URL: "https://api-node.themesbrand.website",
    }
};

export { api }