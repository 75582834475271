import React, { useEffect, useCallback, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AuthProtected = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const logoutTimeoutRef = useRef(null);
    const warningTimeoutRef = useRef(null);
    const inactivityTimeoutRef = useRef(null);

    const inactivityLimit = 10 * 60 * 1000;

    const handleLogout = useCallback(() => {
        sessionStorage.removeItem("custUser");
        navigate("/login", { replace: true, state: { from: location } });
    }, [navigate, location]);

    const resetInactivityTimer = useCallback(() => {
        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
        }

        inactivityTimeoutRef.current = setTimeout(() => {
            handleLogout();
            Swal.fire({
                title: "Logged Out",
                text: "You were logged out due to inactivity.",
                icon: "info",
            });
        }, inactivityLimit);
    }, [handleLogout, inactivityLimit]);

    const monitorSessionExpiration = useCallback(() => {
        const sessionOverAt = JSON.parse(sessionStorage.getItem("expirValidation"));

        if (sessionOverAt?.expiration) {
            const expirationTime = new Date(sessionOverAt.expiration);
            const currentTime = new Date();
            const remainingTime = expirationTime - currentTime;
            console.log({ expirationTime, currentTime, remainingTime });
            if (remainingTime <= 0) {
                handleLogout();
                Swal.fire({
                    title: "Session Timeout",
                    text: "Your session has expired. Please log in again.",
                    icon: "info",
                });
                return;
            }

            const warningTime = remainingTime * 0.9;
            warningTimeoutRef.current = setTimeout(() => {
                Swal.fire({
                    title: "Session Expiring Soon",
                    text: "Your session will expires soon. Please save your work.",
                    icon: "warning",
                    timer: 300000,
                    timerProgressBar: true,
                });
            }, warningTime);

            logoutTimeoutRef.current = setTimeout(() => {
                handleLogout();
                Swal.fire({
                    title: "Session Timeout",
                    text: "Your session has expired. Please log in again.",
                    icon: "info",
                });
            }, remainingTime);
        } else {
            handleLogout();
        }
    }, [handleLogout]);

    useEffect(() => {
        const activityEvents = ["mousemove", "keydown", "scroll", "click", "touchstart", "touchmove"];

        activityEvents.forEach((event) => {
            window.addEventListener(event, resetInactivityTimer);
        });

        resetInactivityTimer();
        monitorSessionExpiration();

        return () => {
            if (logoutTimeoutRef.current) clearTimeout(logoutTimeoutRef.current);
            if (warningTimeoutRef.current) clearTimeout(warningTimeoutRef.current);
            if (inactivityTimeoutRef.current) clearTimeout(inactivityTimeoutRef.current);

            activityEvents.forEach((event) => {
                window.removeEventListener(event, resetInactivityTimer);
            });
        };
    }, [resetInactivityTimer, monitorSessionExpiration]);

    if (!sessionStorage.getItem("custUser")) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthProtected;
