import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";
import { googleLogout } from '@react-oauth/google';

//redux
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Logout = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        sessionStorage.removeItem("custUser");
        sessionStorage.removeItem("")

        // Clear all cache items 
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);
            if (key.startsWith("axios-cache")) {
                sessionStorage.removeItem(key);
                i--; 
            }
        }

        history("/login", { replace: true });
        googleLogout();
    }, [dispatch,history]);

    return <></>;
};

Logout.propTypes = {
    history: PropTypes.object,
};

export default withRouter(Logout);