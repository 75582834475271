import PropTypes from 'prop-types';
import React, { useState } from "react";
import {
    Nav,
    NavbarToggler,
    NavItem,
    NavLink,
    Container,
    Collapse,
} from "reactstrap";
import { Link } from "react-router-dom";
import ScrollspyNav from "./scrollSpy";

//Import Images
import logodark from "./../../../../../images/logo-dark.png";
import logolight from "./../../../../../images/logo-light.png";
import { useEffect } from 'react';

const navItems = [
    { id: 1, idnm: "home", navheading: "Home" },
    { id: 2, idnm: "about", navheading: "About" },
    { id: 3, idnm: "features", navheading: "Services" },
    { id: 4, idnm: "faqs", navheading: "FAQs" },
    { id: 5, idnm: "contact", navheading: "Contact" },
    { id: 6, idnm: "terms", navheading: "Terms" },
    { id: 7, idnm: "policy", navheading: "Policy" },
    //{ id: 8, idnm: "testimonial", navheading: "Testimonial" },
];


const Navbar_Page = props => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isOpenMenu, setisOpenMenu] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Store all NavigationbaFr Id into TargetID variable(Used for Scrollspy)
    let TargetId = navItems.map(item => {
        return item.idnm;
    });

    return (
        <React.Fragment>
            <nav
                className={"navbar navbar-expand-lg navigation fixed-top sticky " + props.navClass}
            >
                <Container>
                    <Link className="navbar-logo" to="/">

                        {windowWidth < 991 ? (
                            <img
                                src={logodark}
                                alt="Dark Logo"
                                height="19"
                                className="logo logo-dark"
                            />) : (
                            props.imglight !== true ? (
                                <img
                                    src={logodark}
                                    alt="Dark Logo"
                                    height="19"
                                    className="logo logo-dark"
                                />
                            ) : (
                                <img
                                    src={logolight}
                                    alt="Light Logo"
                                    height="19"
                                    className="logo logo-light"
                                />
                            ))}
                    </Link>

                    <NavbarToggler
                        className="p-0"
                        onClick={() => {
                            setisOpenMenu(!isOpenMenu);
                        }}
                    >
                        <i className="fa fa-fw fa-bars" />
                    </NavbarToggler>

                    <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar>
                        <ScrollspyNav
                            scrollTargetIds={TargetId}
                            scrollDuration="800"
                            headerBackground="true"
                            activeNavClass="active"
                            className="navbar-collapse"
                        >
                            <Nav className="ms-auto navbar-nav" id="topnav-menu">
                                {navItems.map((item, key) => (
                                    <NavItem
                                        key={key}
                                        className={item.navheading === "Home" ? "active" : ""}
                                    >
                                        <NavLink href={"#" + item.idnm}> {item.navheading}</NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        </ScrollspyNav>
                        <div className="ms-lg-2">
                            <Link to="/login" className="btn btn-outline-success w-xs">
                                Login
                            </Link>
                        </div>
                        <div className="ms-lg-2">
                            <Link to="/register" className="btn btn-outline-success w-xs">
                                Register
                            </Link>
                        </div>
                    </Collapse>
                </Container>
            </nav>
        </React.Fragment>
    );
};

Navbar_Page.propTypes = {
    imglight: PropTypes.any,
    navClass: PropTypes.string
};

export default Navbar_Page;
